import { interval } from 'rxjs';

let startChat,
  chatRegisterDocsStore,
  expandChatWidget,
  minimizeChatWidget,
  isChatExpanded,
  toggleChatWidget;
if (typeof window === 'undefined') {
  startChat = () => false;
  chatRegisterDocsStore = store => store;
  expandChatWidget = startChat
  minimizeChatWidget = startChat;
  isChatExpanded = startChat;
  toggleChatWidget = startChat;
} else {
  const { interval } = require('rxjs');
  let api, docsStoresSubs, viewMode = '2D', docsPortalVisible = false;
  const setButtonColors = () => {
    if (!api) return;
    if (viewMode === '2D' || docsPortalVisible) {
      api('resetColors');
    } else {
      api('setColors', {
        buttonText: '#fff',
        buttonBg: '#001',
        visitorBubbleBg: '#001',
        agentBubbleBg: '#001'
      });
    }
  }
  chatRegisterDocsStore = (store) => {
    const sub = store.subscribe({
      next: ({ state, changes }) => {
        if (!changes || !api) return;
        if (changes.navInSheet || changes.navSheetVisible) {
          state.navSheetVisible ? api('hide') : api('show');
        }
        if (changes.portalVisible) {
          docsPortalVisible = state.portalVisible || false;
          if (docsPortalVisible) {
            api('setZIndex', 36);
          } else {
            setTimeout(() => api('setZIndex', 4), 20);
          }
          setButtonColors();
        }
      },
      complete: () => {
        const idx = docsStoresSubs ? docsStoresSubs.indexOf(sub) : -1;
        if (idx > -1) {
          docsStoresSubs.splice(idx, 1);
        }
      }
    });
    if (!docsStoresSubs) {
      docsStoresSubs = [ sub ];
    } else {
      docsStoresSubs.push(sub);
    }
    return store;
  }
  startChat = (routerLogic) => {
    routerLogic.subscribe(({ localProps }) => {
      const mode = localProps.viewMode;
      if (mode === viewMode) return;
      if (mode === '3D') {
        viewMode = '3D';
        setButtonColors();
      } else if (!localProps.render2D || !localProps.render3D) {
        viewMode = '2D';
        setButtonColors();
      }
    })
    window.SibConversationsSetup = {
      zIndex: 4,
      disableChatOpenHash: true
    }
    if (api) {
      setButtonColors(); // in case reload in 3D due to changes
      api('show');
      return;
    }
    setTimeout(() => {
      function _startChat(d, w, c) {
        w.SibConversationsID = '639a1c03ab0115690612a2a3';
        w[c] = w[c] || function() {
          (w[c].q = w[c].q || []).push(arguments);
        };
        const s = d.createElement('script');
        s.async = true;
        s.src = 'https://conversations-widget.sendinblue.com/sib-conversations.js';
        if (d.head) d.head.appendChild(s);
      }
      _startChat(document, window, 'SibConversations');
      const sub = interval(500).subscribe(() => {
        if (
          window.SibConversations
          && typeof window.SibConversations === 'function'
          && typeof window.SibConversations.show === 'function'
        ) {
          api = window.SibConversations;
          sub.unsubscribe();
        }
      });
    }, routerLogic.localProps.isLighthouse ? 15000 : 750);
    return true;
  }
  expandChatWidget = () => {
    api && api('openChat');
  }
  minimizeChatWidget = () => {
    api && api('minimizeWidget');
  }
  isChatExpanded = () => !!(api && api._chatExpanded);
  toggleChatWidget = (e) => {
    if (e) e.preventDefault();
    if (isChatExpanded()) {
      minimizeChatWidget();
    } else {
      expandChatWidget();
    }
  }
}

export {
  startChat,
  chatRegisterDocsStore,
  expandChatWidget,
  minimizeChatWidget,
  isChatExpanded,
  toggleChatWidget
};
