import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Section } from './Section';
import {
  data as defaultData,
  defaultOrder,
  orderByRole
} from '../data';
import { useCss, useLocation } from '@isomorix/react-router';
import { updater } from '../helpers';
import { useRecordSubscription } from '@isomorix/react';

const storeKeys = [ 'userRoleId' ];

let css;

if (!process.env.BROWSER || process.env.NODE_ENV !== 'production') {
  try {
    css = require('../_features.scss');
  } catch(e) {}
}

let _topSectionsOnly = true;

export const Home = React.memo(function Home(
  {
    setElevation,
    session,
    data = defaultData,
    order: propsOrder,
    is2D,
    isLighthouse
  }
) {
  useCss(css);
  const mainRef = useRef();
  useRecordSubscription(session, storeKeys);
  const [ topSectionsOnly, setTopSectionsOnly ] = useState(_topSectionsOnly);
  let order;
  if (!propsOrder) {
    const userRole = session.userRole;
    order = userRole
      ? orderByRole[userRole.role.slug]
      : defaultOrder;
  } else if (typeof propsOrder === 'string') {
    order = orderByRole[propsOrder];
  }
  useEffect(() => {
    if (!mainRef.current || !setElevation) return;
    const obs = new IntersectionObserver(([ { isIntersecting } ]) => {
      if (!isIntersecting) {
        _topSectionsOnly = false;
        setTopSectionsOnly(false);
      }
      setElevation(!isIntersecting);
    });
    obs.observe(mainRef.current);
    return () => {
      obs.disconnect();
    }
  }, [ setElevation, mainRef.current ]);
  const loc = useLocation();
  const features = loc.state?.features;
  const setState = useMemo(() => (name, partial) => {
    loc.replaceState(updater.merge_deep({
      features: { [name]: partial }
    }, loc.state));
  }, [ loc ]);
  useEffect(() => {
    const state = loc.state;
    if (!state || !state.features) return;
    const { features } = state;
    let section, expandedIds, id;
    for(let name in features) {
      section = features[name];
      if (section && (expandedIds = section.expandedIds)) {
        for(id of expandedIds) {
          loc.registerScrollElem(`#${id}-scrollable-container`);
        }
      }
    }
  }, [ loc, loc.key ]);
  let i = 0, d;
  const children = [];
  for(let name of order) {
    d = data[name];
    children.push(
      <Section
        key={name}
        ref={i === 0 ? mainRef : undefined}
        data={d}
        isAlt={ i !== 0 && i % 2 === 0 }
        isMain={i === 0}
        is2D={is2D}
        isLighthouse={isLighthouse}
        location={loc}
        session={session}
        state={features && features[name]}
        setState={setState}
      />
    );
    if (i === 1 && topSectionsOnly) break;
    i++;
  }
  return (
    <div>
      { children }
    </div>
  );
})

export { css }
