import React from 'react';
import { Link } from '@react-md/link';

const svgStyle = {
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinejoin: 'round',
  strokeMiterlimit: 2,
}

export const TwitterSvg = React.memo(function TwitterSvg(
  {
    backgroundFill = 'none',
    logoFill = '#1da1f2',
    logoFillRule = 'nonzero',
    width = '1.5rem',
    height = '1.5rem',
    handle,
    className
  }
) {
  const svg = (
    <svg
      className={className}
      width={width}
      height={height}
      style={svgStyle}
      version="1.1"
      viewBox="0 0 512 512"
    >
      <rect
        height="400"
        style={{ fill: backgroundFill }}
        width="400" x="56" y="56"
      />
      <path
        style={{ fill: logoFill, fillRule: logoFillRule}}
        d="M161.014,464.013c193.208,0 298.885,-160.071 298.885,-298.885c0,-4.546 0,-9.072 -0.307,-13.578c20.558,-14.871 38.305,-33.282 52.408,-54.374c-19.171,8.495 -39.51,14.065 -60.334,16.527c21.924,-13.124 38.343,-33.782 46.182,-58.102c-20.619,12.235 -43.18,20.859 -66.703,25.498c-19.862,-21.121 -47.602,-33.112 -76.593,-33.112c-57.682,0 -105.145,47.464 -105.145,105.144c0,8.002 0.914,15.979 2.722,23.773c-84.418,-4.231 -163.18,-44.161 -216.494,-109.752c-27.724,47.726 -13.379,109.576 32.522,140.226c-16.715,-0.495 -33.071,-5.005 -47.677,-13.148l0,1.331c0.014,49.814 35.447,93.111 84.275,102.974c-15.464,4.217 -31.693,4.833 -47.431,1.802c13.727,42.685 53.311,72.108 98.14,72.95c-37.19,29.227 -83.157,45.103 -130.458,45.056c-8.358,-0.016 -16.708,-0.522 -25.006,-1.516c48.034,30.825 103.94,47.18 161.014,47.104"
      />
    </svg>
  );
  if (!handle) return svg;
  return (
    <Link
      href={`https://twitter.com/${handle}`}
      target={"_blank"}
    >{ svg }</Link>
  )
});

export const YouTubeSvg = React.memo(function YouTubeSvg(
  {
    backgroundFill = '#fff',
    logoFill = '#ed1f24',
    logoFillRule = 'nonzero',
    width = '1.5rem',
    height = '1.5rem',
    className
  }
) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      style={svgStyle}
      version="1.1"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M501.299,132.766c-5.888,-22.03 -23.234,-39.377 -45.264,-45.264c-39.932,-10.701 -200.037,-10.701 -200.037,-10.701c0,0 -160.105,0 -200.038,10.701c-22.025,5.887 -39.376,23.234 -45.264,45.264c-10.696,39.928 -10.696,123.236 -10.696,123.236c0,0 0,83.308 10.696,123.232c5.888,22.03 23.239,39.381 45.264,45.268c39.933,10.697 200.038,10.697 200.038,10.697c0,0 160.105,0 200.037,-10.697c22.03,-5.887 39.376,-23.238 45.264,-45.268c10.701,-39.924 10.701,-123.232 10.701,-123.232c0,0 0,-83.308 -10.701,-123.236Z"
          style={{ fill: logoFill, fillRule: logoFillRule }}
        />
        <path
          d="M204.796,332.803l133.018,-76.801l-133.018,-76.801l0,153.602Z"
          style={{ fill: backgroundFill, fillRule: 'nonzero'}}
        />
      </g>
    </svg>
  )
})
