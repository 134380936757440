import React, { useEffect, useState } from 'react';

export const Scanner = React.memo(function Scanner(
  {
    render2D,
    render3D,
    viewMode,
  }
) {
  const [ progressVisible, setProgressVisible ] = useState(false);
  useEffect(() => {
    if (render3D && render2D) {
      setProgressVisible(true);
    } else if (render3D) {
      setTimeout(() => {
        setProgressVisible(false);
      }, 100);
    } else {
      setProgressVisible(false);
    }
  }, [ render2D, render3D, setProgressVisible ]);
  if (
    // true ||
    viewMode === '3D'
    && (progressVisible || render2D && render3D)
  ) {
    return <div className={'scanner'} />
  } else {
    return null;
  }
});
