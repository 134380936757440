import React from 'react';

export const RouterContext = React.createContext(null);


/**
 * @typedef module:react-router.routerContextValue
 * @type {Object}
 * @description
 * The `routerContextValue` describes the shape
 * of the Object provided by `RouterContext`.
 *
 * This can be accessed using the
 * [useRouterContext hook]{@link module:react-router.useRouterContext}.
 * @property {?number} dispatchId - The dispatchId
 * for the [REQUEST action]{@link module:core-actions.request}
 * if this is a server-side render. Otherwise, it
 * will be `null`.
 *
 * This is provided by
 * [ui-router's prepareProps logic]{@link module:ui-router/router/logic/request.prepareProps}.
 * @property {module:core/location.Record} location - The Core
 * [location record]{@link module:core/location.Record}
 * representing the location for the app.
 *
 * In addition to the
 * [useRouterContext hook]{@link module:react-router.useRouterContext},
 * the [useLocation hook]{@link module:react-router.useLocation}
 * can also be used to access this property.
 * @property {module:core/session.Record} session - The Core
 * [session record]{@link module:core/session.Record}
 * representing the session for the app.
 * @property {module:core/coreLogic.Record} logic - The
 * [CoreLogic record]{@link module:core/coreLogic.Record}
 * that is managing the app's top-level route.
 *
 * This would be the logic record whose
 * [type property]{@link module:core/coreLogic.Record#type}
 * is set to the
 * [ROUTER constant]{@link module:core-config/coreLogicTypes.ROUTER}.
 * @property {Object} renderer - An Object containing the
 * functions that can be used to
 * render a logic's [children]{@link module:core/coreLogic.Record#children}.
 */
