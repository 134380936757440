import { loadable } from '@isomorix/store';

const wbLoader = loadable(() => import('workbox-window/Workbox'));

export function registerServiceWorker() {

  // if ( 'production' !== process.env.NODE_ENV ) {
  //   return;
  // }
  if (!('serviceWorker' in navigator)) {
    return;
  }
  wbLoader().subscribe(mod => {
    const { Workbox } = mod;
    const wb = new Workbox('/sw.js');

    wb.addEventListener('installed', event => {
      /**
       * We have the condition - event.isUpdate because we don't want to show
       * this message on the very first service worker installation,
       * only on the updated
       */
      if (event.isUpdate) {
        if (confirm(`New app update is available!. Click OK to refresh`)) {
          window.location.reload();
        }
      }
    });
    wb.register();
  })
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  // if ('serviceWorker' in navigator) {
  //   const wb = new Workbox('sw.js');
  //
  //   wb.addEventListener('installed', event => {
  //     /**
  //      * We have the condition - event.isUpdate because we don't want to show
  //      * this message on the very first service worker installation,
  //      * only on the updated
  //      */
  //     if (event.isUpdate) {
  //       if (confirm(`New app update is available!. Click OK to refresh`)) {
  //         window.location.reload();
  //       }
  //     }
  //   });
  //   wb.register();
  // }
}
