
let cliData;
try {
  cliData = require('../../jsdocData/features/cli.json');
} catch (e) {
  cliData = {
    links: {}
  };
}
export const cli = {
  name: 'cli',
  headline: [
    'Feature-Rich,',
    { children: 'Interactive CLI', color: 'theme-secondary'},
    'With',
    { children: 'Full Support', decoration: 'underline' },
    'For Custom Jobs'
  ],
  subHeadline: [
    'Quickly & easily',
    { color: 'theme-secondary', children: 'automate anything', suffix: ',' },
    'plus all jobs can be run programmatically for maximum flexibility'
  ],
  video: {
    url: 'https://dev-39.wistia.com/medias/3n4229v6ym',
    preview: '/static/img/vid/breakout_cli_preview.webp',
    totalSeconds: 35,
  },
  panels: [{
    header: {
      icon: 'checklist',
      avatarColor: 'indigo',
      text: 'Full Suite Of Built-In Jobs',
    },
    list: cliData.list,
  }, {
    header: {
      icon: 'cloud_upload',
      avatarColor: 'purple',
      text: '100% Store Integration - Including Transactions'
    },
    text: [
      'This may seem like overkill at first...',
      'But what if your App grows to include several packages that perhaps you manage via a monorepo?',
      'You\'ll love that this exists, because you get fine-grained control over things like the order packages are built, or even the order specific files between packages are built.',
      'Transactions also dramatically reduce the complexity of your Continuous Integration (CI) and Continuous Deployment (CD) pipeline since as mentioned, transactions provide unrivaled, fine-grained control that will make even the most complex pipeline feel simple and intuitive.'
    ],
  }, {
    header: {
      icon: 'tune',
      avatarColor: 'yellow',
      text: 'Easily Customize Or Extend Anything',
    },
    text: [
      'No two projects are 100% identical, which makes it a bit odd that you typically see rigid CLIs that are difficult - if not outright impossible - to extend or customize to meet your specific needs.',
      'This leaves you with an impossible choice: work around the limitations, or spend countless hours "rolling" your own solution from scratch.',
      'Not anymore.',
      [
        'Every job can be',
        {
          children: 'easily modified',
          to: cliData.links.extendOptions,
          isDocsLink: true,
        },
        'to include custom properties that will then be available for both command line and programmatic usage.'
      ],
      'Want to modify a job\'s execution or add totally custom jobs?',
      // 'Every part of this CLI is modular with insanely detailed instructions for hooking into literally everything.',
      {
        component: 'Button',
        props: {
          theme: 'secondary',
          themeType: 'outline',
          to: cliData.links.setJobsMap,
          isDocsLink: true,
          children: 'See how easy it is',
          style: { marginBottom: '1.5rem' }
        }
      },
      [
        'You can even',
        {
          children: 'create entire programs',
          to: cliData.links.cmd,
          isDocsLink: true
        },
        'with just a few lines of code. That\'s fully supported too.'
      ],
      [
        {
          children: 'Everything',
          after: ',',
          fontStyle: 'italic',
        },
        'from the ground up, is designed with extreme customization as a first-class citizen so you get both a resource that\'s ready to meet your needs out of the box, as well as a resource that will seamlessly rise to any challenge.'
      ],
      [
        'It\'s truly the best of both worlds so that you can have complete confidence that as your app grows - wherever that may lead -',
        {
          children: 'you\'ll always be 100% covered.',
          weight: 'bold'
        }
      ]
      // 'Everything from the ground up is designed with extreme customization as a first-class citizen so you get both a resource that\'s ready to meet your needs out of the box, and a resource that will seamlessly rise to any challenge.',
      // 'That way you can have the confidence that as your app grows - wherever that may lead - you\'ll always be 100% covered.',

      // 'Everything was designed with customization as a first-class citizen so you get a resource that\'s ready to meet your needs today, while also giving you the confidence that as your app grows - wherever that may lead - you\'ll always be 100% covered.',
      // [
      //   {
      //     children: 'Fire off a prompt',
      //     to: cliData.links.prompt,
      //     isDocsLink: true,
      //   },
      //   'to accept interactive input on the fly,'

      // ]
      // 'This is a major frustration with existing solutions,',
      // 'Jobs are just actions.',
      // [
      //   'This means that overriding or augmenting behavior is as simple as adding logic, no different from adding logic to a plugin, model, record, index, etc. - everything is identical, including transactions, so there\'s',
      //   { children: 'nothing new to learn', weight: 'bold' }
      // ],

    ],
  }, {
    header: {
      icon: 'fact_check',
      avatarColor: 'green',
      text: 'Unrivaled Monorepo Features'
    },
    text: [
      'Monorepos are first-class citizens in all respects, and every single job, utility method - you name it - fully supports monorepo usage. There are even several built-in jobs that exist solely for monorepos.',
      'In fact, the entire Cli implementation is written and maintained in a monorepo, so you\'re in great hands.',
      'In fact, the entire Cli implementation is written and maintained in a monorepo, so you\'re in good hands.'
    ],
  }, {
    header: {
      flames: true,
      icon: 'file_present',
      avatarColor: 'red',
      text: 'Greatly Simplified File Management'
    },
    text: [
      'Create, read, update, delete, archive, rebase, watch, or copy files and directories like never before.',
      [
        'Supports in-memory, native-filesystem (node:fs),',
        { children: 'and even containers', weight: 'bold', after: ',' },
        'which can be identified by their ID, name, or service name (if using docker-compose).'
      ],
      'It\'s the only tool you\'ll need to easily manage files and directories cross-platform, while making it easy to filter, add to, modify, watch, and perform a wide range of other typically tedious operations.',
      {
        component: 'Button',
        props: {
          theme: 'secondary',
          themeType: 'outline',
          to: cliData.links.dirItem,
          isDocsLink: true,
          children: 'Full details + examples'
        }
      }
    ],
  }]
}
