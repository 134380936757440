import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { coreLogicMethods } from './client';
import { connectionsLoader } from './connections';

export default function meilisearch(pluginBuilder) {
  pluginBuilder.addLogicMethodEnumValues(coreLogicMethods);
  const sc = pluginBuilder.storeConfig;
  let ofTrue;
  pluginBuilder.pluginAccessors.loadMeilisearchConnections = {
    value: function loadMeilisearchConnections() {
      return ofTrue
        ? ofTrue
        : connectionsLoader().pipe(
            map(module => {
              if (!module) return false;
              if (!ofTrue) {
                ofTrue = of(true);
                module.addConnections(sc);
              }
              return true;
            }),
            catchError(() => of(false))
          )
    },
    writable: true,
    enumerable: false,
  }
}

export const pluginServerLogicMgr = undefined;
export const buildIndex = undefined;
export const addDocumentsToIndex = undefined;
