import React from 'react';
import { Avatar } from '@react-md/avatar';
import { UserMenu as DefaultUserMenu } from '@isomorix/react-md-user';

const RoleAvatar = (function() {
  const colors = {
    S: 'purple',
    A: 'red',
    E: 'grey',
    P: 'orange'
  };
  const style = {
    width: '1.5rem',
    height: '1.5rem',
    fontSize: '0.75rem'
  }
  const initialsStyle = {
    width: '1.75rem',
    height: '1.75rem',
    fontSize: '0.75rem'
  }
  return function RoleAvatar(
    {
      role,
      className,
      isMenuItem,
      nameMaxLength,
      initials
    }
  ) {
    const letter = role.name.charAt(0);
    if (!isMenuItem) {
      return (
        <Avatar
          style={nameMaxLength === 0 ? initialsStyle : style}
          className={className}
          color={colors[letter]}
        >
          { nameMaxLength === 0 ? initials : letter }
        </Avatar>
      )
    }
    return (
      <Avatar
        style={style}
        className={className}
        color={colors[letter]}
      >{ letter }</Avatar>
    )
  }
})();

export const UserMenu = React.memo(function UserMenu(props) {
  return (<DefaultUserMenu
    { ...props }
    nameMaxLength={0}
    loginButtonType={'icon'}
    loginButtonChildren={'manage_accounts'}
    // loginButtonChildren={props.loginButtonType === 'icon' ? undefined : (
    //   <TextIconSpacing icon={<FontIcon>verified_user</FontIcon>}>
    //     Login/Register
    //   </TextIconSpacing>
    // )}
    RoleAvatar={RoleAvatar}
  />);
});
