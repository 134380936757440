import { LogicMgr } from '@isomorix/store';
import { INIT, MUTATION } from '@isomorix/core-actions';
import { CORE_LOGIC_TYPES } from '@isomorix/core-config';
import { recordLogicMgr } from '@isomorix/record';

/**
 * The LogicMgr that contains logic for
 * [CoreLogic records]{@link module:core/coreLogic.Record}
 * that are managing a plugin's
 * [routes]{@link module:core/route.Record}.
 * @name routeLogicMgr
 * @memberof module:react-ssr
 * @type {module:store.LogicMgr}
 */
export const routeLogicMgr = LogicMgr
  .initModule(`@isomorix/ui-router/route/logic`)
  .addSource(recordLogicMgr)


export const routeInitBuilder = routeLogicMgr
  .getBuilder(INIT, './init')
  .setType(CORE_LOGIC_TYPES.ROUTE);

export const routeMutationBuilder = routeLogicMgr
  .getBuilder(MUTATION, './mutation')
  .setType(CORE_LOGIC_TYPES.ROUTE);
