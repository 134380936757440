import { docsRouteLogicMgr as logicMgr } from './logicMgr';
import {
  CORE_LOGIC_ACTIONS as ACTIONS,
  CORE_LOGIC_TYPES
} from '@isomorix/core-logic';
import { map } from 'rxjs/operators';

const builder = logicMgr.getBuilder(ACTIONS.INIT, './init');

function initExtraLocalProps(action) {
  const logic = action.meta.instance;
  const routerLogic = logic.getParentByType(CORE_LOGIC_TYPES.ROUTER);
  const {
    loadDocsModule,
    docsChunkName,
    docsTemplateCss,
  } = routerLogic.localProps;
  return loadDocsModule(action).pipe(
    map(module => {
      if (!docsTemplateCss) {
        routerLogic.setLocalProps({
          docsTemplateCss: module.templateCss,
          DocsSearchButton: module.SearchButton
        })
      }
      const { localProps } = logic;
      localProps.createDocsStore = module.createRouteStore;
      localProps.docsCss = module.css;
      localProps.docsTemplateCss = module.templateCss;
      localProps.mutationLogic = module.mutationLogic;
      const docsStore = module.createRouteStore(action);
      localProps.docsStore = docsStore;
      localProps.docsChunkName = docsChunkName;
      docsStore.value.commit();
      return action;
    })
  )
}

builder.useRelative('initLocalProps', 10)
  .setName('initExtraLocalProps')
  .setPure(false)
  .add(initExtraLocalProps, true);
