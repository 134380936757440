import { coreLogicMethods } from './logicMgr';
import { INIT } from '@isomorix/core-actions';
import {
  CORE_LOGIC_TYPES,
  API_CORE_LOGIC
} from '@isomorix/core-config';


export function addInitLogic(method, pure) {
  coreLogicMethods.getBuilder(INIT, './init')
    .use()
    .setId(API_CORE_LOGIC.SEARCH)
    .setName(API_CORE_LOGIC.SEARCH)
    .setPure(pure)
    .setTransformOp()
    .setType(CORE_LOGIC_TYPES.ROUTER)
    .setDescription(`Manages a meilisearch route by connecting it to the backend service. If \`localProps.proxyTemplate\` is set, it will call its [setRouterLogic method]{@link module:nginx.Meilisearch#setRouterLogic} after ensuring the proper proxy props are set.`)
    .add(method, true);
}

