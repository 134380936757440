/**
 * @module core-ui/constants/uiRouteSlugs
 */
/**
 * @constant module:core-ui/constants/uiRouteSlugs.API
 * @type {string}
 */
const API = 'api';
/**
 * @constant module:core-ui/constants/uiRouteSlugs.API_GRAPH
 * @type {string}
 * @ignore
 */
const API_GRAPH = 'api_graph';
/**
 * @constant module:core-ui/constants/uiRouteSlugs.API_PLAYGROUND
 * @type {string}
 * @ignore
 */
const API_PLAYGROUND = 'api_playground';



/**
 * @constant module:core-ui/constants/uiRouteSlugs.APP_BUILDER
 * @type {string}
 * @ignore
 */
const APP_BUILDER = 'app_builder';
/**
 * @constant module:core-ui/constants/uiRouteSlugs.DOCS
 * @type {string}
 * @ignore
 */
const DOCS = 'docs';

/**
 * @constant module:core-ui/constants/uiRouteSlugs.GRAPHQL_FORCE_GRAPH
 * @type {string}
 * @ignore
 */
const GRAPHQL_FORCE_GRAPH = 'graphql_force_graph';

/**
 * @constant module:core-ui/constants/uiRouteSlugs.GRAPHQL_PLAYGROUND
 * @type {string}
 * @ignore
 */
const GRAPHQL_PLAYGROUND = 'graphql_playground';

/**
 * @constant module:core-ui/constants/uiRouteSlugs.GRAPHQL_VOYAGER
 * @type {string}
 * @ignore
 */
const GRAPHQL_VOYAGER = 'graphql_voyager';

/**
 * @constant module:core-ui/constants/uiRouteSlugs.ROUTER
 * @type {string}
 * @ignore
 */
const ROUTER = 'app';

/**
 * @constant module:core-ui/constants/uiRouteSlugs.USER
 * @type {string}
 * @ignore
 */
const USER = 'user';

export const ROUTE_SLUGS = {
  API,
  API_GRAPH,
  API_PLAYGROUND,


  APP_BUILDER,
  DOCS,
  GRAPHQL_FORCE_GRAPH,
  GRAPHQL_PLAYGROUND,
  GRAPHQL_VOYAGER,
  ROUTER,
  USER
};
