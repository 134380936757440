import { DocsRoute } from './Docs';
import { coreLogicRoutes } from '../coreLogicValues';
import { docsRouteLogicMgr } from './logic';

export function addCoreLogicValues(pluginBuilder) {
  coreLogicRoutes.addRoute(
    DocsRoute,
    './docs/route',
    docsRouteLogicMgr
  );
}
