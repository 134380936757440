import { appBuilderLogicMgr as logicMgr } from './logicMgr';
import { MUTATION } from '@isomorix/core-actions';
import {
  useCss,
  useRouterContext,
  getThemeType,
  setThemeType,
} from '@isomorix/react-router';
import { useLayoutContext } from '@isomorix/react-md-layout';
import { TextContainer, Typography } from '@react-md/typography';
import { Button } from '@react-md/button';
import { FontIcon, TextIconSpacing } from '@react-md/icon';

const builder = logicMgr.getBuilder(MUTATION, './mutation');

if (process.env.BROWSER) {
  function ensureAppClient(action) {
    const sv = action.meta.store.value;
    if (sv.get('match')) {
      let localProps = sv.get('localProps');
      if (!localProps.appMgr && localProps.appModule) {
        const logic = action.meta.instance;
        localProps = logic.getMutableLocalProps();
        const appMgr = localProps.appModule.createAppMgr();
        const main = action.meta.store.mainStore.instance;
        appMgr.getRootThemeType = () => {
          return getThemeType(main.getSession());
        }
        appMgr.setRootThemeType = (themeType) => {
          return setThemeType(main.getSession(), themeType);
        }
        appMgr.locSearchKey = 'abloc';
        appMgr.useRouterContext = useRouterContext;
        appMgr.useLayoutContext = useLayoutContext;
        appMgr.useCss = useCss;
        appMgr.TextContainer = TextContainer;
        appMgr.Typography = Typography;
        appMgr.Button = Button;
        appMgr.FontIcon = FontIcon;
        appMgr.TextIconSpacing = TextIconSpacing;
        localProps.appMgr = appMgr;
        logic.localProps = localProps;
      }
    }
    return action;
  }

  builder.useRelative('trxOptimisticExecute', 10)
    .setName('ensureAppClient')
    .setPure(true)
    .add(ensureAppClient, true)
}
