import { Router } from './Router';
import { coreLogicRoutes } from '../coreLogicValues';
import { routerLogicMgr } from './logic';



export function addCoreLogicValues(pluginBuilder) {
  coreLogicRoutes.addRouter(Router, './router', routerLogicMgr);
}

export { Router, routerLogicMgr };



