import React, { useEffect, useState } from 'react';
import { AppBarAction, useActionClassName } from '@react-md/app-bar';
import { CircularProgress, LinearProgress } from '@react-md/progress';
import { FontIcon } from '@react-md/icon';
import { useAddMessage } from '@react-md/alert';
import { Button } from '@react-md/button';
import {
  DropdownMenu,
  MenuItem,
} from '@react-md/menu';
import { TextIconSpacing } from '@react-md/icon';
import { ListItem } from '@react-md/list';
import { MODEL_NAMES as M } from '@isomorix/core-config';
import { forkJoin } from 'rxjs';
import { Divider } from '@react-md/divider';
import { useRecordSubscription } from '@isomorix/react';

const createOnClick = (logic, role, addMessage) => () => {
  const session = logic.getSession();
  const m = session.plugin.pluginInstance
    .mutation()
    .switchTo(M.CORE_LOGIC);
  logic = m.update(logic, {
    localProps: { ...logic.localProps, userLoading: true }
  }, true);
  const CoreLogicModel = m.model;
  m.execute();
  session
    .switchToRole(role.slug, m.dispatchId)
    .subscribe((resp) => {
      CoreLogicModel.mutation()
        .update([ logic ], {
          localProps: { ...logic.localProps, userLoading: false }
        }, true)
        .execute();
      if (resp) {
        addMessage({
          children: `You are now using the ${role.name} role.`
        });
      } else {
        addMessage({
          messagePriority: 'immediate',
          children: `An unknown error occurred switching to the ${role.name} role.`,
          action: 'Ok',
          disableAutohide: true
        });
      }
    })

}
function NestedItem(props) {
  const { session, logic, addMessage, RoleAvatar } = props;
  const plugin = session.plugin;
  const { userRole } = session;
  const user = userRole.user;
  const [ loaded, setLoaded ] = useState(
    !plugin.isMissingRoles
    && !user.isMissingRoles
  );
  useEffect(() => {
    if (!plugin.isMissingRoles && !user.isMissingRoles) {
      return;
    }
    const sub = forkJoin([ plugin.loadRoles(), user.loadRoles() ])
      .subscribe(() => {
        setLoaded(true);
      });
    return () => sub.unsubscribe();
  }, [ setLoaded, plugin, user ]);
  if (!loaded) {
    return <MenuItem><LinearProgress key={'user-menu-loading'} /></MenuItem>
  }
  const { roles } = user;
  const curr = userRole.role.slug;
  let children;
  for(let slug in roles) {
    if (slug !== curr && (children || (children = []))) {
      children.push(
        <MenuItem
          key={slug}
          leftAddon={<RoleAvatar role={roles[slug]} session={session} isMenuItem={true}/>}
          onClick={createOnClick(logic, roles[slug], addMessage)}
        >
          { roles[slug].name }
        </MenuItem>
      );
    }
  }
  if (!children) {
    return <ListItem key={'no-more-roles'}>(no more roles)</ListItem>
  }
  return (
    <>
      { children }
    </>
  )
}

function createItems(logic, session, addMessage, RoleAvatar) {
  const { userRole } = session;
  const { role } = userRole;
  const logout = () => {
    logic.setLocalProps({ userLoading: true });
    session.logout().subscribe(() => {
      addMessage({
        children: 'You have been logged out.',
      });
      logic.setLocalProps({ userLoading: false });
    })
  };
  const { firstName, lastName } = userRole.user;
  return [
    <MenuItem
      key={'current-role'}
      secondaryText={role.name}
      leftAddon={<RoleAvatar role={role} session={session} isMenuItem={true} />}
    >
      { firstName } { lastName }
    </MenuItem>,
    <DropdownMenu
      id={'sub-menu-id-1'}
      key={'switch-roles-dropdown'}
      // anchor={BELOW_CENTER_ANCHOR}
      portal={false}
      buttonChildren={'Switch Roles'}
    >
      <NestedItem
        key={1}
        session={session}
        logic={logic}
        addMessage={addMessage}
        RoleAvatar={RoleAvatar}
      />
    </DropdownMenu>,
    <Divider key={'user-role-menu-divider-1'} />,
    <MenuItem
      key={'user-logout'}
      leftAddon={<FontIcon>logout</FontIcon>}
      onClick={ logout}
    >
      <span>Logout</span>
    </MenuItem>
  ];
}

const progressComponent = (
  <CircularProgress
    style={{marginRight: '1rem'}}
    circleStyle={{stroke: '#000'}}
  />
);

const sessKeys = [ 'userRoleId' ];

export const UserMenu = React.memo(function UserMenu(props) {
  const { logic, RoleAvatar } = props;
  const { localProps } = logic;
  const session = logic.getSession();
  useRecordSubscription(session, sessKeys);
  useRecordSubscription(logic);
  const addMessage = useAddMessage();
  let className = useActionClassName({
    first: props.first || false,
    last: props.last || false,
    inheritColor: typeof props.inheritColor === 'boolean'
      ? props.inheritColor
      : true,
  });
  if (session.userRoleId) {
    const { userRole } = session;
    const { nameMaxLength } = props;
    const { firstName, lastName } = userRole.user;
    let text = firstName;
    const icon = localProps.userLoading
      ? progressComponent
      : (
        <RoleAvatar
          role={userRole.role}
          session={session}
          isMenuItem={false}
          nameMaxLength={nameMaxLength}
          initials={`${firstName.substring(0, 1).toUpperCase()}${lastName ? lastName.substring(0, 1).toUpperCase() : ''}`}
        />
      );
    let buttonType = 'text', buttonChildren;
    if (props.nameMaxLength === 0) {
      buttonType = 'icon';
      buttonChildren = icon;
    } else {
      if (nameMaxLength && text.length > nameMaxLength) {
        text = `${text.substring(0, nameMaxLength)}...`;
      }
      buttonChildren = (
        <TextIconSpacing icon={icon}>{ text }</TextIconSpacing>
      );
    }
    return (
      <DropdownMenu
        id={'app-bar-user-options'}
        className={className}
        buttonType={buttonType}
        buttonChildren={buttonChildren}
        aria-label={`user menu`}
      >
        { createItems(logic, session, addMessage, RoleAvatar) }
      </DropdownMenu>
    )
  } else if (localProps.userLoading) {
    return (
      <AppBarAction key={'userChild'} aria-label={'loading'}>
        { progressComponent }
      </AppBarAction>
    );
  } else {
    let children = props.loginButtonChildren;
    if (props.loginButtonType === 'icon') {
      if (!children) {
        children = <FontIcon>manage_accounts</FontIcon>
      } else if (typeof children === 'string') {
        children = <FontIcon>{children}</FontIcon>
      }
    } else if (!children) {
      children = 'Login';
    }
    return (
      <Button
        className={className}
        type={'button'}
        buttonType={props.loginButtonType || 'text'}
        themeType={'flat'}
        aria-label={'login or register'}
        onClick={() => session.location.pushSearch({ user_login: true })}
      >
        { children }
      </Button>
    );
  }
});
