import React from 'react';
import { forwardRef } from 'react';
import { useLocation } from './hooks';

/**
 * Renders a component that when clicked will trigger
 * a navigation to the provided `href` or `to`.
 *
 * The [useOnClick hook]{@link module:react-router.useOnClick}
 * can also be used instead, since it provides the same
 * functionality as this component.
 * @function Link
 * @memberof module:react-router
 * @param {Object} props
 * @param {string} [props.component = "a"] - The component
 * type to use when rendering the link.
 * @param {string} [props.href] - A string representing
 * the location to navigate to when the link is clicked.
 * @param {Object} [props.to] - An Object containing
 * details about the location to navigate to can
 * also be provided rather than the `href` prop.
 * @param {function(Event)} [props.onClick] - A function
 * to call when the component is clicked. This
 * will be called prior to performing the navigation.
 * @param {boolean} [props.preventMaliciousTarget = true] - Whether
 * to include `rel="noopener noreferer"` if
 * `props.target === "_blank"`.
 * @param {boolean} [props.replace = false] - If `true`,
 * the [location's replace method]{@link module:core/location.Record#replace}
 * (which will replace the current location with the
 * provided `href` or `to`) will be used
 * rather than the [location's push method]{@link module:core/location.Record#push}
 * (which pushes a new entry on the history stack).
 */
export const Link = forwardRef(function Link({
  component = 'a',
  href,
  onClick,
  preventMaliciousTarget = true,
  replace,
  to,
  ...props
},
 ref
) {
  /**
   * @type {module:core/location.Record}
   * @ignore
   */
  const location = useLocation();
  const { target } = props;
  props.ref = ref;
  if (!href && to) {
    if (typeof to !== 'string' && !to.pathname) {
      to = { ...to, pathname: location.pathname };
    }
    href = location.createPath(to);
  }
  props.href = href;
  if (
    preventMaliciousTarget
    && typeof props.rel !== 'string'
    && props.target === '_blank'
  ) {
    props.rel = 'noopener noreferrer';
  }
  props.onClick = (e) => {
    if (onClick) {
      try {
        const resp = onClick(e, location);
        if (e.defaultPrevented) return;
        if (resp) {
          href = location.createPath(resp);
          to = resp;
        }
      } catch (err) {
        e.preventDefault();
        throw err;
      }
    }
    if (
      href
      && e.button === 0 // only left-click
      && (!target || target === '_self')
      // rest would be modified clicking of some type. Ignore if true.
      && !e.shiftKey
      && !e.altKey
      && !e.ctrlKey
      && !e.metaKey
    ) {
      e.preventDefault();
      let state, title;
      if (to && typeof to === 'object') {
        ({ state, title } = to);
      }
      if (replace) {
        location.replaceState(state, title, href);
      } else {
        location.pushState(state, title, href);
      }
    }
  }
  return React.createElement(component, props);
});
