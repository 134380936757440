export const appBuilder = {
  name: 'appBuilder',
  headline: [
    'Drag & Drop',
    { children: 'Visual App Builder', color: 'theme-secondary' },
    'For The Ultimate No-Code Solution... Without The Tradeoffs'
  ],
  video: {
    url: 'https://dev-39.wistia.com/medias/nyan7mwi1l',
    preview: '/static/img/vid/breakout_app_builder_devs_preview.webp',
    totalSeconds: 926,
  },
  subHeadline: [
    { children: 'Tip:', weight: 'bold' },
    'This is a',
    { children: 'fantastic', fontStyle: 'italic' },
    'way to rapidly generate mockups and stage new features with bare-minimum effort!'
  ]
};
