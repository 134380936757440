import { createElement } from 'react';
import {
  hydrate as reactHydrate,
  render,
  unmountComponentAtNode,
} from 'react-dom'
import { getInsertCssFn } from '@isomorix/ui-router';
import { INIT } from '@isomorix/core-actions';
import { routerLogicMgr } from './logicMgr';
import { mergeMapTo } from 'rxjs/operators';
import { ofChunksReady } from '../ofChunksReady';
import { delay } from 'rxjs/operators';


function hydrate(action) {
  const { meta, } = action;
  const logic = meta.instance.getMainInstance();
  let { props } = action.payload;
  if (!props) {
    props = {};
  }
  if (!props.insertCss) {
    props.insertCss = getInsertCssFn();
  }
  props.logic = logic;
  if (!props.session) {
    props.session = meta.getSession().getMainInstance();
  }
  const { onRender, isReload } = action.payload;
  const { containerId } = meta.instance.props;
  if (isReload) {
    unmountComponentAtNode(document.getElementById(containerId));
  }
  const cb = () => {
    if (onRender) {
      if (isReload) console.log('App Re-Rendered');
      setTimeout(() => onRender(), 100);
    }
  };
  meta.mainActionMeta.pipe(
    delay(5),
    /*
     * Could be redundant if the app's
     * entry also waited.
     */
    mergeMapTo(ofChunksReady())
  ).subscribe(() => {
    props.location = props.session.location;
    if (isReload) {
      render(
        createElement(logic.component, props),
        document.getElementById(containerId)
      );
    } else {
      reactHydrate(
        createElement(logic.component, props),
        document.getElementById(containerId)
      );
    }
    cb();
  });
  return action;
}

routerLogicMgr.findExistingBuilder(INIT)
  .useProps('hydrate')
  .setLogicMgr(routerLogicMgr, './init')
  .setPure(true)
  .add(hydrate, true);



