import React from 'react';
import { useRecordSubscription } from '@isomorix/react';
import { MessageQueue } from '@react-md/alert';
import { Configuration } from "@react-md/layout";
import {
  Router as ReactRouter,
  HelmetProvider
} from '@isomorix/react-router';
import { LayoutContainer, css } from './LayoutContainer';
import { Head, insertMainStyles } from '../Head';
import { CORE_LOGIC } from '../constants';

/*
 * The ConfigurationProps are just all the props for the providers
 * joined together. The only difference is that onResize has been
 * renamed to onAppResize for the AppSizeListener
 *
 * If overrides are used, add them to this Object
 * and then:
 * <Configuration { ...overrides }>
 */
// const overrides = {};

export const Router = function CoreUiRouter(props) {
  const changeKey = useRecordSubscription(props.logic);
  const head = (
    <Head
      logic={props.logic}
      location={props.location}
      session={props.session}
    />
  );
  if (props.headOnly) {
    insertMainStyles(props, css);
    return (
      <HelmetProvider context={props.helmetContext}>
        { head }
      </HelmetProvider>
    );
  }
  return (
    <Configuration>
      <MessageQueue position='bottom'>
        <ReactRouter { ...props } logicChangeKey={changeKey}>
          { head }
          <LayoutContainer { ...props } />
        </ReactRouter>
      </MessageQueue>
    </Configuration>
  )
}

Router.displayName = CORE_LOGIC.ROUTER;
