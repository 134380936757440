import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import { map } from 'rxjs/operators';
import {
  CORE_DIRECTIVES,
  MODEL_NAMES as M
} from '@isomorix/core-config';
import { LinearProgress } from '@react-md/progress';
import { Form } from '@react-md/form';
import { Grid } from '@react-md/utils';
import {
  FormController,
  Password,
  SubmitButton,
  TextField
} from '@isomorix/react-md-form';
import { Typography } from '@react-md/typography';
import { useAddMessage } from '@react-md/alert';
import { defaultSubmitStyle } from './inlineStyles';
import { FontIcon } from '@react-md/icon';

export const RegisterForm = React.memo(function RegisterForm(
  {
    session, // required, all remaining are optional
    onComplete,
    beforeChildren,
    beforeSubmitChildren,
    submitButton,
    afterSubmitChildren,
    isVisible
  }
) {
  const pluginRec = session.plugin;
  const plugin = pluginRec.pluginInstance;

  const addMessage = useAddMessage();
  const [ errorCode, setErrorCode ] = useState(null);
  const [ fields, setFields ] = useState(false);
  useEffect(() => {
    if (!isVisible) return;
    const elem = document.getElementById('register-first-name');
    if (elem) {
      elem.focus();
    }
  }, [ isVisible ]);
  useEffect(() => {
    const sub = session.loadUserModels().pipe(
      map(() => {
        const User = plugin.switchTo(M.USER);
        const fieldRecs = User.schema.fieldRecords;
        const directive = pluginRec.directives[CORE_DIRECTIVES.USER_CREATE_PASSWORD];
        const { args } = directive;
        const fields = {
          ...args,
          confirmPassword: args.password,
          firstName: fieldRecs.firstName,
          lastName: fieldRecs.lastName,
          email: fieldRecs.email,
        };
        setFields(fields);
      })
    ).subscribe();
    return () => sub.unsubscribe();
  }, [ session, plugin ]);
  const onSubmit = useCallback((state, setState) => {
    const {
      errors,
      values: {
        email,
        password,
        confirmPassword
      }
    } = state;
    if (password !== confirmPassword) {
      errors.confirmPassword = `The passwords do not match.`;
      state.errorCount = 1;
      setState(state);
      return;
    }
    state.submitting = true;
    setState(state);
    session.registerUser(state.values).subscribe(user => {
      if (!user) {
        setErrorCode(500);
      } else if (typeof user === 'number') {
        // duplicate
        setErrorCode(user);
      } else {
        session.login({ email, password }).subscribe(sess => {
          if (sess && sess.userRoleId) {
            const { userRole } = sess;
            addMessage({
              children: (
                <>
                  <p>Welcome, {userRole.user.firstName}! You have been registered with the {userRole.role.name} role, and you are now logged in.</p>
                  <p style={{marginTop: '1rem'}}>Check your email ({ email }) for additional details!
                  </p>
                </>
              ),
              action: {
                themeType: 'contained',
                children: 'Got it!'
              },
              disableAutohide: true,
              twoLines: true
            });
            if (onComplete) {
              onComplete(sess);
            } else {
              sess.location.replaceSearch({ user_login: undefined });
            }
          } else {
            setErrorCode(500);
          }
        })
      }
    })
  }, [ session, plugin ])
  if (!fields) {
    return <LinearProgress/>
  }
  let errorComponent = null;
  if (errorCode) {
    if (errorCode === 409) {
      // duplicate, but not going to advertise that
      return <Typography type={'body-1'} className={'rmd-label--error'}>Check your email for confirmation.</Typography>;
    } else {
      return <Typography type={'body-1'} className={'rmd-label--error'}>An unknown error occurred. Please try again later.</Typography>;
    }
  }
  if (!submitButton) {
    submitButton = (
      <SubmitButton
        id={'dialog-register-close'}
        style={defaultSubmitStyle}
        text={'Register'}
        submittingText={'Registering...'}
        progressOnSubmit={true}
        leftIcon={'gpp_good'}
        theme={'primary'}
        themeType={'contained'}
      />
    )
  }
  return (
    <FormController fields={fields} onSubmit={onSubmit}>
      <Form>
        <Grid>
          { beforeChildren }
          { errorComponent }
          <TextField
            id={'register-first-name'}
            autoComplete={'given-name'}
            name={'firstName'}
            label={'First Name*'}
            rightChildren={<FontIcon>person</FontIcon>}
          />
          <TextField
            id={'register-last-name'}
            autoComplete={'family-name'}
            name={'lastName'}
            label={'Last Name'}
            rightChildren={<FontIcon>person</FontIcon>}
          />
          <TextField
            id={'register-email'}
            autoComplete={'email'}
            name={'email'}
            label={'Email*'}
            type={'email'}
            rightChildren={<FontIcon>email</FontIcon>}
          />
          <Password
            id={'register-password'}
            autoComplete={'new-password'}
            name={'password'}
            label={'Password*'}
            type={'password'}
          />
          <Password
            id={'register-confirm-password'}
            autoComplete={'new-password'}
            name={'confirmPassword'}
            label={'Confirm Password*'}
            type={'password'}
          />
          { beforeSubmitChildren }
          { submitButton }
        </Grid>
      </Form>
    </FormController>
  )
});
