import { from, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';

export function copyTextToClipboard(text) {
  if (typeof navigator === 'undefined') return of(false);
  return from(navigator.clipboard.writeText(text))
    .pipe(
      mapTo(true),
      catchError(() => of(false))
    );
}
