import React, { useMemo, useRef } from 'react';
import scssVariables from "@react-md/theme/dist/scssVariables";
import {
  getBestMatch,
  Helmet,
  getThemeType,
  findThemeType,
  setThemeType
} from '@isomorix/react-router';
import { useRecordSubscription } from '@isomorix/react';
import { PATHS } from './constants';
import { isDocsPath, isVoyagerPath } from './helpers';

const defaultRouteMeta = {
  title: `isomx - web apps made easy`,
  description: 'Build fast, responsive isomorphic web apps.',
  keywords: "isomx, react, react-md, progressive web app, redux, graphql",
  author: "Josh Cope"
}

const getColorVar = (color, hue) => {
  switch(hue) {
    case 100:
    case 200:
    case 400:
    case 700:
      return scssVariables[`rmd-${color}-a-${hue}`];
    default:
      return scssVariables[`rmd-${color}-${hue}`];
  }
}
const getHtmlStyle = (colorProps) => {
  const {
    primaryColor = 'indigo',
    primaryColorHue = 500,
    secondaryColor = 'red',
    secondaryColorHue = 200,

    // primaryColor = 'cyan',
    // primaryColorHue = 500,
    // secondaryColor = 'orange',
    // secondaryColorHue = 400,

    progressBackgroundColor = 'red',
    progressBackgroundColorHue = 200,
    progressColor = 'red',
    progressColorHue = 900
  } = colorProps;
  const p = getColorVar(primaryColor, primaryColorHue);
  const s = getColorVar(secondaryColor, secondaryColorHue);
  const progressBkg = getColorVar(
    progressBackgroundColor,
    progressBackgroundColorHue
  );
  const progress = getColorVar(progressColor, progressColorHue);
  return `--rmd-theme-primary: ${p}; --rmd-theme-secondary: ${s}; --rmd-progress-background-color: ${progressBkg}; --rmd-progress-color: ${progress};`;
}

let insertMainStyles, useThemeType;

/*
 * This can be used to include the main
 * css file rather than embedding via useCss().
 */
// const staticStyles = (
//   <link
//     key={'mainApp'}
//     rel="stylesheet"
//     href={`${PATHS.APP_CSS}`}
//     type="text/css"
//   />
// );

function renderHeader(
  {
    routeMeta,
    themeType,
    viewMode,
    isVoyager,
    href,
    title,
    colorProps
  }
) {
  const d = routeMeta
    ? { ...defaultRouteMeta, ...routeMeta }
    : defaultRouteMeta;
  if (!title) {
    title = d.title;
  } else if (!title.startsWith('isomx')) {
    title = `isomx ${title}`
  }
  let cls = `view-mode-${viewMode || '2D'}`;
  if (themeType) {
    cls += ` ${themeType}-theme`;
  }
  if (isVoyager) {
    cls += ' graphql-voyager-route';
  }
  return (
    <Helmet prioritizeSeoTags>
      <html
        lang="en"
        dir="ltr"
        className={cls}
        style={getHtmlStyle(colorProps)}
      />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin
      />
      <meta charSet="utf-8"/>
      <meta httpEquiv="x-ua-compatible" content="ie=edge"/>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <title>{title}</title>
      <meta name="description" content={`${d.description}`} />
      <meta name="keywords" content={d.keywords} />
      <meta name="author" content={d.author} />
      <link rel="canonical" href={href} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={d.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={href} />
      <meta property="twitter:site" content="isomx.com"/>
      <meta property="twitter:creator" content={d.author}/>
      <meta property="twitter:title" content={title}/>
      {/*<meta name="theme-color" content="#3f51b5" />*/}
      <meta name="theme-color" content="#202b68" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="apple-touch-icon" href="/static/img/icons/icon-192x192.png"  />
      <link
        key='preload-app-styles'
        rel={'preload'}
        href={`${PATHS.APP_CSS}`}
        as={'style'}
      />
      <link
        key={'mainApp'}
        rel="stylesheet"
        href={`${PATHS.APP_CSS}`}
        type="text/css"
      />
    </Helmet>
  )
}
/*
 * If we include the CSS in the client bundle
 * it'll get bundled. But these are the
 * main styles, so they will be returned
 * from the server response and inserted
 * in <head />. Therefore no need to
 * also include them in the client bundle.
 *
 * This also means that we handle
 * rendering a bit different, since
 * we'll be rendered *before* the
 * actual app so that the head can
 * be flushed to the client ASAP.
 */
if (process.env.BROWSER) {
  useThemeType = (session) => {
    const firstRender = useRef(true);
    useRecordSubscription(session);
    let themeType;
    if (firstRender.current) {
      firstRender.current = false;
      themeType = getThemeType(session) || findThemeType(session, true);
      setThemeType(session, themeType);
    } else {
      themeType = getThemeType(session);
    }
    return themeType;
  }
} else {
  let featuresCss;
  // let mainCss;
  /*
   * We may be started as a standalone
   * (i.e., not webpack bundle). In
   * that case we'll get an "unexpected syntax"
   * error. So just disregard.
   */
  try {
    /*
     * Uncomment to use import vs. link tag.
     * Comment out the <link> tags if doing so.
     */
    // mainCss = require('./appDenseStyles.min.css');
    featuresCss = require('./features').css;
    /*
     * Uncomment to compile them. But they never
     * change, so it speeds up development to
     * include the precompiled minified css.
     */
    // mainCss = require('./index.scss');
  } catch (e) {}

  useThemeType = (session) => {
    return findThemeType(session) || getThemeType(session) || 'dark';
  }

  insertMainStyles = (props, layoutCss) => {
    // props.insertCss(mainCss);
    props.insertCss(layoutCss);
    props.insertCss(featuresCss);
    if (isDocsPath(props.location.pathname)) {
      const { docsTemplateCss } = props.logic.localProps;
      if (docsTemplateCss) {
        props.insertCss(docsTemplateCss);
      }
    }

    // if (props.logic.match.isExact) {
    //   props.insertCss(featuresCss);
    // } else if (isDocsPath(props.location.pathname)) {
    //   const { docsTemplateCss } = props.logic.localProps;
    //   if (docsTemplateCss) {
    //     props.insertCss(docsTemplateCss);
    //   }
    // }
  }
}

const locKeys = [ 'key' ];

export const Head = function Head(
  {
    logic,
    location,
    session
  }
) {
  useRecordSubscription(location, locKeys);
  const themeType = useThemeType(session);
  const { href, title } = location;
  const logicProps = logic.props;
  const viewMode = logic.localProps.viewMode;
  const isVoyager = isVoyagerPath(location.pathname);
  return useMemo(() => renderHeader({
    href,
    routeMeta: getBestMatch(logic).route.meta,
    themeType,
    colorProps: logicProps,
    viewMode,
    isVoyager,
    title
  }), [
    logic,
    href,
    themeType,
    logicProps,
    viewMode,
    isVoyager,
    title
  ]);
}

export { insertMainStyles };
