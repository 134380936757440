import React, { createElement } from 'react';
import { Link as ReactRouterLink } from '@isomorix/react-router';
import { Link as RmdLink } from '@react-md/link';

export const Link = React.memo(function Link(
  {
    ...props
  }
) {
  props.component = ReactRouterLink;
  return createElement(RmdLink, props);
})
