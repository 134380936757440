import { LogicMgr } from '@isomorix/store';
import { recordLogicMgr } from '@isomorix/record';
import { INIT, MUTATION } from '@isomorix/core-actions';
import { CORE_LOGIC_TYPES } from '@isomorix/core-config';

export const routerPlaceholdersLogicMgr = LogicMgr
  .initModule('@isomorix/ui-router/router/logic');

/**
 * The LogicMgr that contains logic for the
 * [CoreLogic record]{@link module:core/coreLogic.Record}
 * that is managing the plugin's top-level UI route,
 * most commonly referred to as the plugin's
 * [router logic]{@link module:core-config/coreLogicTypes.ROUTER}.
 * @name routerLogicMgr
 * @memberof module:ui-router
 * @type {module:store.LogicMgr}
 */
export const routerLogicMgr = LogicMgr
  .initModule(`@isomorix/ui-router/router/logic`)
  .addSource(routerPlaceholdersLogicMgr)
  .addSource(recordLogicMgr);

export const routerInitBuilder = routerLogicMgr
  .getBuilder(INIT, './init')
  .setType(CORE_LOGIC_TYPES.ROUTER);

export const routerMutationBuilder = routerLogicMgr
  .getBuilder(MUTATION, './mutation')
  .setType(CORE_LOGIC_TYPES.ROUTER);
