import { useEffect, useRef, useState } from 'react';
import { Subscriber } from "rxjs";
import { getStateChangeKey } from "./utils";

/**
 * Subscribes to a store and triggers a rerender when changes occur.
 * @function useRecordSubscription
 * @memberof module:react/hooks
 * @param {module:record.Record} record
 * @param {Array.<string>} [keys = [ "__changesDispatchId", "__changesEvent" ] ] - Optionally
 * provide the names of the fields that should
 * be monitored.
 *
 * If provided, this hook will only trigger a rerender
 * if one of the field values change.
 *
 * Otherwise, it will rerender any time the state changes.
 * @returns {string}
 * A hash of the values specified by the `keys` param.
 */
export function useRecordSubscription(record, keys) {
  const isFirst = useRef(true);
  const [ changeKey, setChange ] = useState(isFirst.current
    ? getStateChangeKey(record, keys)
    : undefined
  );
  useEffect(() => {
    const first = isFirst.current;
    isFirst.current = false;
    if (!record || !record.__ID) {
      if (!first) {
        setChange(null);
      }
      return undefined;
    }
    const recordSub = record.subscribeAndPersist(new RecordSubscriber(setChange, keys, record));
    if (!first) {
      setChange(getStateChangeKey(record, keys));
    }
    return () => recordSub.unsubscribe();
  }, [ record, keys, setChange ]);
  return changeKey;
}

class RecordSubscriber extends Subscriber {
  constructor(setChange, keys, record) {
    super();
    this.setChange = setChange;
    this.recordKeys = keys;
    this.record = record;
    this.__prefix = record.__dispatchId ? 'pending' : 'main';
  }

  _next(record) {
    if (this.__prefix === 'pending' && !record.__dispatchId) {
      this.__prefix = 'main';
    } else if (!record.__changes) {
      return;
    }
    this.record = record;
    this.setChange(getStateChangeKey(record, this.recordKeys, this.__prefix));
  }

  _complete() {
    if (this.setChange && this.record) {
      this.setChange(null);
      this.unsubscribe();
    }
  }

  unsubscribe() {
    super.unsubscribe();
    this.setState = undefined;
    this.record = undefined;
  }
}
