import React, {
  createElement,
  useEffect,
  useMemo,
  useRef
} from 'react';
import {
  ExpansionList as RmdExpansionList,
  usePanels
} from '@react-md/expansion-panel';
import { ExpansionPanel } from './ExpansionPanel';
// import { bem } from '../helpers';

// const block = bem('');

export const ExpansionList = React.memo(function ExpansionList(
  {
    name,
    panels,
    expandedIds: propsExpandedIds,
    setState,
    location,
  }
) {
  const defaultExpandedIndex = useMemo(() => {
    if (!propsExpandedIds) {
      return [];
    }
    const b = `${name}OverviewExpansionList-`;
    return propsExpandedIds.map(entry => parseInt(
      entry.replace(b, '')
    ) - 1);
  }, []);
  const [
    panelsProps,
    onKeyDown,
    expandedIds,
  ] = usePanels({
    idPrefix: `${name}OverviewExpansionList`,
    count: panels.length,
    multiple: false,
    preventAllClosed: false,
    defaultExpandedIndex,
  });
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setState(name, { expandedIds });
  }, [ expandedIds, setState, name ]);
  const children = [];
  let i = 0;
  for(let panel of panels) {
    children.push(createElement(ExpansionPanel, {
      panel,
      ...panelsProps[i],
      key: panel.header.text,
      location
    }));
    i++;
  }
  return (
    <RmdExpansionList onKeyDown={onKeyDown}>
      { children }
    </RmdExpansionList>
  );
})


// export const ExpansionList = React.memo(function ExpansionList(
//   {
//     name,
//     panels,
//     state,
//     location
//   }
// ) {
//   const defaultExpandedIndex = useMemo(() => {
//     const { expandedIds } = state;
//     if (!expandedIds.length) return [];
//     let i = 0;
//     const length = panels.length;
//     const base = `${name}OverviewExpansionList`;
//     const resp = [];
//     let id;
//     while(i < length) {
//       id = `${base}-${i + 1}`;
//       if (expandedIds.indexOf(id) > -1) {
//         resp.push(i);
//       }
//       i++;
//     }
//     return resp;
//   }, []);
//   const [
//     panelsProps,
//     onKeyDown,
//     expandedIds,
//     setExpandedIds,
//   ] = usePanels({
//     idPrefix: `${name}OverviewExpansionList`,
//     count: panels.length,
//     multiple: true,
//     preventAllClosed: false,
//     defaultExpandedIndex,
//   });
//   if (name === 'query') {
//     console.log({
//       expandedIds,
//       setExpandedIds,
//       panelsProps,
//       state,
//     });
//   }
//   const createOnExpandClick = (panelId) => () => {
//     setExpandedIds(prevIds => {
//       const newState = { ...state };
//       console.log('CURRENT expandedIds = ', state.expandedIds);
//       const expandedIds = [ ...newState.expandedIds ];
//       newState.expandedIds = expandedIds;
//       const i = expandedIds.indexOf(panelId);
//       if (i === -1) {
//         expandedIds.push(panelId);
//       } else {
//         expandedIds.splice(i, 1);
//       }
//       const currState = location.state;
//       setTimeout(() => {
//         location.replaceState({
//           ...currState,
//           features: {
//             ...currState.features,
//             [name]: newState
//           }
//         });
//       }, 50);
//       console.log('new expandedIds = ', expandedIds);
//       console.log('i = ', i);
//       console.log('prevIds = ', prevIds);
//       return expandedIds;
//     })
//   }
//   const children = [];
//   let i = 0;
//   for(let panel of panels) {
//     children.push(createElement(ExpansionPanel, {
//       panel,
//       ...panelsProps[i],
//       onExpandClick: createOnExpandClick(panelsProps[i].id),
//       key: panel.header.text,
//     }));
//     i++;
//   }
//   return (
//     <RmdExpansionList onKeyDown={onKeyDown}>
//       { children }
//     </RmdExpansionList>
//   );
// })
