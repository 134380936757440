import React from 'react';
import {
  TextField as RmdTextField,
  Password as RmdPassword
} from '@react-md/form';
import { useField } from './useField';

const createGetValue = ref => () => ref.current.value;

const render = (props, Component) => {
  const {
    Container,
    containerProps,
    ErrorComponent,
    fieldProps,
    context,
    name
  } = props;
  const error = context.errors[name];
  fieldProps.error = !!error;
  fieldProps.disabled = context.submitting;
  return (
    <Container { ...containerProps }>
      <Component { ...fieldProps } />
      { error && <ErrorComponent>{error}</ErrorComponent>}
    </Container>
  )
}

export function TextField(props) {
  const formatted = useField(props, createGetValue);
  return render(formatted, RmdTextField);
}

export function Password(props) {
  const formatted = useField(props, createGetValue);
  return render(formatted, RmdPassword);
}

