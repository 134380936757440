import { of } from 'rxjs';
import { map } from 'rxjs/operators';

let layout3DLoader;
if (process.env.BROWSER) {
  layout3DLoader = (loader, coreStore, localProps) => {
    coreStore = coreStore.mainStore;
    let mod;
    function loadLayout3DModule() {
      if (mod) return of(mod);
      return loader().pipe(
        map(module => {
          if (mod) return mod;
          mod = {
            Layout3D: module.Layout3D,
            createLayout3DStore: module.createStoreFactory(coreStore)
          };
          return mod;
        })
      )
    }
    loadLayout3DModule.chunkName = loader.chunkName;
    if (localProps) {
      localProps.loadLayout3DModule = loadLayout3DModule;
      localProps.layout3DChunkName = loader.chunkName;
    }
    return loadLayout3DModule;
  }
}

/**
 * @param {function(): import("rxjs").Observable.<Object>} loader
 * @param {module:store.Store} coreStore
 * @param {Object} [localProps]
 * @returns {function(): import("rxjs").Observable.<?Object>}
 * @ignore
 */
export function manageLayout3DLoader(loader, coreStore, localProps) {
  return layout3DLoader
    ? layout3DLoader(loader, coreStore, localProps)
    : of(null);
}
