/**
 * @module core-ui/constants/pkg
 */
/**
 * @constant module:core-ui/constants/pkg.SLUG
 * @type {string}
 * @default core_ui
 */
const SLUG = 'core_ui';

export const PKG = {
  SLUG
}
