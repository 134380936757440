import React from 'react';
import { useActionClassName } from '@react-md/app-bar';
import { DropdownMenu, MenuItemLink } from '@react-md/menu';
import { FontIcon } from '@react-md/icon';
import { TwitterSvg, YouTubeSvg } from './svgs';
import { Button } from '@react-md/button';
import { copyTextToClipboard } from '../features/helpers';

export const SocialMenuAppBarAction = React.memo(function SocialMenu(
  {
    id = 'social-media-app-bar-menu',
    first = false,
    last = false,
    inheritColor = true
  }
) {
  let className = useActionClassName({ first, last, inheritColor });
  return (
    <DropdownMenu
      buttonType={'icon'}
      buttonChildren={<FontIcon>share</FontIcon>}
      id={id}
      aria-label={'social media links and email'}
      className={className}
    >
      <MenuItemLink
        leftAddon={<TwitterSvg />}
        href={'https://twitter.com/@isomxDev'}
        target={'_blank'}
      >@isomxDev</MenuItemLink>
      <MenuItemLink
        leftAddon={<YouTubeSvg />}
        href={'https://youtube.com/@isomx'}
        target={'_blank'}
      >
        @isomx
      </MenuItemLink>
      <MenuItemLink
        leftAddon={<FontIcon>email</FontIcon>}
        href={'mailto:contact@isomx.com'}
        rightAddon={(
          <Button
            style={{ fontSize: '0.7rem' }}
            onClick={(e) => {
              e.preventDefault();
              copyTextToClipboard('contact@isomx.com')
                .subscribe()
            }}
          >
            copy
          </Button>
        )}
      >contact@isomx.com</MenuItemLink>
    </DropdownMenu>
  )
})
