import { Observable, of } from 'rxjs';
import { loadableReady } from '@loadable/component';

let _ready = false;

export function ofChunksReady() {
  if (_ready) return of(true);
  return new Observable(observer => {
    if (_ready) {
      observer.next(true);
      observer.complete();
    } else {
      loadableReady(() => {
        _ready = true;
        observer.next(true);
        observer.complete();
      })
    }
  })
}
