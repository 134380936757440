import { PLUGIN_INIT } from '@isomorix/core-actions';
import { MODEL_NAMES as M } from '@isomorix/core-config';
import { pluginLogicMgr } from './logicMgr';
import {
  addCoreLogicValues as graphqlForceGraphValues
} from '../graphqlForceGraph';
import {
  addCoreLogicValues as graphqlPlaygroundValues
} from '../graphqlPlayground';
import {
  addCoreLogicValues as graphqlVoyagerValues
} from '../graphqlVoyager';
import { addCoreLogicValues as docsValues } from '../docs';
import { addCoreLogicValues as routerValues } from '../router';
import { addCoreLogicValues as appBuilderValues } from '../appBuilder';
import { addCoreLogicValues as userValues } from '../user';
import {
  coreLogicComponents,
  coreLogicMethods
} from '../coreLogicValues';
import { PERMISSIONS } from '../constants';

const builder = pluginLogicMgr.getBuilder(PLUGIN_INIT, './init');

function addCoreUiEnumValues(action) {
  /**
   * @type {module:plugin.PluginBuilder}
   * @ignore
   */
  const builder = action.payload.pluginBuilder;
  graphqlForceGraphValues(builder);
  graphqlPlaygroundValues(builder);
  graphqlVoyagerValues(builder);
  docsValues(builder);
  routerValues(builder);
  appBuilderValues(builder);
  userValues(builder);
  builder
    .addLogicMethodEnumValues(coreLogicMethods)
    .addLogicComponentEnumValues(
      M.CORE_LOGIC,
      Object.values(coreLogicComponents)
    )
    .addPermissionUserCanValues(Object.values(PERMISSIONS));
  return action;
}

builder.useRelative('defineScalarAndEnumTypes', -10)
  .setName('addCoreUiEnumValues')
  .setPure(true)
  .setDescription(`Compiles the methods for the CoreLogicMethodEnum, the components for the CoreLogicComponentEnum, and the permissions for the PermissionUserCanEnum.`)
  .add(addCoreUiEnumValues, true);

// function pluginReady(action) {
//   if (process.env.BROWSER) {
//     return action;
//   }
//   const sc = action.meta.store.storeConfig;
//   if (sc.proxy && !action.payload.devApi) {
//     return sc.proxy.build().pipe(
//       map((resp) => {
//         return action;
//       })
//     )
//   }
//   return action;
// }
//
// builder.use()
//   .setName('specialPluginReady')
//   .setPure(false)
//   .setCompleteOp()
//   .setPriority(100)
//   .add(pluginReady, true);
