/**
 * @module react-router/constants/pkg
 */
/**
 * @constant module:react-router/constants/pkg.SLUG
 * @type {string}
 * @default react_router
 */
const SLUG = 'react_router';

export const PKG = {
  SLUG
}
