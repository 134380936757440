import { PLUGIN_INIT } from '@isomorix/core-actions';
import { MODEL_NAMES as M } from '@isomorix/core-config';
import { mergeMap } from 'rxjs/operators';
import { asyncLogicResp } from '@isomorix/store';
import { CORE_LOGIC } from '../../constants';
import { createDocsLoader, createLayout3DLoader } from '../../chunks';
import { pluginLogicMgr } from '../../logic';
import { nextTick$ } from '../../helpers';
import { startChat } from '../../chat';
import { registerServiceWorker } from '../swRegister';

const builder = pluginLogicMgr.getBuilder(PLUGIN_INIT, './init');

function initAppLogic(action, observer) {
  const { meta } = action;
  /** @type {module:plugin.Plugin} */
  const plugin = meta.instance;
  const { coreLogic } = plugin.pluginRecord;
  const m = plugin.mutation()
    .executeWhen(action)
    .switchTo(M.CORE_LOGIC);
  let routerLogic, l;
  for(let key in coreLogic) {
    l = coreLogic[key];
    if (l.name === CORE_LOGIC.ROUTER && !l.routerLogicId) {
      routerLogic = m.getMutableRecord(l);
      break;
    }
  }
  const store = meta.store;
  const loc = meta.getSession().location;
  const docsPath = `${loc.protocol}//${loc.host}/static`;
  const localProps = routerLogic.getMutableLocalProps(action);
  createLayout3DLoader(store, localProps);
  createDocsLoader(store, docsPath, localProps);
  const isLighthouse = plugin.Cookie.get('lighthouse') === '1' ? true : false;
  localProps.isLighthouse = window.__IS_LIGHTHOUSE__ || isLighthouse;
  routerLogic.localProps = localProps;
  const mainLogic = routerLogic.getMainInstance();
  action.meta.mainActionMeta.subscribe({
    complete: () => {
      startChat(mainLogic);
      registerServiceWorker();
    }
  });
  const $ = nextTick$.pipe(
    mergeMap(() => routerLogic.dispatchCurrent({
      onRender: action.payload.onRender,
      isReload: action.payload.isReload
    }, action.meta.mainDispatchId)),
  );
  return asyncLogicResp($, action, observer);
}
builder.useProps('initAppLogic')
  .setName('initAppLogic')
  .setPure(false)
  .setDescription(`Initializes the app logic.`)
  .add(initAppLogic, true);
