import {
  routerLogicMgr as _routerMgr,
  routeLogicMgr as _routeMgr
} from '@isomorix/react-ssr';
import { Router } from './Router';
import { Route } from './Route';
import {
  CORE_LOGIC_ACTIONS as ACTIONS,
  CoreLogicMethods
} from '@isomorix/core-logic';
import { MODEL_NAMES } from '@isomorix/core-config';
import { CORE_LOGIC_IDS } from './constants';

const { INIT } = ACTIONS;

const coreLogicMethods = CoreLogicMethods
  .initModule('@isomorix/react-router/coreLogic');

const routerInitBuilder = _routerMgr.findExistingBuilder(INIT);
const routeInitBuilder = _routeMgr.findExistingBuilder(INIT);

const _init = (action, logicMgr, component, logic) => {
  const { meta } = action;
  // don't persist the INIT logic, since this is
  // the only time it'll be dispatched.
  let logicArray = logicMgr.getByActionType(INIT);
  meta.addLogic(logicArray);
  logicArray = logicMgr.getByActionType(INIT, true);
  // but persist the remaining, inFlight (2nd arg)
  // defaults to `true` already, which is fine.
  // No need to provide "all", since there couldn't
  // be any other inflight actions.
  meta.addLogic(logicArray, 'all');
  let rec = meta.instance;
  if (!rec.component) {
    rec = rec.getMutableRecord(action);
    rec.component = component;
  }
  meta.removeLogic(logic, 'all');
  return action;
}

function reactRouter(action, routerLogicMgr) {
  return _init(action, routerLogicMgr || _routerMgr, Router, this);
}

routerInitBuilder.use()
  .setId(CORE_LOGIC_IDS.ROUTER)
  .setName(CORE_LOGIC_IDS.ROUTER)
  .setPrepareOp()
  .setPriority(1)
  .setPure(true)
  .setDescription(`Initializes a router based on React.`)
  .setLogicMgr(coreLogicMethods, `./router`)
  .add(reactRouter, true);

function reactRoute(action, routeLogicMgr) {
  return _init(action, routeLogicMgr || _routeMgr, Route, this);
}

routeInitBuilder.use()
  .setId(CORE_LOGIC_IDS.ROUTE)
  .setName(CORE_LOGIC_IDS.ROUTE)
  .setPrepareOp()
  .setPriority(1)
  .setPure(true)
  .setDescription(`Initializes a route based on React.`)
  .setLogicMgr(coreLogicMethods, `./route`)
  .add(reactRoute, true);

export { coreLogicMethods };

/**
 * @class CoreLogicFactory
 * @memberof module:react-router
 * @classdesc
 * # Summary
 * A helper class that makes it easy to
 * build out the [CoreLogic records]{@link module:core/coreLogic.Record}
 * that handle routing in the app.
 *
 * # Usage
 * See the [static init method]{@link module:react-router.CoreLogicFactory.init}.
 *
 * Full documentation coming soon...
 */
export class CoreLogicFactory {
  constructor(logicMgr, components) {
    this.logicMgr = logicMgr;
    // An Object is used due to HMR. It
    // prevents duplicates.
    this.components = components || {};
  }

  /**
   * Initializes a new [CoreLogicFactory instance]{@link module:react-router.CoreLogicFactory}
   * that can be used to build out the
   * [CoreLogic records]{@link module:core/coreLogic.Record}
   * that are used to control routing in the app.
   *
   * Full documentation coming soon...
   * @function init
   * @memberof module:react-router.CoreLogicFactory.
   * @param {module:core/coreLogic.CoreLogicMethods|string} logicMgrOrModuleName -
   * An instance of the
   * [CoreLogicMethods class]{@link module:core/coreLogic.CoreLogicMethods},
   * or a `string` indicating the moduleName to use
   * when instantiating it.
   * @param {Object} [components = {}] - An Object that
   * each logic will be added to. Only necessary to
   * provide if managing the Object directly.
   *
   * Otherwise, just call the
   * [getComponentEnumValues method]{@link module:react-router.CoreLogicFactory#getComponentEnumValues}
   * and provide that to the PluginBuilder's
   * [addLogicComponentEnumValues method]{@link module:plugin.PluginBuilder#addLogicComponentEnumValues}.
   * @returns {module:react-router.CoreLogicFactory}
   */
  static init(logicMgrOrModuleName, components) {
    if (typeof logicMgrOrModuleName === 'string') {
      return new this(
        CoreLogicMethods.initModule(logicMgrOrModuleName),
        components
      );
    } else {
      return new this(logicMgrOrModuleName, components);
    }
  }

  _addLogic(component, moduleNameOrLogicMgr, logicMgr, builder, Component) {
    if (!logicMgr && typeof moduleNameOrLogicMgr !== 'string') {
      logicMgr = moduleNameOrLogicMgr;
      moduleNameOrLogicMgr = undefined;
    }
    let displayName;
    if (typeof component === 'string') {
      displayName = component;
    } else {
      Component = component;
      ({ displayName } = Component);
      this.components[displayName] = Component;
    }
    builder
      .setLogicMgr(this.logicMgr, moduleNameOrLogicMgr)
      .setId(displayName)
      .setName(displayName)
      .setArgs(logicMgr && [ logicMgr ])
      .add();
    return this;
  }

  /**
   * Full documentation coming soon...
   * @function addRouter
   * @memberof module:react-router.CoreLogicFactory#
   * @param {import("react").FC|string} componentOrName - The
   * functional component or the name for it and the default
   * [Router component]{@link module:react-router.Router}
   * will be used.
   * @param {string|module:store.LogicMgr} [moduleNameOrLogicMgr]
   * @param {module:store.LogicMgr} [logicMgr] - Only applicable
   * if `moduleNameOrLogicMgr` is a `moduleName`.
   * @returns {module:react-router.CoreLogicFactory}
   */
  addRouter(componentOrName, moduleNameOrLogicMgr, logicMgr) {
    return this._addLogic(
      componentOrName,
      moduleNameOrLogicMgr,
      logicMgr,
      routerInitBuilder.use(reactRouter),
      Router,
    );
  }

  /**
   * Full documentation coming soon...
   * @function addRoute
   * @memberof module:react-router.CoreLogicFactory#
   * @param {import("react").FC|string} componentOrName - The
   * functional component or the name for it and the default
   * [Route component]{@link module:react-router.Route}
   * will be used.
   * @param {string|module:store.LogicMgr} [moduleNameOrLogicMgr]
   * @param {module:store.LogicMgr} [logicMgr] - Only applicable
   * if `moduleNameOrLogicMgr` is a `moduleName`.
   * @returns {module:react-router.CoreLogicFactory}
   */
  addRoute(componentOrName, moduleNameOrLogicMgr, logicMgr) {
    return this._addLogic(
      componentOrName,
      moduleNameOrLogicMgr,
      logicMgr,
      routeInitBuilder.use(reactRoute),
      Route
    );
  }

  /**
   * Convenience method for adding the values to the
   * PluginBuilder during initialization.
   *
   * It uses the PluginBuilder's
   * [addLogicMethodEnumValues method]{@link module:plugin.PluginBuilder#addLogicMethodEnumValues}
   * and [addLogicComponentEnumValues method]{@link module:plugin.PluginBuilder#addLogicComponentEnumValues}.
   * @function addToEnumValues
   * @memberof module:react-router.CoreLogicFactory#
   * @param {module:plugin.PluginBuilder} pluginBuilder -
   * The PluginBuilder instance that is being used
   * to build out the Plugin before initialization.
   * @returns {module:react-router.CoreLogicFactory}
   */
  addToEnumValues(pluginBuilder) {
    pluginBuilder
      .addLogicMethodEnumValues(this.logicMgr)
      .addLogicComponentEnumValues(
        MODEL_NAMES.CORE_LOGIC,
        Object.values(this.components)
      );
    return this;
  }

  /**
   * Returns an Array of values to provide to
   * the PluginBuilder's
   * [addLogicComponentEnumValues method]{@link module:plugin.PluginBuilder#addLogicComponentEnumValues}.
   * @function getComponentEnumValues
   * @memberof module:react-router.CoreLogicFactory#
   * @returns {Array.<Object.<module:core/coreLogic.Record>>}
   */
  getComponentEnumValues() {
    return Object.values(this.components);
  }

  getLogicEnumValues() {
    return this.logicMgr;
  }
}
