import { Trigger } from '@isomorix/store';
import { MODEL_NAMES as M } from '@isomorix/core-config';

const locFieldNames = [ 'pathname', 'search', 'hash' ];
const sessFieldNames = [ 'userRoleId' ];

function destroy() {
  this.routesMgr = undefined;
  this.thisArg = undefined;
}

/**
 * @alias module:ui-router.RecordTrigger
 * @extends module:store.Trigger
 * @classdesc
 * # Summary
 * The Trigger that is used client-side as the
 * RoutesMgr's
 * [locationTrigger]{@link module:ui-router.RoutesMgr#locationTrigger}
 * and [sessionTrigger]{@link module:ui-router.RoutesMgr#sessionTrigger}
 * so that the UI can respond to
 * [location changes]{@link module:core/location.Record}
 * and changes to the user's
 * [userRole]{@link module:core/session.Record#userRole}.
 *
 * The [RecordMaybeTrigger]{@link module:ui-router.RecordMaybeTrigger}
 * is used server-side. The only difference is that version also
 * compares the record's
 * [__dispatchId]{@link module:core/location.Record#__dispatchId}
 * to ensure it matches the
 * [RoutesMgr's mainDispatchId property]{@link module:ui-router.RoutesMgr#mainDispatchId}.
 *
 * The triggers only notify the [RoutesMgr]{@link module:ui-router.RoutesMgr}
 * of changes, they **do not** perform any actions.
 *
 * When added to Core's [Location model]{@link module:core/location.Model},
 * it will call the RoutesMgr's
 * [matchLocation method]{@link module:ui-router.RoutesMgr#matchLocation}
 * when a change occurs to the location's
 * [pathname]{@link module:core/location.Record#pathname},
 * [search]{@link module:core/location.Record#search},
 * or [hash]{@link module:core/location.Record#hash} properties.
 *
 * When added to Core's [Session model]{@link module:core/session.Model},
 * it will call the RoutesMgr's
 * [addCheckPermsLogic method]{@link module:ui-router.RoutesMgr#addCheckPermsLogic}
 * when a change occurs to the
 * [session record's userRoleId property]{@link module:core/session.Record#userRoleId}.
 *
 * # Usage
 *
 * This Trigger is instantiated by the RoutesMgr's
 * [addTriggers method]{@link module:ui-router.RoutesMgr#addTriggers}.
 * Augment that method, or its
 * [Trigger property]{@link module:ui-router.RoutesMgr#Trigger}
 * to provide a trigger that should be used instead.
 *
 * Otherwise, there is nothing to configure. It will be
 * setup & managed automatically. And since it simply
 * notifies the `RoutesMgr`, it is highly unlikely
 * there will ever be a need to override it. Override
 * the methods it calls on the `RoutesMgr` instead.
 * @param {number} priority
 * The priority of the trigger, which affects
 * the order it is called in relation to other triggers.
 *
 * This can also be set using the
 * [setPriority method]{@link module:store.Trigger#setPriority}.
 *
 * Ultimately, it doesn't matter what priority is used,
 * since notifying the [RoutesMgr]{@link module:ui-router.RoutesMgr}
 * of a change to a tracked record isn't order-dependent.
 *
 * Defaults to [MODEL_INDEX_PRIORITY]{@link module:store/constants/trxPriorities.MODEL_INDEX_PRIORITY}.
 * @param {module:ui-router.RoutesMgr} routesMgr - The
 * [RoutesMgr instance]{@link module:ui-router.RoutesMgr}
 * that the trigger is associated with.
 * @param {string} typename - The name of the model that
 * the trigger is attached to. This would be the model's
 * [__typename property]{@link module:model.Model#__typename}.
 *
 * It must be the value of one of the following
 * Core [MODEL_NAMES constants]{@link module:core-config.MODEL_NAMES}:
 *
 * - [LOCATION]{@link module:core-config/modelNames.LOCATION} - Represents
 * Core's [Location model]{@link module:core/location.Model}
 *
 * - [SESSION]{@link module:core-config/modelNames.SESSION} - Represents
 * Core's [Session model]{@link module:core/session.Model}
 * @param {?number} [mainDispatchId] - See the RoutesMgr's
 * [mainDispatchId property]{@link module:ui-router.RoutesMgr#mainDispatchId}
 * for details. Typically, this is only provided server-side.
 */
export class RecordTrigger extends Trigger {
  constructor(priority, routesMgr, typename, mainDispatchId) {
    super(priority);
    /**
     * The RoutesMgr's
     * [mainDispatchId property]{@link module:ui-router.RoutesMgr#mainDispatchId}.
     *
     * This is used by the
     * [RecordMaybeTrigger's afterFieldUpdate method]{@link module:ui-router.RecordMaybeTrigger#afterFieldUpdate}
     * to ensure a change to a record applies to
     * the current [REQUEST action]{@link module:ui-router/router/logic/request}
     * before notifying the RoutesMgr.
     * @member mainDispatchId
     * @memberof module:ui-router.RecordTrigger#
     * @type {?number}
     */
    this.mainDispatchId = mainDispatchId || null;
    /**
     * A reference to the
     * [RoutesMgr instance]{@link module:ui-router.RoutesMgr}
     * that this trigger is associated with.
     * @member routesMgr
     * @memberof module:ui-router.RecordTrigger#
     * @type {module:ui-router.RoutesMgr}
     */
    this.routesMgr = routesMgr;
    switch(typename) {
      case M.LOCATION:
        /**
         * The names of the fields that this trigger will
         * respond to on the record.
         *
         * When attached to
         * [Core's Location model]{@link module:core/location.Model},
         * it will be the following field names:
         *
         * - [pathname]{@link module:core/location.Record#pathname}
         * - [search]{@link module:core/location.Record#search}
         * - [hash]{@link module:core/location.Record#hash}
         *
         * And when attached to
         * [Core's Session model]{@link module:core/session.Model}
         * it will be the following field name:
         *
         * - [userRoleId]{@link module:core/session.Record#userRoleId}
         * @member fieldNames
         * @memberof module:ui-router.RecordTrigger#
         * @type {Array.<string>}
         */
        this.fieldNames = locFieldNames;
        /**
         * The name of the method that this trigger's
         * [afterFieldUpdate method]{@link module:ui-router.RecordTrigger#afterFieldUpdate}
         * will call on the [RoutesMgr]{@link module:ui-router.RoutesMgr}
         * when a qualified change to a record is detected.
         *
         * It will be one of the following:
         *
         * - `matchLocation` - This will be the value of this
         * property when this trigger is attached to
         * [Core's Location model]{@link module:core/location.Model}.
         * It means it will call the
         * [RoutesMgr's matchLocation method]{@link module:ui-router.RoutesMgr#matchLocation}.
         *
         * - `addCheckPermsLogic` - This will be the value of this
         * property when this trigger is attached to
         * [Core's Session model]{@link module:core/session.Model}.
         * It means it will call the
         * [RoutesMgr's addCheckPermsLogic method]{@link module:ui-router.RoutesMgr#addCheckPermsLogic}.
         * @member methodName
         * @memberof module:ui-router.RecordTrigger#
         * @type {string}
         */
        this.methodName = 'matchLocation';
        /**
         * The RoutesMgr's [locStoreId property]{@link module:ui-router.RoutesMgr#locStoreId}
         * when this trigger is associated with Core's
         * [Location model]{@link module:core/location.Model},
         * or the RoutesMgr's [sessStoreId property]{@link module:ui-router.RoutesMgr#sessStoreId}
         * when this trigger is associated with Core's
         * [Session model]{@link module:core/session.Model}.
         *
         * This trigger will ignore changes to any records whose
         * [__storeId property]{@link module:record.Record#__storeId}
         * is not the same as the value of this property.
         * @member __storeId
         * @memberof module:ui-router.RecordTrigger#
         * @type {string|number}
         */
        this.__storeId = routesMgr.locStoreId;
        break;
      case M.SESSION:
        this.fieldNames = sessFieldNames;
        this.methodName = 'addCheckPermsLogic';
        this.__storeId = routesMgr.sessStoreId;
        break;
      default:
        throw new Error(`Unrecognized typename for Router RecordTrigger to target: ${typename}. Expected either ${M.LOCATION} or ${M.SESSION}.`);
    }
  }

  /**
   * The method called when a change occurs to a record on the
   * model this trigger is associated with.
   * @member afterFieldUpdate
   * @memberof module:ui-router.RecordTrigger#
   * @param {module:core/location.Record|module:core/session.Record} record -
   * Either a [location record]{@link module:core/location.Record}
   * or a [session record]{@link module:core/session.Record},
   * depending on which model this trigger is associated with.
   * @param {string} fieldName - The name of the field that
   * changed, it will be one of the names from the
   * [fieldNames property]{@link module:ui-router.RecordTrigger#fieldNames}.
   * @param {*} prevValue - The previous value of the field
   * @param {*} currValue - The current value of the field.
   * @param {module:model.Mutation} mutation - The mutation that
   * resulted in the change to the field.
   * @param {boolean} isLocalOnly - Whether the change is
   * scoped as local-only, meaning it will not affect persistent storage
   * (server-side) or result in notifying the server (client-side).
   */
  afterFieldUpdate(
    record,
    fieldName,
    prevValue,
    currValue,
    mutation,
    isLocalOnly
  ) {
    if (record.__storeId === this.__storeId) {
      this.routesMgr[this.methodName](mutation, record);
    }
  }
}
RecordTrigger.prototype.destroy = destroy;

/**
 * @alias module:ui-router.RecordMaybeTrigger
 * @extends module:ui-router.RecordTrigger
 * @classdesc
 * # Summary
 * See the [RecordTrigger]{@link module:ui-router.RecordTrigger},
 * this is identical, except that its
 * [afterFieldUpdate method]{@link module:ui-router.RecordMaybeTrigger#afterFieldUpdate}
 * will make sure the record's
 * [__dispatchId]{@link module:core/location.Record#__dispatchId}
 * matches the RoutesMgr's
 * [mainDispatchId property]{@link module:ui-router.RoutesMgr#mainDispatchId}
 * before calling the relevant method on the
 * [RoutesMgr]{@link module:ui-router.RoutesMgr}
 * as described in the
 * [RecordTrigger's documentation]{@link module:ui-router.RecordTrigger}.
 *
 * This version is the default on the server, whereas
 * the [RecordTrigger]{@link module:ui-router.RecordTrigger}
 * is the default on the client.
 *
 */
export class RecordMaybeTrigger extends RecordTrigger {

  /**
   * The method called when a change occurs to a record on the
   * model this trigger is associated with.
   *
   * This is the same as the [RecordTrigger's afterFieldUpdate method]{@link module:ui-router.RecordTrigger#afterFieldUpdate},
   * except that it will also check that
   * [mutation.mainDispatchId]{@link module:model.Mutation#mainDispatchId}
   * is the same as the [mainDispatchId associated with this trigger]{@link module:ui-router.RecordMaybeTrigger#mainDispatchId}
   * before calling the method (see [methodName]{@link module:ui-router.RecordMaybeTrigger#methodName})
   * on the [RoutesMgr]{@link module:ui-router.RoutesMgr}.
   * @member afterFieldUpdate
   * @memberof module:ui-router.RecordTrigger#
   * @param {module:core/location.Record|module:core/session.Record} record -
   * Either a [location record]{@link module:core/location.Record}
   * or a [session record]{@link module:core/session.Record},
   * depending on which model this trigger is associated with.
   * @param {string} fieldName - The name of the field that
   * changed, it will be one of the names from the
   * [fieldNames property]{@link module:ui-router.RecordTrigger#fieldNames}.
   * @param {*} prevValue - The previous value of the field
   * @param {*} currValue - The current value of the field.
   * @param {module:model.Mutation} mutation - The mutation that
   * resulted in the change to the field.
   * @param {boolean} isLocalOnly - Whether the change is
   * scoped as local-only, meaning it will not affect persistent storage
   * (server-side) or result in notifying the server (client-side).
   */
  afterFieldUpdate(
    record,
    fieldName,
    prevValue,
    currValue,
    mutation,
    isLocalOnly
  ) {
    if (
      record.__storeId === this.__storeId
      && mutation.mainDispatchId === this.mainDispatchId
    ) {
      this.routesMgr[this.methodName](mutation, record);
    }
  }
}
