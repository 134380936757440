import { CoreLogicMethods } from '@isomorix/core-logic';
import { CoreLogicFactory } from '@isomorix/react-router';

export const coreLogicMethods = CoreLogicMethods
  .initModule('@isomorix/core-ui/coreLogic');

export const coreLogicComponents = {};

export const coreLogicRoutes = CoreLogicFactory
  .init(coreLogicMethods, coreLogicComponents);

