import {
  routerInitBuilder as builder,
  routerPlaceholdersLogicMgr as placeholdersMgr
} from '../../routerLogic';

function startRouter(action) {
  if (typeof window !== 'undefined' && window.history) {
    /*
     * Maybe add the hash back. That isn't sent
     * to the server, so it won't be in Location.
     * But this only applies if this is the first
     * load, not if this is due to a reload command
     * in dev mode.
     */
    const hash = action.payload.isReload ? null : window.location.hash;
    const { meta } = action;
    const logic = meta.instance;
    let loc = logic.getLocation(action);
    if (hash) {
      const m = logic.getModel(loc.__typename).mutation(meta.dispatchId);
      loc = m.update(loc, { hash });
      m.execute();
    }
    loc.syncWithWindowHistory();
  }
  return action;
}

builder.useProps('startRouter').add(startRouter, true);

builder.use()
  .setName('hydrate')
  .setCompleteOp()
  .setPriority(1000)
  .setPure(true);
if (process.env.NODE_ENV !== 'production') {
  builder.setDescription(`Hydrates the app. If overriding this logic, make sure to subscribe to the mainAction (\`action.meta.mainAction.meta.subscribe()\`) and perform the hydration after the main action completes, using the main record, rather than the pending record.`);
}

builder.addTo(placeholdersMgr);
