import React, {
  createElement,
  useCallback,
  useMemo
} from 'react';
import { leadingSlash } from '@isomorix/utils';
import { FontIcon } from '@react-md/icon';
import {
  useLayoutNavigation as useRmdLayoutNav
} from '@react-md/layout';
import {
  Link as DefaultLink,
} from '@isomorix/react-router';
import {
  CORE_LOGIC_REF_VF_NAME,
  CORE_LOGIC_TYPES
} from '@isomorix/core-config';
import { Tooltip, useTooltip } from '@react-md/tooltip';
import { useRecordSubscription, useVFSubscription } from '@isomorix/react';

const ROUTE = CORE_LOGIC_TYPES.ROUTE;

function LinkWithTooltip(
  {
    id,
    dense,
    onClick,
    onBlur,
    onFocus,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    onTouchStart,
    onContextMenu,
    position,
    defaultPosition,
    focusTime,
    touchTime,
    to,
    linkOnClick,
    ...props
  }
) {
  const { elementProps, tooltipProps } = useTooltip({
    baseId: id,
    dense,
    onClick,
    onBlur,
    onFocus,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    onTouchStart,
    onContextMenu,
    position: position || 'right',
    focusTime,
    touchTime,
    defaultPosition: defaultPosition || 'right',
  });
  if (linkOnClick) {
    const elemOnClick = elementProps.onClick;
    elementProps.onClick = (e) => {
      if (elemOnClick) {
        elemOnClick(e);
      }
      return linkOnClick(e);
    }
  }
  return (
    <>
      <DefaultLink { ...props } { ...elementProps }/>
      <Tooltip { ...tooltipProps }>{to}</Tooltip>
    </>
  )
}



function Link(
  {
    to,
    linkOnClick,
    ...props
  }
) {
  if (props.className.indexOf('__mini-item') > -1) {
    props.to = to;
    props.linkOnClick = linkOnClick;
    return createElement(LinkWithTooltip, props);
  } else {
    return createElement(DefaultLink, props);
  }
}

const getDocsToObj = (route, href, sessionId) => {
  const allLogic = route[CORE_LOGIC_REF_VF_NAME];
  if (!allLogic) {
    return { pathname: href };
  }
  let logic, bestLogic, sessId;
  for(let key in allLogic) {
    logic = allLogic[key];
    if (logic.type !== ROUTE) continue;
    if ((sessId = logic.sessionId) === sessionId) {
      bestLogic = logic;
      break;
    }
    if (!sessId) {
      bestLogic = logic;
    }
  }
  const docsStore = bestLogic && bestLogic.localProps?.docsStore;
  if (!docsStore) return { pathname: href };
  const exit = !bestLogic.match && docsStore.get('exitMatch');
  if (exit) {
    const loc = bestLogic.getLocation();
    return {
      pathname: exit.pathname,
      searchParams: loc.searchParams,
      hash: exit.hash,
      title: docsStore.value.getTitle(loc.createPath(
        exit.pathname,
        undefined,
        exit.hash
      ))
    };
  } else {
    return {
      pathname: href,
      title: docsStore.value.getTitle(href)
    }
  }
}

const createDocsLink = (route, sessionId = null) => function DocsLink(
  {
    onClick: propsOnClick,
    ...props
  }
) {
  const { href } = props;
  props.linkOnClick = useCallback((e) => {
    if (propsOnClick) {
      propsOnClick(e);
    }
    return getDocsToObj(route, href, sessionId);
  }, [ href ]);
  return createElement(Link, props);
}

const createItem = (route, session, parentId = null) => {
  const meta = route.meta;
  if (
    !meta
    || !meta.navName
    || (meta.navHideOnPermDenied && !route.permUse(session.userRole))
  ) {
    return null;
  }
  let absPath = route.absPath;
  // handle route params, we only want
  // the base path
  if (absPath.indexOf(':') > -1) {
    const parts = absPath.split('/');
    absPath = '';
    for(let p of parts) {
      if (!p) continue;
      if (p.charAt(0) === ':') {
        break;
      }
      absPath += `/${p}`;
    }
  }
  const { iconName } = meta;
  const href = leadingSlash(absPath);
  return {
    itemId: href,
    parentId,
    href: href,
    children: meta.navName,
    leftAddon: iconName
      ? <FontIcon>{iconName}</FontIcon>
      : null,
    /*
     * This prop gets passed through, so it's
     * used to provide the value to display
     * in a tooltip.
     */
    to: meta.navName,
    contentComponent: meta.isDocsRoute
      ? createDocsLink(route, session.__ID)
      : meta.isParentOnly ? 'span' : Link
  }
}


const descend = (navItems, session, parentItem, children) => {
  if (!children) return;
  const parentId = parentItem
    ? parentItem.itemId
    : null;
  let item;
  for(let pkValue in children) {
    if ((item = createItem(children[pkValue], session, parentId))) {
      navItems[item.href] = item;
      descend(navItems, session, item, children[pkValue].children);
    }
  }
}

export function createNavigationItems(router, session) {
  const navItems = {};
  const item = createItem(router, session, null);
  if (item) {
    navItems[item.href] = item;
  }
  descend(navItems, session, null, router.children);
  return navItems;
}


export function useLayoutNavigation(logic, session) {
  const router = logic.route;
  const { location: { pathname } } = session;
  const changeKey = useRecordSubscription(router);
  const VFChangeKey = useVFSubscription(router, 'routes');
  const navItems = useMemo(
    () => createNavigationItems(router, session),
    [ changeKey, VFChangeKey, session.userRoleId ]
  );
  const treeProps = useRmdLayoutNav(navItems, pathname, DefaultLink);
  let selectedId = treeProps.selectedIds[0];
  if (!selectedId || navItems[selectedId]) {
    return treeProps;
  }
  const parts = selectedId.split('/');
  let i = 1;
  selectedId = `/${parts[i]}`;
  const length = parts.length;
  while(i < length) {
    if (!navItems[`${selectedId}/${parts[i]}`]) {
      treeProps.selectedIds[0] = selectedId;
      return treeProps;
    }
    selectedId += `/${parts[i]}`;
    i++;
  }
  return treeProps;
}
