import React from 'react';
import { loadable, useRouterContext } from '@isomorix/react-router';
import { LinearProgress } from '@react-md/progress';
import { useRecordSubscription } from '@isomorix/react';
import { PermissionDenied } from '../PermissionDenied';
import { CORE_LOGIC } from '../constants';

const Voyager = loadable(() => /* webpackChunkName: "graphqlVoyager" */ import('./Voyager'), {
  resolveComponent: mod => mod.Voyager,
  ssr: false,
  fallback: <LinearProgress />
});

export const GraphqlVoyager = React.memo(function GraphqlVoyager(
  {
    logic,
  }
) {
  useRecordSubscription(logic);
  const { session, location } = useRouterContext();
  const { match } = logic;
  if (!match) {
    return null;
  }
  if (logic.permDenied) {
    return <PermissionDenied logic={logic} />
  }
  return <Voyager session={session} location={location} logic={logic} />;
});

GraphqlVoyager.displayName = CORE_LOGIC.GRAPHQL_VOYAGER_ROUTE;
