
let docsData;
try {
  docsData = require('../../jsdocData/features/mutation.json');
} catch (e) {
  docsData = { links: {} };
}
export const mutation = {
  name: 'mutation',
  headline: [
    // 'Get Guaranteed Data Integrity With',
    'Easily & Predictably Manage Even The Most Complex Data Structures With',
    { children: 'ACID Compliant Mutations', color: 'theme-secondary' },
    'In',
    { children: 'Every', fontStyle: 'italic' },
    'Environment*'
  ],
  subHeadline: {
    fontStyle: 'italic',
    children: [
      `*Yes that includes the frontend, and it's`,
      { children: 'blazing fast', color: 'theme-secondary' },
      'too'
    ]
  },
  video: {
    url: 'https://dev-39.wistia.com/medias/74gkwkya16',
    preview: '/static/img/vid/breakout_mutation_preview.webp',
    totalSeconds: 35,
  },
  panels: [{
    header: {
      avatar: 'A',
      avatarColor: 'indigo',
      text: 'Atomicity'
    },
    text: [
      [
        'Easily queue up changes using the dead-simple',
        {
          children: 'Mutation interface',
          to: docsData.links.mutationModule,
          after: ',',
          isDocsLink: true
        },
        {
          children: 'including across multiple different data models',
          weight: 'bold',
        },
        {
          children: 'simultaneously',
          fontStyle: 'italic'
        }
      ],
      [
        'Then simply "fire and forget".'
      ],
      [
        'If',
        { children: 'any', fontStyle: 'italic' },
        'change fails,',
        { children: 'all', fontStyle: 'italic' },
        'changes will be',
        {
          children: 'seamlessly rolled back automatically',
          after: ',',
          to: docsData.links.mutationError,
          isDocsLink: true
        },
        'regardless of complexity, and all without writing a single line of code.'
      ],
      [
        'This also means in-flight mutations can be',
        {
          children: 'safely cancelled',
          to: docsData.links.mutationCancel,
          isDocsLink: true,
          after: ',',
        },
        'or you can',
        {
          children: 'easily undo',
          to: docsData.links.controllerUndo,
          isDocsLink: true,
        },
        'or even',
        {
          children: 'redo past mutations',
          to: docsData.links.controllerRedo,
          isDocsLink: true,
        },
        'at any time with just a single call.'
      ]
    ]
  }, {
    header: {
      avatar: 'C',
      avatarColor: 'indigo',
      text: 'Consistency'
    },
    text: [
      [
        'All state changes are',
        {
          children: 'validated automatically',
          to: docsData.links.setRecordState,
          isDocsLink: true,
        },
        'for 100% type-safety, so it\'s',
        { children: 'literally impossible', fontStyle: 'italic'},
        'to mistakenly set an invalid value in state.'
      ],
      'But that\'s only the beginning...',
      [
        'For the first time ever, enjoy the confidence and security that comes with having',
        {
          children: 'sql-grade constraints in every environment',
          after: '.',
          to: docsData.links.constraintRecordModule,
          isDocsLink: true,
        },
        'These will transparently handle difficult and complex things like relational integrity, cascading changes, and uniqueness (even across multiple fields).'
      ],
      'You\'ll never even have to think about it, either.',
      'They\'re intelligently generated by analyzing dozens of data points, but custom ones can be added too.',
      [
        'Best of all, they don\'t just apply to in-memory state. Pick the persistent storage of your choice, and they\'ll be converted, optimized for the storage type, then set up for you so',
        {
          children: 'you get true 360 degree, type safe, end-to-end consistency - with zero fragile logic',
          after: '.',
          weight: 'bold',
        }
      ]
    ]
  }, {
    header: {
      avatar: 'I',
      avatarColor: 'indigo',
      text: 'Isolation'
    },
    text: [
      [
        'All state (and therefore mutations) are isolated on a per-action basis. And parallel mutations that affect the same records will be',
        {
          children: 'intelligently managed for you',
          to: docsData.links.mergeMainStateInFlight,
          isDocsLink: true,
          after: ','
        },
        'dramatically reducing code complexity and outright eliminating the errors that typically arise when using manual processes.'
      ],
      [
        'This means you\'ll be shipping new features faster and more confidently than ever before because you can execute',
        {
          children: 'any mutation',
          // to: docsData.links.mutation,
        },
        {
          children: 'whenever',
          fontStyle: 'italic',
        },
        'and',
        {
          children: "wherever",
          fontStyle: 'italic'
        },
        'needed - all the complex, heavy lifting is done for you to ensure state remains fully isolated and ultimately reconciled in the proper order.'
      ],
      // [
      //   'All state (and therefore mutations) are isolated on a per-action basis. You also get an extremely robust solution for',
      //   {
      //     children: 'intelligently handling parallel mutations',
      //     to: docsData.links.mergeMainStateInFlight,
      //     isDocsLink: true,
      //   },
      //   'that affect the same record(s), dramatically reducing the complexity of your code. Simply make the desired changes, and the rest is handled automatically.',
      // ],
      // 'All state (and therefore mutations) are isolated on a per-action basis, ensuring parallel mutations can occur without one affecting the other.',
      [
        'What\'s more, the state for any plugin, model, or record can be easily',
        {
          children: 'serialized on a global transaction level',
          to: docsData.links.SERIALIZABLE,
          isDocsLink: true,
        },
        'based on the needs of your app. It\'s the same robust serialization that up until now was only available with storage solutions like sql. Now for the first time ever, you can have the same level of protection on both the client and server with',
        {
          children: 'just a single call',
          to: docsData.links.setIsolationLevel,
          isDocsLink: true,
          // after: '.'
        },
        '- everything will be set up and configured for you automatically.'
      ],
      // [
      //   'What\'s more, easily add',
      //   {
      //     children: 'sql-grade serialization logic',
      //     weight: 'bold'
      //   },
      //   'to',
      //   { children: 'any', fontStyle: 'italic' },
      //   'plugins, models, or even records to isolate state on a global transaction level based on the needs of your App - all with just a single line of code.'
      // ]
    ]
  }, {
    header: {
      avatar: 'D',
      avatarColor: 'indigo',
      text: 'Durability'
    },
    text: [
      'Forget fragile, hard to maintain business logic...',
      [
        {
          children: 'Persistent history backups',
          to: docsData.links.trackHistory,
          isDocsLink: true,
        },
        'and secondary storage using solutions like Redis are',
        {
          children: 'ready to go',
          to: docsData.links.redis,
          isDocsLink: true,
        },
        '(and even configured for you automatically if you want) so you get maximum backend data durability & efficiency.'
      ], [
        'And on the frontend you get',
        {
          children: 'automatic offline detection',
          weight: 'bold'
        },
        'that will transparently queue mutations that occur when the user is offline, then automatically replay them once the connection is restored.'
      ], [
        'Best of all, there\'s no affect on the user experience',
        { children: 'whatsoever', fontStyle: 'italic' },
        'thanks to',
        {
          children: 'automatic optimistic state',
          to: docsData.links.optimisticCompleteLogic,
          isDocsLink: true,
          after: ',',
        },
        // 'No configuration is needed either if you don\'t want to - it just works out of the box. This means you and your users get unprecedented peace of mind that',
        // {
        //   children: 'no data will ever be lost',
        //   after: ',',
        //   // weight: 'bold'
        // },
        'and no configuration is needed either if you don\'t want to - everything just works out of the box. That way you get peace of mind knowing your data is safe, even over the most challenging mobile connection, without any additional effort.',
        // {
        //   children: 'no data will ever be lost',
        //   after: ',',
        //   // weight: 'bold'
        // },
        // 'even on the most challenging mobile connection.'
      ]
    ]
  }]
}
