import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from './FormContext';

export function useField(
  {
    name,
    Container,
    containerProps,
    ErrorComponent,
    ...props
  },
  createGetValue
) {
  const context = useFormContext();
  const ref = useRef();
  const { onBlur, onChange } = useMemo(() => {
    return {
      onBlur: () => context.onBlur(name),
      onChange: () => context.onChange(name)
    }
  }, [ context, name ]);
  props.ref = ref;
  props.onBlur = onBlur;
  props.onChange = onChange;
  if (!Container) {
    Container = context.FieldContainer;
    containerProps = { ...context.fieldContainerProps }
  } else if (!containerProps) {
    containerProps = {};
  }
  if (!ErrorComponent) {
    ErrorComponent = context.ErrorComponent;
  }
  const { getters } = context;
  useEffect(() => {
    getters[name] = createGetValue(ref);
  }, [ name, getters, createGetValue ]);
  return {
    name,
    Container,
    containerProps,
    ErrorComponent,
    context,
    fieldProps: props,
  };
}
