import React, {
  useCallback,
  useMemo,
  useState
} from 'react';
import { FormContext } from './FormContext';
import { GridCell } from '@react-md/utils';
import { camelCaseToWords } from '@isomorix/utils';

const updateValue = (state, name, result) => {
  const { errors, values } = state;
  if (result.error) {
    if (!errors[name]) {
      errors[name] = result.error;
      state.errorCount++;
      return true;
    } else if (errors[name] !== result.error) {
      errors[name] = result.error;
      return true;
    } else {
      return false;
    }
  } else if (errors[name]) {
    errors[name] = null;
    state.errorCount--;
    values[name] = result.value;
    return true;
  } else if (result.value !== values[name]) {
    values[name] = result.value;
    return true;
  } else {
    return false;
  }
}

export function FormController(props) {
  const {
    onSubmit: onSubmitCb,
    FieldContainer = GridCell,
    fieldContainerProps = { colSpan: 12 },
    ErrorComponent = 'p',
    fields
  } = props;
  const [ state, setState ] = useState(() => {
    const values = {};
    const errors = {};
    const blurred = {};
    const getters = {};
    const getValues = () => {
      for(let key in getters) {
        values[key] = getters[key]();
      }
      return values;
    }
    for(let key in fields) {
      values[key] = undefined;
      errors[key] = null;
      blurred[key] = false;
    }
    return {
      values,
      errors,
      errorCount: 0,
      blurred,
      getters,
      submitting: false,
      getValues,
    }
  });
  const validate = useCallback((name) => {
    const { type, defaultValue, notNullable } = fields[name];
    let value = state.getters[name]();
    let error = null;
    if (
      notNullable
      && defaultValue === null
      && (value === null || value === '' || typeof value === 'undefined')
    ) {
      error = `The ${camelCaseToWords(name, true)} field is required.`;
    } else if (value !== '') {
      try {
        value = type.parseValue(value);
      } catch(e) {
        error = e.message;
      }
    } else {
      value = defaultValue;
    }
    return { error, value };
  }, [ state, fields ]);

  const onChange = useCallback((name) => {
    if (state.submitting || state.blurred[name]) {
      const newState = { ...state };
      updateValue(newState, name, validate(name));
      setState(newState);
    }
  }, [ state, fields ]);
  const onBlur = useCallback((name) => {
    if (state.blurred[name]) {
      onChange(name);
    } else if (state.getters[name]()) {
      state.blurred[name] = true;
    }
  }, [ state, onChange ]);
  const onSubmit = useCallback(() => {
    if (state.submitting) return;
    const { blurred } = state;
    const newState = { ...state };
    for(let name in fields) {
      blurred[name] = true;
      updateValue(newState, name, validate(name));
    }
    if (newState.errorCount) {
      setState(newState);
    } else {
      onSubmitCb(newState, setState);
    }
  }, [ state, setState ]);
  const contextValue = useMemo(() => ({
    ...state,
    FieldContainer,
    fieldContainerProps,
    ErrorComponent,
    onChange,
    onBlur,
    onSubmit,
    validate,
    fields,
  }), [
    fields,
    FieldContainer,
    fieldContainerProps,
    ErrorComponent,
    state,
    onBlur,
    onSubmit,
    validate
  ]);
  return (
    <FormContext.Provider value={contextValue}>
      { props.children }
    </FormContext.Provider>
  )
}

