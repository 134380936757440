import React from 'react';
import { useRecordSubscription } from '@isomorix/react';
import { useRouterContext } from '@isomorix/react-router';
import { CORE_LOGIC } from '../constants';
import { LinearProgress } from '@react-md/progress';
import { loadable, getThemeType } from '@isomorix/react-router';
import { PermissionDenied } from '../PermissionDenied';

const Playground = loadable(() => /* webpackChunkName: "graphqlPlayground" */ import('./Playground'), {
  resolveComponent: mod => mod.Playground,
  ssr: false,
  fallback: <LinearProgress />
})

export const GraphqlPlayground = React.memo(function ApiPlayground(props) {
  const { logic, isPortal } = props;
  useRecordSubscription(logic);
  const { session, location } = useRouterContext();
  const { match } = logic;
  if (!match && !isPortal) {
    return null;
  }
  if (logic.permDenied) {
    return <PermissionDenied logic={logic} />
  }
  /*
   * This makes it possible to have a separate "workspace"
   * per role. That means that when switching roles,
   * it'll switch the tabs with the queries/mutations
   * from session storage to those for the role.
   * Basically, an independent playground will
   * exist for each role.
   */
  // const { userRole } = session;
  // const workspaceName = userRole ? userRole.role.slug : PERMISSIONS.ANY;
  return (
    <Playground
      disableAnimation={true}
      fixedAppBar={true}
      location={location}
      session={session}
      themeType={getThemeType(session)}
      // workspaceName={workspaceName}
    />
  );
});
GraphqlPlayground.displayName = CORE_LOGIC.GRAPHQL_PLAYGROUND_ROUTE;
