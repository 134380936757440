import React, { useEffect, useState } from 'react';
import { LaunchTimerRender } from './LaunchTimerRender';
// import { loadable } from '@isomorix/store';

// const timerLoader = loadable(() => /* webpackChunkName: "countdownTimer" */ import('./LaunchTimerRender'));
const countStyle = {
  color: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0)'
};
const labelStyle = { fontSize: '12px' };
let RenderComponent;

export const LaunchTimer = React.memo(function LaunchTimer(
  {
    isLighthouse
  }
) {
  const [ TimerRender, setTimerRender ] = useState();
  useEffect(() => {
    if (isLighthouse) return;
    if (RenderComponent) {
      setTimerRender(RenderComponent);
      return;
    }
    let timeout = window.setTimeout(() => {
      RenderComponent = LaunchTimerRender;
      setTimerRender(RenderComponent);
    }, 10);
    return () => {
      window.clearTimeout(timeout);
    }
    // let sub;
    // let timeout = window.setTimeout(() => {
    //   sub = timerLoader().subscribe(mod => {
    //     RenderComponent = mod.LaunchTimerRender;
    //     timeout = undefined;
    //     sub = undefined;
    //     setTimerRender(RenderComponent);
    //   })
    // }, 250);
    // return () => {
    //   if (timeout) {
    //     window.clearTimeout(timeout);
    //     if (sub) {
    //       sub.unsubscribe();
    //     }
    //   }
    // }
  }, [ setTimerRender, isLighthouse ]);
  if (TimerRender) {
    return <TimerRender />
  }
  return (
    <div className={'launch-timer-container'}>
      <div className="root">
        <div className="root-react-component-countdown-timer launch-timer" id="">
          <div className="displayedTime">
            <div style={{ fontSize: '16px' }} className="countBox right border responsive">
              <div className="countBoxItem">
                <div style={labelStyle} className="label">Sec</div>
                <div className="count" style={countStyle}>-</div>
              </div>
              <span className="split">:</span>
              <div className="countBoxItem">
                <div style={labelStyle} className="label">Min</div>
                <div className="count" style={countStyle}>-</div>
              </div>
              <span className="split">:</span>
              <div className="countBoxItem">
                <div style={labelStyle} className="label">Hour</div>
                <div className="count" style={countStyle}>-</div>
              </div>
              <span className="split">:</span>
              <div className="countBoxItem">
                <div style={labelStyle} className="label">Day</div>
                <div className="count" style={countStyle}>-</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})
