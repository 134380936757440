import { LogicMgr } from '@isomorix/store';
import {
  routerLogicMgr as uiRouterLogicMgr
} from '@isomorix/ui-router';


export const routerLogicMgr = LogicMgr
  .initModule(`@isomorix/react-ssr/routerLogic`)
  .setEnv('client')
  .addSource(uiRouterLogicMgr)
  .useSources();
