/**
 * @module react/hooks/utils/stateChange
 * @ignore
 */

/**
 * @type {Array.<string>}
 */
const defaultKeys = [ '__changesDispatchId', '__changesEvent' ];

/**
 *
 * @param {Object} obj
 * @param {Array.<string>} [keys = defaultKeys]
 * @returns {string|null}
 * @ignore
 */
export function getStateChangeKey(obj, keys = defaultKeys, prefix) {
  if (!obj) {
    return null;
  }
  let state = prefix
    ? prefix
    : obj.__dispatchId ? 'pending' : 'main';
  for(let key of keys) {
    state += `_${obj[key]}`;
  }
  return state;
};
