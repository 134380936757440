import { PERMISSIONS as _PERMISSIONS } from '@isomorix/core-config';
import { PKG } from './pkg';

function id(id) {
  return `${PKG.SLUG}_${id}`;
}


const READ_APP_BUILDER = id('read_app_builder');

const READ_DOCS = id('read_docs_static');

const READ_GRAPHQL_FORCE_GRAPH = id('read_graphql_force_graph');
const READ_GRAPHQL_PLAYGROUND = id('read_graphql_playground');
const READ_GRAPHQL_VOYAGER = id('read_graphql_voyager');

/**
 *
 * @type {Object}
 * @ignore
 */
export const PERMISSIONS = {
  READ_GRAPHQL_FORCE_GRAPH,
  READ_GRAPHQL_PLAYGROUND,
  READ_GRAPHQL_VOYAGER,
  READ_DOCS,
  READ_APP_BUILDER,
};

export const CORE_UI_PERMISSIONS = {
  ..._PERMISSIONS,
  ...PERMISSIONS,
}
