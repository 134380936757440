import React, { useEffect } from 'react';
import { useRecordSubscription } from '@isomorix/react';
import { CORE_LOGIC, PATHS } from '../constants';
import { LinearProgress } from '@react-md/progress';
import { Helmet, useCss } from '@isomorix/react-router';
import { CORE_LOGIC_TYPES } from '@isomorix/core-config';
import { PermissionDenied } from '../PermissionDenied';

let css;
try {
  css = require('./_styles.scss');
} catch (e) {}

export const AppBuilderRoute = React.memo(function AppBuilderRoute(props) {
  const { logic } = props;
  const changeKey = useRecordSubscription(logic);
  const { localProps } = logic;
  const { appMgr } = localProps;
  useCss(appMgr ? css : undefined);
  useEffect(() => {
    if (!appMgr) return;
    const routerLogic = logic.getParentByType(CORE_LOGIC_TYPES.ROUTER);
    const { localProps } = routerLogic;
    if (localProps.layout3DStore) {
      localProps.layout3DStore.storeConfig.addAppBuilderGraphs(appMgr);
    }
  }, [ appMgr, logic ]);
  if (logic.permDenied) {
    return logic.match
      ? <PermissionDenied logic={logic} />
      : null;
  } else if (appMgr) {
    const { Route } = localProps.appModule;
    return (
      <>
        <Helmet>
          <link
            rel={"stylesheet"}
            href={`${PATHS.APP_BUILDER_CSS}`}
            type={"text/css"}
          />
        </Helmet>
        <Route
          changeKey={changeKey}
          appMgr={localProps.appMgr}
          logic={logic}
        />
      </>
    )
  } else if (logic.match) {
    return <LinearProgress style={{ marginTop: '1rem' }} />
  } else {
    return null;
  }
});

AppBuilderRoute.displayName = CORE_LOGIC.APP_BUILDER_ROUTE;
