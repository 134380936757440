export const defaultSubmitStyle = {
  float: 'right',
  marginTop: '1.5rem'
};

export const dialogCloseIcon = {
  marginLeft: '2rem'
}

export const dialogStyle = {
  width: '40rem'
}
