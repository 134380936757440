import { initBuilder, CoreBuilder } from '../entries';
import { Entry } from '@isomorix/core';
import { defaultCorePluginRecord } from '@isomorix/core-helpers';
import { asyncScheduler } from 'rxjs';
import { pluginLogicMgr } from './logic';
import { ofChunksReady } from '@isomorix/react-router';

const init = (entry) => {
  let builder, sc;
  asyncScheduler.schedule(() => {
    sc = CoreBuilder.createStoreConfig(false);
  }, 0);
  asyncScheduler.schedule(() => {
    defaultCorePluginRecord(sc);
  }, 0);
  asyncScheduler.schedule(() => {
    builder = initBuilder(sc, pluginLogicMgr);
  }, 0);
  asyncScheduler.schedule(() => {
    const store = builder.createStore();
    if (process.env.NODE_ENV === 'production') {
      const main = store.mainStore;
      window.getState = function() {
        return main;
      }
    }
  }, 0);
  asyncScheduler.schedule(() => {
    entry.registerAction(builder.dispatchInit()).subscribe();
  }, 0);
}

const entry = new Entry(init, false);
if (module.hot) {
  module.hot.accept([
    './logic',
    '@isomorix/core',
    '@isomorix/core-helpers',
    '@isomorix/react-router',
    '../entries'
  ], entry.init);
  if (typeof window !== 'undefined') {
    window.reloadApp = entry.init;
    window.getModel = entry.getModel;
  }
}
ofChunksReady().subscribe(entry.init);
