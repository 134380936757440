import {
  routerInitBuilder as builder,
  routerPlaceholdersLogicMgr
} from './logicMgr';
import { DEFAULT_LOGIC_PRIORITY } from '@isomorix/store';
import { addSharedInitLogic } from '../sharedLogic';

addSharedInitLogic(builder);

builder.use()
  .setName('startRouter')
  .setTransformOp()
  .setPure(true)
  .setPriority(DEFAULT_LOGIC_PRIORITY);

if (process.env.NODE_ENV !== 'production') {
  builder.setDescription(`Starts the router.

On the client, it will instantiate the [RoutesMgr]{@link module:ui-router.RoutesMgr} that will be used throughout the lifespan of the client. It will also call the [location's syncWithWindowHistory method]{@link module:core/location.Record#syncWithWindowHistory} so that the location remains in sync with the browser.

On the server, this will include adding the appropriate middlewares to the [endpoint]{@link module:core/endpoint.Record} to facilitate server-rendering, session management (including permissions), serving static assets (gzip supported too), and compression middleware to minimize the size of the transfers.`);
}

builder.addTo(routerPlaceholdersLogicMgr);
