import React, { useEffect, useState } from 'react';

export function useFlames(ref) {
  // const ref = useRef();
  const [ flames, setFlames ] = useState();
  useEffect(() => {
    if (!ref.current) return;
    const obs = new IntersectionObserver(([ { isIntersecting }]) => {
      if (!isIntersecting) {
        setFlames(null);
        return;
      }
      const flames = [];
      let i = 0;
      while(i < 50) {
        flames.push(<div key={i} className={'fire-particle'}/>);
        i++;
      }
      setFlames(
        <div className={'fire-container'}>
          { flames }
        </div>
      );
    }, {
      threshold: 0
    });
    obs.observe(ref.current);
    return () => {
      obs.disconnect();
    }
  }, [ setFlames, ref.current ]);
  return flames;
}
