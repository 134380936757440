import {
  RecordTrigger,
  RecordMaybeTrigger
} from './triggers';
import {
  MODEL_NAMES as M,
} from '@isomorix/core-config';
import { checkPerms } from '@isomorix/core-logic';
import { isPendingInstance } from '@isomorix/object-types';
import { matchLocation } from './helpers';

/**
 * @alias module:ui-router.RoutesMgr
 * @classdesc
 * # Summary
 * Provides a simple interface to manage the routes
 * associated with a plugin/app.
 *
 * It works with the
 * [coreLogic records]{@link module:core/coreLogic.Record}
 * that are associated with the
 * [routes for the plugin]{@link module:core/route.Record},
 * and it includes full support for server-side rendering,
 * permissions, and more.
 *
 * # Usage
 * This is integrated automatically, it is configured
 * during the
 * [routerLogic's INIT action]{@link module:ui-router/router/logic/init},
 * specifically by the [initLocalProps logic]{@link module:ui-router/router/logic/init.initLocalProps}.
 * It will set a reference to this class
 * on the [routerLogic's]{@link module:ui-router.RoutesMgr#routerLogic}
 * `localProps.RoutesMgr` if that property has not already been set.
 *
 *This class only needs to be instantiated once on the client. The
 * [startRouter logic]{@link module:ui-router/router/logic/init.startRouter}
 * will instantiate this class and set it as `localProps.routesMgr`,
 * if that property is not already set.
 *
 * But on the server, an instance of this class
 * is initialized for each
 * [REQUEST action]{@link module:ui-router/router/logic/request},
 * specifically by the
 * [matchLocation REQUEST logic]{@link module:ui-router/router/logic/request.matchLocation},
 * since each request will be associated with different resources.
 *
 * # Custom Usage
 * Extend this class to include the additional methods/overrides
 * as needed. Then set an instance of the custom class on the
 * [routerLogic's]{@link module:ui-router.RoutesMgr#routerLogic}
 * `localProps.routesMgr` (client-side),
 * or a reference to the custom class on `localProps.RoutesMgr`
 * (server-side) and its [static init method]{@link module:ui-router.RoutesMgr.init}
 * will be called during each `REQUEST` action.
 *
 * Additionally, see the
 * [locationTrigger property]{@link module:ui-router.RoutesMgr#locationTrigger}
 * and
 * [sessionTrigger property]{@link module:ui-router.RoutesMgr#sessionTrigger}
 * for details on overriding those.
 *
 * @hideconstructor
 */
export class RoutesMgr {
  constructor(routerLogic, session, mainDispatchId) {
    /**
     * The [coreLogic record]{@link module:core/coreLogic.Record}
     * that is providing routing to
     * the app.
     * @member routerLogic
     * @memberof module:ui-router.RoutesMgr#
     * @type {module:core/coreLogic.Record}
     */
    this.routerLogic = routerLogic;
    /**
     * The storeId of the [session record]{@link module:core/session.Record}.
     * This is set when the Class is initialized by
     * referencing the provided `session`'s
     * [__storeId property]{@link module:core/session.Record#__storeId}.
     * @member sessStoreId
     * @memberof module:ui-router.RoutesMgr#
     * @type {string|number}
     */
    this.sessStoreId = session.__storeId;
    /**
     * The storeId of the
     * [location record]{@link module:core/location.Record}
     * that is derived from the session record's
     * [location virtualField]{@link module:core/session.Record#location}.
     *
     * This is set when the Class is initialized.
     * @member locStoreId
     * @memberof module:ui-router.RoutesMgr#
     * @type {string|number}
     */
    this.locStoreId = session.location.__storeId;
    /**
     * The dispatchId for the main action that is being
     * used to server-render the app.
     *
     * This will be `null` on the client, but on
     * the server it makes it possible to ignore changes
     * to the monitored records if the change does
     * not occur under this main dispatchId.
     * @member mainDispatchId
     * @memberof module:ui-router.RoutesMgr#
     * @type {?number}
     */
    this.mainDispatchId = mainDispatchId || null;
    /**
     * The function to call in order to add the
     * [checkPermsLogic]{@link module:core/coreLogic/checkPerms/logic.checkPermsLogic}
     * to the [router associated with this instance]{@link module:ui-router.RoutesMgr#routerLogic}.
     *
     * This is a cache of the
     * [routerLogic's]{@link module:ui-router.RoutesMgr#routerLogic}
     * `localProps.addCheckPermsLogic` function, meaning
     * that should be set before instantiating this
     * [RoutesMgr class]{@link module:ui-router.RoutesMgr}.
     *
     * The logic that this method adds will handle changes to the
     * [user's role]{@link module:core/session.Record#userRole},
     * and will update each logic record's
     * [permDenied field]{@link module:core/coreLogic.Record#permDenied}
     * as needed.
     *
     * See the coreLogic's
     * [checkPerms module]{@link module:core/coreLogic/checkPerms}
     * for full details.
     *
     * Note that this method accepts the
     * [checkPerms payload Object]{@link module:core/coreLogic/checkPerms.payload},
     * but the logic adds in any missing properties
     * using the action. So it's only necessary to
     * provide the properties that should be used
     * over the value of the property derived from the action.
     *
     * @member __addCheckPermsLogic
     * @memberof module:ui-router.RoutesMgr#
     * @type {(function(module:model.Mutation, module:core/coreLogic/checkPerms.payload))}
     */
    this.__addCheckPermsLogic = routerLogic
      .localProps
      .addCheckPermsLogic;
    /**
     * An Array containing the [route IDs]{@link module:core/route.Record#id}
     * of routes that are already known not to exist.
     *
     * This is tracked to prevent repeatedly trying to
     * load the logic.
     *
     * For example, a user may not have permission to access
     * the logic. A request would be made to load the logic, but
     * if it is not returned from the backend, it would be
     * due to permission denied. Therefore, it would be
     * in this Array to prevent a duplicate request to load the
     * logic each time the
     * [location changes]{@link module:core/location.Record}.
     * Note that in this example, an attempt would still be made again
     * if a user's permissions change (i.e.,
     * [changing roles]{@link module:core/session.Record#userRole}),
     * but it wouldn't occur simply due to a location change.
     *
     * The entries in this Array are managed by the
     * [matchLocation method]{@link module:ui-router.matchLocation}
     * and by the router's
     * [manageMissingRouteLogic logic]{@link module:ui-router/router/logic/mutation.manageMissingRouteLogic}
     * @member allMissing
     * @memberof module:ui-router.RoutesMgr#
     * @type {Array.<string>}
     */
    this.allMissing = [];
    /**
     * The Trigger class that is used when instantiating the
     * [locationTrigger]{@link module:ui-router.RoutesMgr#locationTrigger}
     * and the
     * [sessionTrigger]{@link module:ui-router.RoutesMgr#sessionTrigger},
     * if those properties have not been set when the
     * [addTriggers method]{@link module:ui-router.RoutesMgr#addTriggers}
     * is called.
     *
     * This property is set automatically when this class
     * is instantiated, but if a custom class should be
     * used instead, set the custom class on this property.
     *
     * Otherwise, this will be a reference to the
     * [RecordTrigger]{@link module:ui-router.RecordTrigger}
     * on the client, and the
     * [RecordMaybeTrigger]{@link module:ui-router.RecordMaybeTrigger}
     * on the server (since the triggers should only
     * respond to the changes that occur under the
     * [mainDispatchId associated with this instance]{@link module:ui-router.RoutesMgr#mainDispatchId}).
     * @member Trigger
     * @memberof module:ui-router.RoutesMgr#
     * @type {typeof module:ui-router.RecordTrigger|typeof module:ui-router.RecordMaybeTrigger}
     */
    this.Trigger = mainDispatchId
      ? RecordMaybeTrigger
      : RecordTrigger;
    if (!mainDispatchId) {
      this._maybeConvertToMain('routerLogic');
    }
  }

  /**
   * Initializes this class. This is the only method that
   * is used to do so.
   *
   * On the client, this only occurs once.
   *
   * But on the server, a RoutesMgr instance will be
   * created for each
   * [REQUEST action]{@link module:core-actions.request},
   * specifically by the routerLogic's
   * [matchLocation REQUEST logic]{@link module:ui-router/router/logic/request.matchLocation}.
   * @function init
   * @memberof module:ui-router.RoutesMgr.
   * @param {module:core/coreLogic.Record} routerLogic -
   * The [coreLogic record]{@link module:core/coreLogic.Record}
   * that is providing routing to
   * the app. It can be the main instance
   * or pending instance.
   * @param {module:core/session.Record} location - The
   * location record.
   * @param {?number} [mainDispatchId] - Provided
   * server-side, since the RoutesMgr is specific
   * to each render.
   * @returns {module:ui-router.RoutesMgr}
   * The [RoutesMgr instance]{@link module:ui-router.RoutesMgr}.
   */
  static init(routerLogic, location, mainDispatchId) {
    return new this(routerLogic, location, mainDispatchId);
  }

  _maybeConvertToMain(key) {
    if (isPendingInstance(this[key])) {
      const main = this[key].getMainInstance();
      this[key].subscribe({
        complete: () => {
          this[key] = main;
        }
      })
    }
  }

  /**
   * Adds the [locationTrigger]{@link module:ui-router.RoutesMgr#locationTrigger}
   * to Core's [Location model]{@link module:core/location.Model}
   * and the
   * [sessionTrigger]{@link module:ui-router.RoutesMgr#sessionTrigger}
   * to Core's [Session model]{@link module:core/session.Record}.
   *
   * If the [locationTrigger]{@link module:ui-router.RoutesMgr#locationTrigger}
   * property has not been set, it will instantiate the
   * Trigger using the [Trigger class]{@link module:ui-router.RoutesMgr#Trigger}
   * attached to this class,
   * same goes for the
   * [sessionTrigger]{@link module:ui-router.RoutesMgr#sessionTrigger}.
   *
   * Therefore, it is only necessary to set those
   * properties ahead of time if the app is using
   * some type of custom implementation. Otherwise,
   * simply call this method, and it'll take care of the rest.
   * @function addTriggers
   * @memberof module:ui-router.RoutesMgr#
   * @returns {module:ui-router.RoutesMgr}
   * Returns `this` for chaining.
   */
  addTriggers() {
    if (!this.locationTrigger) {
      /**
       * The trigger that is added to Core's
       * [Location model]{@link module:core/location.Model}
       * to respond to any changes to the location.
       *
       * This will be an instance of the
       * [RecordTrigger]{@link module:ui-router.RecordTrigger}
       * on the client, and an instance of the
       * [RecordMaybeTrigger]{@link module:ui-router.RecordMaybeTrigger}
       * on the server, since on the server the
       * trigger should ignore changes that do
       * not match for the
       * [mainDispatchId set on this class]{@link module:ui-router.RoutesMgr#mainDispatchId}.
       *
       * This trigger can be set manually if a custom
       * trigger is being used, otherwise it will be
       * set automatically when the
       * [addTriggers method]{@link module:ui-router.RoutesMgr#addTriggers}
       * is called.
       * @member locationTrigger
       * @memberof module:ui-router.RoutesMgr#
       * @type {module:ui-router.RecordTrigger|module:ui-router.RecordMaybeTrigger}
       */
      this.locationTrigger = new this.Trigger(
        undefined,
        this,
        M.LOCATION,
        this.mainDispatchId
      );
    }
    if (!this.sessionTrigger) {
      /**
       * The trigger that is added to Core's
       * [Session model]{@link module:core/session.Model}
       * to respond to any changes to the session
       * (specifically, its
       * [userRole property]{@link module:core/session.Record#userRole}).
       *
       * This will be an instance of the
       * [RecordTrigger]{@link module:ui-router.RecordTrigger}
       * on the client, and an instance of the
       * [RecordMaybeTrigger]{@link module:ui-router.RecordMaybeTrigger}
       * on the server, since on the server the
       * trigger should ignore changes that do
       * not match for the
       * [mainDispatchId set on this class]{@link module:ui-router.RoutesMgr#mainDispatchId}.
       *
       * This trigger can be set manually if a custom
       * trigger is being used, otherwise it will be
       * set automatically when the
       * [addTriggers method]{@link module:ui-router.RoutesMgr#addTriggers}
       * is called.
       * @member sessionTrigger
       * @memberof module:ui-router.RoutesMgr#
       * @type {module:ui-router.RecordTrigger|module:ui-router.RecordMaybeTrigger}
       */
      this.sessionTrigger = new this.Trigger(
        undefined,
        this,
        M.SESSION,
        this.mainDispatchId
      );
    }
    this.routerLogic.getModel(M.LOCATION)
      .triggers
      .add(this.locationTrigger);
    this.routerLogic.getModel(M.SESSION)
      .triggers
      .add(this.sessionTrigger);
    return this;
  }

  /**
   * Removes the [locationTrigger]{@link module:ui-router.RoutesMgr#locationTrigger}
   * from Core's [Location model]{@link module:core/location.Model},
   * and the
   * [sessionTrigger]{@link module:ui-router.RoutesMgr#sessionTrigger}
   * from Core's [Session model]{@link module:core/session.Model}.
   *
   * It can also "destroy" these trigger instances if
   * `destroy === true`.
   *
   * This method will be called automatically when this
   * instance's [destroy method]{@link module:ui-router.RoutesMgr#destroy}
   * is called.
   *
   * Typically, this is only used server-side, since the RoutesMgr
   * instance is only applicable to a single
   * [REQUEST action]{@link module:ui-router/router/logic/request}.
   * But it can be used client-side as well if replacing the
   * triggers due to some type of custom implementation.
   * @function removeTriggers
   * @memberof module:ui-router.RoutesMgr#
   * @param {boolean} [destroy = false] - Whether to
   * also call each trigger's [destroy method]{@link module:store.Trigger#destroy}
   * (if it is defined) as well as set the
   * [locationTrigger property]{@link module:ui-router.RoutesMgr#locationTrigger}
   * and
   * [sessionTrigger property]{@link module:ui-router.RoutesMgr#sessionTrigger}
   * to `undefined`.
   * @returns {module:ui-router.RoutesMgr}
   * Returns `this` for chaining.
   */
  removeTriggers(destroy) {
    if (this.locationTrigger) {
      this.routerLogic.getModel(M.LOCATION)
        .triggers
        .remove(this.locationTrigger);
      if (destroy) {
        if (typeof this.locationTrigger.destroy === 'function') {
          this.locationTrigger.destroy();
        }
        this.locationTrigger = undefined;
      }
    }
    if (this.sessionTrigger) {
      this.routerLogic.getModel(M.SESSION)
        .triggers
        .remove(this.sessionTrigger);
      if (destroy) {
        if (typeof this.sessionTrigger.destroy === 'function') {
          this.sessionTrigger.destroy();
        }
        this.sessionTrigger = undefined;
      }
    }
    return this;
  }

  /**
   * A convenience method that calls the standalone
   * [matchLocation method]{@link module:ui-router.matchLocation}
   * to update the [match property]{@link module:core/coreLogic.Record#match}
   * of routing logic.
   *
   * It will provide the standalone
   * [matchLocation method]{@link module:ui-router.matchLocation}
   * with the additional parameters that
   * it requires ([routerLogic]{@link module:ui-router.RoutesMgr#routerLogic}
   * and the [allMissing Array]{@link module:ui-router.RoutesMgr#allMissing}).
   *
   * This method can be called at any time to update the
   * `match` property of the routing logic, but it is
   * most often called by the
   * [locationTrigger]{@link module:ui-router.RoutesMgr#locationTrigger}
   * due to a change to the
   * [location record]{@link module:core/location.Record}.
   *
   * An example of this method being called other than by the
   * `locationTrigger` would be server-side by the
   * [matchLocation logic]{@link module:ui-router/router/logic/request.matchLocation}
   * attached to the
   * [REQUEST action]{@link module:core-actions.request}.
   * @function matchLocation
   * @memberof module:ui-router.RoutesMgr#
   * @param {module:model.Mutation|module:model/mutation.Controller} mutation -
   * The model mutation or plugin mutation (a.k.a. `controller`)
   * to use when updating the `match` properties of each logic.
   *
   * A mutation for the [CoreLogic model]{@link module:core/coreLogic.Model}
   * will be retrieved from this param, so it doesn't need to
   * already be for the [CoreLogic model]{@link module:core/coreLogic.Model}.
   * @param {module:core/location.Record} location - The
   * [location record]{@link module:core/location.Record}
   * whose properties will be used when updating the
   * [match properties]{@link module:core/coreLogic.Record#match}.
   * @returns {?Object}
   * The
   * [MUTATION action payload]{@link module:core-actions.mutationPayload}
   * for the routerLogic,
   * or `null` if it didn't need to be dispatched to.
   */
  matchLocation(mutation, location) {
    return matchLocation(
      mutation,
      location,
      this.routerLogic,
      this.allMissing
    );
  }

  /**
   * Calls the routerLogic's
   * [localProps.addCheckPermsLogic function]{@link module:ui-router.RoutesMgr#__addCheckPermsLogic},
   * to update the
   * [permDenied property]{@link module:core/coreLogic.Record#permDenied}
   * of all applicable logic records.
   *
   * If the provided `mutation` is already in
   * [commitOnly mode]{@link module:model.Mutation#commitOnly},
   * it will wait until it completes, then grab a followup
   * mutation to perform the update.
   *
   * This method is primarily called by the
   * [sessionTrigger]{@link module:ui-router.RoutesMgr#sessionTrigger}
   * due to it detecting a change to the session record's
   * [userRole property]{@link module:core/session.Record#userRole}.
   * The [checkPerms method]{@link module:ui-router.RoutesMgr#checkPerms}
   * is also available to perform an update to the
   * [permDenied properties]{@link module:core/coreLogic.Record#permDenied}
   * independent of using logic.
   *
   * For more details on the whole permission process,
   * see CoreLogic's
   * [checkPerms module]{@link module:core/coreLogic/checkPerms}.
   * @function addCheckPermsLogic
   * @memberof module:ui-router.RoutesMgr#
   * @param {module:model.Mutation|module:model/mutation.Controller} mutation -
   * A model mutation, or plugin mutation (a.k.a. `controller`),
   * that will be used to get a mutation for the
   * [routerLogic]{@link module:ui-router.RoutesMgr#routerLogic}.
   *
   * @param {module:core/session.Record} session - The
   * session record. Its
   * [userRole property]{@link module:core/session.Record#userRole}
   * will be referenced to get the
   * [userRole record]{@link module:core/userRole.Record}
   * to use when updating the
   * [permDenied properties]{@link module:core/coreLogic.Record#permDenied}.
   */
  addCheckPermsLogic(mutation, session) {
    if (mutation.isOptimisticComplete) {
      /*
       * It is already in commit-only mode,
       * so we need to wait until the next
       * mutation. The reason for checking
       * isOptimisticComplete is because
       * the session could be updated from
       * a query, so mutation.isCommitOnly === true,
       * but the mutation hasn't executed
       * or hasn't optimistically completed.
       * So we'd still be good.
       */
      mutation.ofComplete().subscribe(() => {
        const m = mutation.model.mutation();
        /*
         * Only override properties need to be
         * provided, even though the typedef
         * shows properties as required. The
         * logic will fill in missing properties
         * from the action. See the __addCheckPermsLogic
         * documentation for details.
         */
        this.__addCheckPermsLogic(m, { session });
        m.execute();
      })
    } else {
      // Same as ^^^, only need to add overrides.
      this.__addCheckPermsLogic(mutation, { session });
    }
  }

  /**
   * Calls CoreLogic's
   * [checkPerms method]{@link module:core/coreLogic/checkPerms.checkPerms}.
   *
   * This is used by the
   * [matchLocation REQUEST logic]{@link module:ui-router/router/logic/request.matchLocation}
   * on the server to check perms for an incoming request.
   *
   * This is because each incoming request will have a different
   * [session record]{@link module:core/session.Record},
   * so all the logics'
   * [permDenied properties]{@link module:core/coreLogic.Record#permDenied}
   * needs to be updated independent of a change to the session record.
   *
   * Otherwise, it's handled by the
   * [sessionTrigger]{@link module:ui-router.RoutesMgr#sessionTrigger},
   * which calls the
   * [addCheckPermsLogic method]{@link module:ui-router.RoutesMgr#addCheckPermsLogic}
   * when a change to the session's
   * [userRole]{@link module:core/session.Record#userRole}
   * occurs.
   * @function checkPerms
   * @memberof module:ui-router.RoutesMgr#
   * @param {Object} payload - The Object that
   * will be provided to CoreLogic's
   * [checkPerms method]{@link module:core/coreLogic/checkPerms.checkPerms}
   * as its [payload param]{@link module:core/coreLogic/checkPerms.payload}.
   *
   * This method will ensure that the properties required
   * by CoreLogic's
   * [checkPerms method]{@link module:core/coreLogic/checkPerms.checkPerms}
   * are present on this `payload` before
   * calling it.
   *
   * For example, if `payload.userRole === undefined`
   * it will check for `payload.session`, and if that
   * exists, it will use its
   * [userRole property]{@link module:core/session.Record#userRole}.
   * This is because the [session record]{@link module:core/session.Record}
   * is available on the
   * [REQUEST action payload]{@link module:core-actions.requestPayload},
   * which is typically when this method is called instead of the
   * [addCheckPermsLogic method]{@link module:ui-router.RoutesMgr#addCheckPermsLogic}.
   *
   * Meaning, providing the
   * [REQUEST action payload]{@link module:core-actions.requestPayload}
   * is sufficient.
   *
   * Also, if `payload.checkPermsFilter === undefined`, it
   * will set it using the
   * [routerLogic's]{@link module:ui-router.RoutesMgr#routerLogic}
   * `localProps.checkPermsFilter` property.
   * @param {module:core/coreLogic.Record} [logic = this.routerLogic] - The
   * logic serving as the router.
   */
  checkPerms(payload, logic) {
    if (!logic) logic = this.routerLogic;
    if (typeof payload.userRole === 'undefined') {
      payload.userRole = payload.session
        ? payload.session.userRole
        : payload.mutation
            ? payload.mutation.getSession().userRole
            : logic.getSession().userRole;
    }
    if (typeof payload.checkPermsFilter === 'undefined') {
      payload.checkPermsFilter = logic.localProps.checkPermsFilter;
    }
    return checkPerms(payload, logic);
  }

  /**
   * Disposes of this instance by de-referencing its
   * properties and
   * [removing the triggers]{@link module:ui-router.RoutesMgr#removeTriggers}.
   *
   * This is primarily used server-side once the
   * [REQUEST action]{@link module:ui-router/router/logic/request}
   * completes, since a `RoutesMgr` instance is specific to
   * each request on the server.
   *
   * This can also be called client-side if there is some
   * kind of custom implementation that makes it necessary to do so.
   * @function destroy
   * @memberof module:ui-router.RoutesMgr#
   */
  destroy() {
    if (this.routerLogic) {
      this.removeTriggers(true);
      this.routerLogic = undefined;
      this.__addCheckPermsLogic = undefined;
    }
  }
}
