import { asyncScheduler, scheduled } from 'rxjs';
export {
  noop,
  hasOwn
} from './generics';
export { isDocsPath, isAppBuilderPath, isVoyagerPath } from './paths';
export { getBestTitle } from './getBestTitle';
export {
  docsUrlToSearchParams,
  createDocsDialogLink,
  createDocsDialogOnClick
} from './links';

export const nextTick$ = scheduled([ null ], asyncScheduler);
