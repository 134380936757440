import React, { useMemo } from 'react';
import { CssContext } from './CssContext';
import { RouterContext } from './RouterContext';
import {
  useRecordSubscription,
  RenderContext,
  createRenderContextValue
} from '@isomorix/react';
import {
  HelmetProvider
} from '@isomorix/react-ssr';
import { CORE_LOGIC_IDS } from './constants';

/*
 * These keys will be used to create a hash of the
 * record's state, and when that hash changes
 * useRecordSubscription() will trigger a re-render.
 *
 * In this case we're re-rendering any time the
 * location changes (it always updates the key property).
 */
const locKeys = [ 'key' ];

/**
 * The default component used for
 * [CoreLogic]{@link module:core/coreLogic.Record}
 * that is managing the top-level route for the app.
 * @function Router
 * @memberof module:react-router
 * @param {Object} props
 * @param {module:core/coreLogic.Record} props.logic - The
 * [CoreLogic record]{@link module:core/coreLogic.Record}
 * managing the route.
 *
 * The component will rerender when the record's
 * [match property]{@link module:core/coreLogic.Record#match}
 * changes.
 * @param {module:core/session.Record} props.session -
 * See the
 * [routerContextValue typedef]{@link module:react-router.routerContextValue}
 * for details.
 * @param {module:core/location.Record} props.location -
 * See the
 * [routerContextValue typedef]{@link module:react-router.routerContextValue}
 * for details.
 * @param {function(Object)} props.insertCss - See the
 * [ui-router's insertCss function]{@link module:ui-router~insertCss}.
 * @param {string} [props.logicChangeKey]
 * @param {Object} [props.helmetContext] - Can be
 * provided server-side to
 * capture the elements that should be included in the HTML's
 * `<head>`.
 *
 * Using Helmet is optional, see
 * [react-helmet-async documentation]{@link https://github.com/staylor/react-helmet-async}
 * for details on usage.
 * @param {?number} [props.dispatchId] -
 * See the
 * [routerContextValue typedef]{@link module:react-router.routerContextValue}
 * for details.
 */
export const Router = React.memo(function ReactRouter(props) {
  const {
    session,
    location,
    logic,
    insertCss,
    logicChangeKey,
    helmetContext,
    dispatchId = null
  } = props;
  const locChangeKey = useRecordSubscription(location, locKeys);
  const sessChangeKey = useRecordSubscription(session);
  const renderContext = useMemo(
    () => createRenderContextValue(props),
    [ dispatchId ]
  );
  const routerContextValue = useMemo(() => {
    return {
      renderer: renderContext,
      dispatchId,
      location,
      session,
      logic,
    }
  }, [
    dispatchId,
    renderContext,
    location,
    locChangeKey,
    session,
    sessChangeKey,
    logic,
    logicChangeKey,
  ]);
  const cssContextValue = useMemo(() => ({ insertCss }), [ insertCss ]);
  const app = (
    <RenderContext.Provider value={renderContext}>
      <HelmetProvider context={helmetContext}>
        <RouterContext.Provider value={routerContextValue}>
          <CssContext.Provider value={cssContextValue}>
            { props.children }
          </CssContext.Provider>
        </RouterContext.Provider>
      </HelmetProvider>
    </RenderContext.Provider>
  );
  return app;
});

Router.displayName = CORE_LOGIC_IDS.ROUTER;
