import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  useCss,
  useRouterContext
} from '@isomorix/react-router';
import { useCrossFadeTransition } from '@react-md/transition';
import {
  Layout as ReactMdLayout,
} from '@react-md/layout';
import { LinearProgress } from '@react-md/progress';
import { UserMenu } from './appBarActions';
import {
  LayoutContext,
  useLayoutContext,
  useLayoutNavigation,
  ViewModeButton
} from '@isomorix/react-md-layout';
import { ThemeTypeAppBarAction } from '../theme';
import { getBestTitle, isDocsPath } from '../helpers';
import { Home } from '../features';
import { useAppSize } from '@react-md/utils';
import {
  APP_BAR_HEIGHT,
  MAIN_SCROLL_ELEM_ID as SCROLL_ELEM_ID,
  ROUTE_SLUGS
} from '../constants';
import { AppBarAction } from '@react-md/app-bar';
import { FontIcon } from '@react-md/icon';
import { toggleChatWidget } from '../chat';
import { SocialMenuAppBarAction } from '../components';
// import { Form } from './Form';
// import { Button } from '@react-md/button';

const isBrowser = process.env.BROWSER;
let reloadAppButton;
if (process.env.NODE_ENV !== 'production') {
  const onClick = (e) => {
    e.preventDefault();
    if (
      typeof window !== 'undefined'
      && window.reloadApp
    ) {
      window.reloadApp();
    }
  }
  reloadAppButton = () => (
    <AppBarAction
      key={'reloadApp'}
      aria-label={'Reload App'}
      onClick={onClick}
    >
      <FontIcon>sync</FontIcon>
    </AppBarAction>
  )
}
const navHeaderProps = {
  title: "Navigation",
  theme: 'primary'
};
/*
 * Client-only: Once the docs styles get added,
 * keep them there. Otherwise, they get loaded
 * every time the dialog opens and closes, which
 * causes problems with not only flashing the
 * content w/o styles, but also scrolling to the
 * correct anchor. The anchor position is calculated,
 * but then the styles are loaded and the position
 * is no longer valid.
 */
const useDocsCss = process.env.BROWSER
  ? (templateCss) => useCss(templateCss)
  : (templateCss, isDocs) => isDocs && useCss(templateCss);

/*
 * Used when adding an action button to perform testing.
 */
// function LoadMissingSchema(
//   {
//     logic
//   }
// ) {
//   return (
//     <Button
//       theme={'primary'}
//       themeType={'contained'}
//       floating={'bottom-left'}
//       onClick={() => {
//         logic.localProps.loadMissingSchema();
//       }}
//     >
//       'LOAD'
//     </Button>
//   )
// }

export const Layout = React.memo(function Layout(props) {
  const { is2D } = props;
  const {
    renderer,
    location,
    logic,
    session
  } = useRouterContext();
  const rootValue = useLayoutContext();
  const appMedia = useAppSize();
  const { elementProps, transitionTo } = useCrossFadeTransition();
  const { localProps, match } = logic;
  const { missingChildRoutes, DocsSearchButton } = localProps;
  const { pathname } = location;
  const isDocs = isDocsPath(pathname);
  const [ elevated, setElevation ] = useState(!match.isExact);
  const prevPathname = useRef('');
  useDocsCss(localProps.docsTemplateCss, isDocs);
  const contextValue = useMemo(() => ({
    ...rootValue,
    setElevation,
    is2D,
    scrollWidth: appMedia.isDesktop
      ? rootValue.width - 56
      : rootValue.width,
    scrollHeight: rootValue.height - APP_BAR_HEIGHT,
    isRoot: false,
  }), [ rootValue, is2D, setElevation, appMedia ]);
  useEffect(() => {
    if (
      prevPathname.current
      && pathname !== prevPathname.current
      && (!isDocsPath(pathname) || !isDocsPath(prevPathname.current))
      && (pathname.indexOf('/app-builder') < 0 || !session.userRoleId)
    ) {
      transitionTo('enter');
      setTimeout(() => location.registerScrollElem(`#${SCROLL_ELEM_ID}`), 10);
    } else {
      location.registerScrollElem(`#${SCROLL_ELEM_ID}`);
    }
    prevPathname.current = pathname;
  }, [ location.key, transitionTo, is2D, session ]);
  let children = renderer.many(logic.children);
  let docsPortal = null;
  if (localProps.docsStore && localProps.docsStore.value) {
    const Portal = localProps.docsStore.get('Portal');
    docsPortal = <Portal store={localProps.docsStore} />;
  }
  const treeProps = useLayoutNavigation(logic, session);
  treeProps.navClassName = 'core-ui-layout-navigation-tree-nav';
  let docsSearch;
  if ((isBrowser || isDocs) && DocsSearchButton) {
    docsSearch = (
      <DocsSearchButton
        key={'search-button'}
        first={true}
        routerLogic={logic}
        isDocsRoute={isDocs}
        docsRouteSlug={ROUTE_SLUGS.DOCS}
      />
    )
  }
  /*
   * Either I don't understand it, or it's a bug. But the
   * layout that gets chosen based on the app size
   * is confusing AF, and it feels inconsistent.
   *
   * If you include "mini" in any of the types, it'll
   * show it regardless of the appSize. But the LayoutContent
   * component only includes padding for it if the appSize
   * is desktop. So this hack overrides it by just
   * resetting all types to be the one I really want to use.
   *
   * Also, temporary is not respected if it's a tablet.
   * All you can do is toggleable or toggleable-mini.
   */
  const layoutType = appMedia.isDesktop
    ? 'toggleable-mini'
    : appMedia.isTablet
      ? 'toggleable'
      : 'temporary';
  /*
   * Used when adding an action button to perform testing.
   */
  // if (!children) children = [];
  // children.push(
  //   <LoadMissingSchema key={'loadMissingSchema'} logic={logic} />
  // )
  return (
    <LayoutContext.Provider value={contextValue}>
      <ReactMdLayout
        id={is2D ? 'layout' : 'layout3D' }
        title={getBestTitle(location)}
        navAfterAppBar={true}
        navHeaderProps={navHeaderProps}
        treeProps={treeProps}
        tabletLayout={layoutType}
        desktopLayout={layoutType}
        largeDesktopLayout={layoutType}
        phoneLayout={layoutType}
        mainProps={{
          ...elementProps,
          headerOffset: true,
          mini: appMedia.isDesktop,
        }}
        appBarProps={{
          fixedElevation: !match.isExact || elevated,
          children: [
            docsSearch,
            (
              <ViewModeButton
                key={'viewModeAppBarAction'}
                menuBaseId={`viewModeAppBarContextMenu${is2D ? '2D' : '3D'}`}
                appBarAction={true}
                logic={logic}
                first={!isDocs || !!!DocsSearchButton}
              />
            ),
            (<ThemeTypeAppBarAction
                key={'theme-type-button'}
                session={session}
              />
            ),
            is2D && reloadAppButton && reloadAppButton(),
            (
              <SocialMenuAppBarAction
                id={`social-menu-app-bar-action-${is2D ? '2D' : '3D'}`}
                key={'social-menu-app-bar-action'}
              />
            ),
            (
              <AppBarAction
                key={'contact-me'}
                onClick={toggleChatWidget}
                aria-label={'chat or message us'}
              >
                <FontIcon>contact_support</FontIcon>
              </AppBarAction>
            ),
            (<UserMenu
                key={'user-menu'}
                last={true}
                logic={logic}
                session={session}
              />
            )]
      }}
      >
        <div id={SCROLL_ELEM_ID}>
          { missingChildRoutes && missingChildRoutes.length
              ? <LinearProgress key={'loading'} />
              : undefined
          }
          { match.isExact && (
              <Home
                logic={logic}
                session={session}
                is2D={is2D}
                isLighthouse={localProps.isLighthouse}
                setElevation={setElevation}
              />
            )
          }
          { children }

        </div>
      </ReactMdLayout>
      { docsPortal }
    </LayoutContext.Provider>
  )
});
