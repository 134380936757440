import { recordLogicMgr } from '@isomorix/record';
import { MUTATION } from '@isomorix/core-actions';
import { asyncLogicResp } from '@isomorix/store';

const trxNotify = recordLogicMgr.findByName('trxNotify', MUTATION);

/*
 * This takes place after trxNotify. That way there's
 * a new mutation for the children to use. Otherwise,
 * they'd be trying to use the commit-only mutation.
 *
 * Also, see ../helpers/matchLocation and
 * ../routerLogic/mutation. The mutation logic
 * will handle cleaning up if the mutation gets
 * cancelled before we get here, which prevents
 * having un-initialized logic in local state.
 */
function _dispatchToMissingChildLogic(action, observer) {
  const routeLogic = action.payload.missingChildRoutes;
  if (!routeLogic) {
    return asyncLogicResp(null, action, observer);
  } else {
    /*
     * There could be `undefined` entries if permission
     * was denied, but dispatchTo() checks for that.
     *
     * Providing `null` for payload ensures a new
     * payload is created for each action. And providing
     * `mainDispatchId` will result in creating
     * a new dispatchId for the action, but it'll
     * then use the same dispatchId for each dispatch.
     */
    return asyncLogicResp(action.meta.instance.dispatchTo(
      routeLogic,
      null,
      action.meta.mainDispatchId
    ), action, observer);
  }
}


export function addSharedMutationLogic(builder) {

  function dispatchToMissingChildLogic(action) {
    return _dispatchToMissingChildLogic(action);
  }

  builder.useRelative(trxNotify, 10)
    .setName('dispatchToMissingChildLogic')
    .setPure(false)
    .setDescription('Dispatches to new routeLogic so it can setup and render.')
    .add(dispatchToMissingChildLogic, true);
}
