import React from 'react';
import { Typography } from '@react-md/typography';
import { Button } from '../../components';
import { useRecordSubscription } from '@isomorix/react';
import { FontIcon, TextIconSpacing } from '@react-md/icon';
import { Button as RmdButton } from '@react-md/button';
import { toggleChatWidget } from '../../chat';
import { LaunchTimer } from '../../countdownTimer';

const sessionKeys = [ 'userRoleId' ];

export const main = {
  name: 'main',
  // headline: [
  //   'How To Build And Scale Even The Most Complex Web App With A Tiny Fraction Of The Time And Effort You\'re Putting In Now,',
  //   {
  //     children: 'And Get Paid To Do It',
  //     color: 'theme-secondary',
  //   }
  // ],
  // subHeadline: '...And How To Get Paid For Doing It',
  headline: [
    // 'How To Dramatically Simplify Even The Most Complex Web App',
    // 'How To Dramatically Simplify Any Web App, Regardless Of Complexity,',
    // 'How To Dramatically Simplify And Supercharge Any Web App, Regardless Of Complexity,',
    'How to Dramatically Simplify Any Web App, Unlock Entirely New Capabilities, And Even Get Paid To Do It',
    // {
    //   children: 'And Even Get Paid To Do It',
    //   color: 'theme-secondary',
    // }
  ],
  CustomContent(
    {
      session,
      isLighthouse
    }
  ) {
    useRecordSubscription(session, sessionKeys);
    if (session.userRoleId) {
      return (
        <div className={'subheadline-content'}>
          <LaunchTimer isLighthouse={isLighthouse} />
          <Typography type={'headline-2'}>
            I'll keep you posted on upcoming launch events, { session.userRole.user.firstName }!
          </Typography>
          {/*{ children }*/}
          <Typography key={'questions'} type={'body-1'}>
            Questions/Feedback in the meantime?
          </Typography>
          <RmdButton
            key={'get-in-touch'}
            type={'button'}
            theme={'clear'}
            themeType={'contained'}
            aria-label={'get in touch'}
            onClick={toggleChatWidget}
          >
            <TextIconSpacing
              icon={<FontIcon>contact_phone</FontIcon>}
            >
              Get In Touch!
            </TextIconSpacing>
          </RmdButton>
        </div>
      )
    } else {
      return (
        <div className={'subheadline-content'}>
          <LaunchTimer isLighthouse={isLighthouse} />
          <Typography type={'headline-2'}>
            Get notified of upcoming launch events & get instant access to prelaunch content!
          </Typography>
          <Button
            to={{ user_login: true }}
            searchOnly={true}
            theme={'clear'}
            themeType={'contained'}
            icon={'verified_user'}
            aria-label={'login or register'}
          >Login/Register</Button>
        </div>
      )
    }
  },
  video: {
    url: 'https://dev-39.wistia.com/medias/73sl3pcebu',
    preview: '/static/img/vid/isomx_presentation_devs_preview.webp',
    totalSeconds: 4091
  },

}
