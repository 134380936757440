import { useCallback, useMemo } from 'react';
import { DOCS_URL_SEARCH as SEARCH } from '@isomorix/core-config';
import { useLocation } from '@isomorix/react-router';
import {
  createDocsDialogLink,
  createDocsDialogOnClick
} from '../helpers';

export function useDocsDialogLink(
  {
    to,
    state,
    replace,
    onClick
  }
) {
  const location = useLocation();
  return useMemo(() => createDocsDialogLink(
    location,
    {
      to,
      state,
      replace,
      onClick
    }
  ), [ to, state, replace, onClick, location ]);
}

export function useDocsDialogOnClick(
  {
    to,
    state,
    replace,
    onClick
  }
) {
  const location = useLocation();
  return useMemo(() => createDocsDialogOnClick(location, {
    to,
    state,
    replace,
    onClick,
  }), [ to, state, replace, onClick, location ]);
}

export function useDocLinks(location) {
  const onClick = useCallback(e => {
    const t = e.target;
    if (t.tagName !== 'A') return;
    e.preventDefault();
    const s = {
      [SEARCH.PATHNAME]: t.pathname,
      [SEARCH.HASH]: t.hash
        ? t.hash.startsWith('#')
          ? t.hash.replace('#', '')
          : t.hash
        : undefined,
      [SEARCH.OFFSET_X]: e.clientX,
      [SEARCH.OFFSET_Y]: e.clientY,
    };
    location.pushSearch(s);
  }, [ location ]);
  return onClick;
}
