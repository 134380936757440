import {
  CORE_LOGIC_TYPES,
  MODEL_META_FIELDS as META_FIELDS
} from '@isomorix/core-config';
import { createAddCheckPermsLogic } from '@isomorix/core-logic';
import { DEFAULT_LOGIC_PRIORITY, TYPE } from '@isomorix/store';
import { RoutesMgr } from '../RoutesMgr';
let sharedDesc;
if (process.env.NODE_ENV === 'production') {
  sharedDesc = {}
} else {
  sharedDesc = {
    setMatch: `Sets the logic's [match property]{@link module:core/coreLogic.Record#match} and [permDenied property]{@link module:core/coreLogic.Record#permDenied}, if [session.location]{@link module:core/session.Record#location} is available.`,
    dispatchChildren: `Dispatches to the router's children`,
    initLocalProps: `Initializes the localProps Object.`
  };
}
/*
 * Determines whether to descend into children
 * based on the status of the parent. The
 * default is to always descend, which is
 * correct for the client. But on the server,
 * it's pointless since it won't render anything.
 * And it'll be rechecked on the client side.
 */
let checkPermsFilter;
let reducerRemoveMatch;
if (!process.env.BROWSER) {
  const { ROUTER, ROUTE } = CORE_LOGIC_TYPES;
  checkPermsFilter = (logic, permDenied) => {
    if (permDenied) return false;
    const t = logic.type;
    return t === ROUTER || t === ROUTE
      ? !!logic.match
      : true;
  }
  reducerRemoveMatch = sv => {
    const state = sv.getState();
    if (state.match) {
      state.match = null;
    }
  }
}

function _initLocalProps(action) {
  const logic = action.meta.instance;
  logic.getMutableRecord(action);
  const localProps = logic.getMutableLocalProps();
  if (!localProps.missingChildRoutes) {
    localProps.missingChildRoutes = [];
  }
  if (!localProps.checkPermsFilter) {
    localProps.checkPermsFilter = checkPermsFilter;
  }
  logic.localProps = localProps;
  return localProps;
}

function _setMatch(action) {
  const session = action.meta.getSession();
  if (!session) return action;
  const store = action.meta.store;
  const logic = store.instance;
  const { route } = logic;
  const location = logic.getLocation(action);
  if (location && route) {
    if (!action.payload.isReload) {
      /*
       * This is universal, since the logic on the
       * server could be loaded during a request,
       * in which case `session` will exist and
       * we need to go ahead and set the match.
       *
       * Session will always exist on the client.
       *
       * Additionally, on the client, this assumes
       * any matched logic was already included
       * in window.__PRELOAD_STATE__. This is why
       * "reload" is handled differently. When that
       * is the case, matchLocation() is used so
       * that any missing logic is fetched.
       */
      const prev = logic.match;
      const match = route.matchPath(
        location.pathname,
        location.searchParams,
        location.hash,
        prev
      );
      if (match !== prev) {
        logic.match = match;
      }
    } else if (!store.value.get('routerLogicId')) {
      /*
       * We're the router. Match the location for everyone
       * so any logic not existing in preload state will
       * be fetched by our mutation logic.
       *
       * Also, the location is updated by core/logic/init.saveModels()
       * when payload.isReload = true. In the future, all
       * this should be configurable where you could
       * decide what parts are kept from "old" state.
       * But this is sufficient for now.
       */
      logic.localProps.routesMgr.matchLocation(
        store.value.mutation,
        location
      );
    }
  }
  const permDenied = store.value.get('permDenied');
  if (typeof permDenied !== 'boolean') {
    const { userRole } = session;
    let permDenied = !logic[META_FIELDS.PERM_REC_READ](userRole, logic);
    if (!permDenied) {
      permDenied = !route.permUse(userRole, logic);
    }
    logic.permDenied = permDenied;
  }
  return action;
}

function _dispatchChildren(action) {
  const logic = action.meta.instance;
  const obs$ = logic.dispatchTo(
    logic.children,
    null,
    action.meta.dispatchId
  );
  if (obs$) obs$.subscribe();
  return action;
}

/**
 *
 * @param {module:store.LogicBuilder} builder
 * @ignore
 */
export function addSharedInitLogic(builder) {

  let initLocalProps;
  if (builder.DEFAULTS[TYPE] === CORE_LOGIC_TYPES.ROUTER) {
    initLocalProps = function initLocalProps(action) {
      const localProps = _initLocalProps(action);
      const { meta, payload } = action;
      if (!localProps.RoutesMgr) {
        localProps.RoutesMgr = payload.RoutesMgr || RoutesMgr;
      }
      if (!localProps.addCheckPermsLogic) {
        localProps.addCheckPermsLogic = createAddCheckPermsLogic(
          action.meta.store,
        );
      }
      const logic = meta.instance;
      if (!process.env.BROWSER) {
        /*
         * The getAssetsMgr function is server-side only,
         * and it needs storeConfig. It'll use it to
         * memoize preloadState and stuff.
         */
        if (!meta.store.storeConfig){
          meta.store.storeConfig = {};
        }
        if (!localProps.getAssetsMgr) {
          if (!payload.getAssetsMgr) {
            throw new Error(`The payload.getAssetsMgr function was not provided when initiating routerLogic with name ${logic.name}, associated with route ${logic.route?.slug}. This is required on the server if not already set on the logic's localProps.`);
          }
          localProps.getAssetsMgr = payload.getAssetsMgr;
        }
        let { props } = logic;
        if (!props || !props.containerId) {
          props = logic.getMutableProps();
          props.containerId = `${logic.plugin.slug}_app_container`;
          logic.props = props;
        }
        meta.store.addMainReducers(reducerRemoveMatch);
      } else if (!localProps.routesMgr) {
        /*
         * The routesMgr is initiated per request
         * on the server, since each request will
         * be from a different client. But on the
         * client it's the same.
         */
        localProps.routesMgr = localProps.RoutesMgr.init(
          action.meta.instance,
          action.meta.getSession()
        ).addTriggers();
      }
      return action;
    }
  } else {
    initLocalProps = function initLocalProps(action) {
      _initLocalProps(action);
      return action;
    }
  }

  builder.use()
    .setName('initLocalProps')
    .setPriority(100)
    .setPrepareOp()
    .setPure(true)
    .setDescription(sharedDesc.initLocalProps)
    .add(initLocalProps, true);

  function setMatch(action) {
    return _setMatch(action);
  }

  builder.use()
    .setName('setMatch')
    .setPrepareOp()
    .setPriority(DEFAULT_LOGIC_PRIORITY - 100)
    .setPure(true)
    .setDescription(sharedDesc.setMatch)
    .add(setMatch, true);

  function dispatchChildren(action) {
    return _dispatchChildren(action);
  }

  builder.use()
    .setName('dispatchChildren')
    .setPrepareOp()
    .setPriority(DEFAULT_LOGIC_PRIORITY)
    .setPure(true)
    .setDescription(sharedDesc.dispatchChildren)
    .add(dispatchChildren, true);
}
