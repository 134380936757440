import { getBestTitle as _default } from '@isomorix/ui-router';
import { docsDirRegexp, isDocsPath } from './paths';

const srcToPath = /_/g;
export function getBestTitle(location) {
  let title, pathname;
  if (typeof location === 'string') {
    pathname = location;
  } else {
    ({ title, pathname } = location);
  }
  if (!isDocsPath(pathname)) {
    title = _default(location);
    return title.startsWith('isomx')
      ? title
      : `isomx${title ? ` - ${title}` : ''}`;
  }
  if (title) {
    return title.startsWith('isomx - Docs')
      ? title
      : `isomx - Docs - ${title}`;
  }
  title = pathname
    .replace(docsDirRegexp, '')
    .replace('.html', '');
  if (title.endsWith('.js')) {
    title = title.replace(srcToPath, '/');
  }
  return title
    ? `isomx - Docs - ${title}`
    : `isomx - Docs`;
}
