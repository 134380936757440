import React, { createElement } from 'react';
import { Button as RmdButton } from '@react-md/button';
import { useLocation } from '@isomorix/react-router';
import { createDocsDialogOnClick } from '../helpers';
import { FontIcon, TextIconSpacing } from '@react-md/icon';

export const Button = React.memo(React.forwardRef(function Button(
  {
    to,
    state,
    replace,
    searchOnly,
    isDocsLink,
    onClick,
    icon,
    iconAfter,
    ...props
  },
  ref
){
  if (ref) {
    props.ref = ref;
  }
  const location = useLocation();
  if (isDocsLink) {
    props.onClick = createDocsDialogOnClick(location, {
      to,
      state,
      replace,
      onClick,
    })
  } else {
    props.onClick = location.createOnClick({
      to,
      state,
      replace,
      onClick,
      searchOnly,
    });
  }
  if (icon) {
    if (props.floating) {
      props.children = <FontIcon>{ icon }</FontIcon>
    } else {
      props.children = (
        <TextIconSpacing
          icon={<FontIcon>{ icon }</FontIcon>}
          iconAfter={iconAfter}
        >
          { props.children }
        </TextIconSpacing>
      )
    }
  }
  return createElement(RmdButton, props);

}));
