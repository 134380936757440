export const nginx = {
  name: 'nginx',
  headline: [
    'Done-For-You',
    { children: 'Nginx', className: 'color--nginx' },
    'Server & Reverse Proxy, Expertly Tuned For',
    { children: 'Your', decoration: 'underline' },
    'Unique App'
  ],
  video: {
    url: 'https://dev-39.wistia.com/medias/t1z0m4qe4y',
    preview: '/static/img/vid/breakout_nginx_preview.webp',
    totalSeconds: 35,
  },
  panels: [{
    header: {
      icon: 'cloud_sync',
      avatarColor: 'green',
      text: 'Real-Time Monitoring'
    },
    text: [
      'Your App and all associated services will be constantly watched so you never need to think about Nginx.',
      'Switch domains, start & stop services, even change IPs without so much as a hiccup - everything will be quickly reconfigured and deployed for you automatically.'
    ],
  }, {
    header: {
      icon: 'compare_arrows',
      avatarColor: 'green',
      text: 'Configurable Automation'
    },
    text: [
      'I\'m not satisfied until I can have my cake and eat it too. And you shouldn\'t be either.',
      'So while everything is ready to go out of the box, including real-time configuration for your App, GraphQL, Search, and more, it\'s also super easy to override or augment any configuration.',
      'There\'s nothing new to learn, either.',
      'It uses the exact same logic that powers everything else, such as models and records, making it familiar and dead-simple to hook into anything you can imagine.',
      'You also get a powerful template engine so you can confidently add any custom service with just a few lines of code, regardless of complexity. But you can also write the config by hand if you want - no vendor lock in here :)'
    ]
  }, {
    header: {
      icon: 'https',
      avatarColor: 'green',
      text: 'Integrated SSL',
    },
    text: [
      'No need to install 3rd party packages to configure SSL. It monitors your app and the domains it uses, and can automatically configure SSL for you using letsencrypt, or do so on-demand with an interactive guide - whatever works for you.',
      'In any case, once a domain has an SSL certificate it will do all the heavy lifting for you by configuring all the SSL settings and headers based on industry best practices so you don\'t have to worry about it.',
      'Already have an SSL certificate? Bring it with you! As with all isomx features, there is absolutely ZERO vendor lock-in.'
    ]
  }, {
    header: {
      icon: 'dynamic_form',
      avatarColor: 'green',
      text: 'Extremely Adaptive'
    },
    text: [[
      'Automatically detects virtually every conceivable setup and adapts to',
      { children: 'you', after: '.', decoration: 'underline' },
      'It\'s fully capable of complex service discovery, copying files into running or stopped containers, restarting Nginx remotely as things change, and so much more.'
    ],
      'Want to run your App locally while all other services are run in containers?',
      'No problem!',
      'It comes with full Docker API integration and will seamlessly communicate with containers from the host instead.',
      'There are literally countless examples, but the bottom line is you get the peace of mind that as your App evolves, this will be here to support you every step of the way.'
    ]
  }, {
    header: {
      icon: 'developer_mode',
      avatarColor: 'green',
      text: 'Development Mode',
    },
    text: [
      'Development has never been this easy.',
      'When your App is started in development mode, it shifts out of the production configuration and transparently handles everything needed for even the most demanding development environment.',
      'That includes things like intercepting requests for bundle resources and routing them to the Webpack DevServer, setting up a websocket endpoint for Hot Module Replacement (HMR), and much more.',
      'It will also act as a "traffic cop" between the frontend and backend so that both can successfully restart at the same time without causing mismatched state that is difficult to detect and trace.',
      'There\'s a lot more it does too, like disabling caching, optionally mapping static files to a local directory on the host, things like that.',
      'Altogether, a ton of thought and detail has gone into providing you with the most amazing development experience so you can focus on shipping great features instead of wasting time on configurations.'
    ]
  }, {
    header: {
      icon: 'key_off',
      avatarColor: 'green',
      text: 'Zero Vendor Lock In'
    },
    text: [[
      'This',
      { children: 'is not', decoration: 'underline' },
      'some meta implementation or some weird abstraction over the top of Nginx that leaves you feeling vulnerable that you\'re locked in to a custom solution.',
    ],
      'Quite the opposite.',
      'It simply analyzes your app to produce configs based on accepted best practices. Even though it\'s going to feel like magic, at the end of the day this is just a standard Nginx setup the same as any other.',
      'What\'s more, you fully control the Nginx service. You could just as easily use this to generate your configs and then deploy those to an existing solution if you want - no problems, no headaches, ever.'
    ]
  }]
}
