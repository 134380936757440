import { Subscriber, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
  routerMutationBuilder as builder
} from './logicMgr';
import { addSharedMutationLogic } from '../sharedLogic';

addSharedMutationLogic(builder);

/*
 * Handles removing the routeIds from the
 * RoutesMgr's `allMissing` Array.
 */
const _removeFromAllMissing = (routeIds, allRouteIds) => {
  let idx;
  for(let id of routeIds) {
    if ((idx = allRouteIds.indexOf(id)) > -1) {
      allRouteIds.splice(idx, 1);
    }
  }
}


class RemoveFromAllMissing extends Subscriber {
  constructor(routeIds, allRouteIds) {
    super();
    this.__routeIds = routeIds;
    this.__allRouteIds = allRouteIds;
  }

  _complete() {
    _removeFromAllMissing(this.__routeIds, this.__allRouteIds);
    this.unsubscribe();
  }

  unsubscribe() {
    super.unsubscribe();
    this.__routeIds = undefined;
    this.__allRouteIds = undefined;
  }
}


function manageMissingRouteLogic(action) {
  return action.payload.mutation.ofConnectorComplete().pipe(
    mergeMap(() => _doFetchMissingRouteLogic(action))
  );
  /*
   * 07/04/2022:
   *
   * The below code was the implementation when this logic was
   * pure logic. It would  add the `fetchMissingRouteLogic`
   * logic if payload.missingRouteIds exists, and that
   * logic would then handle fetching it. That's
   * the logic that is commented out at the end of
   * this module.
   *
   * The problem is that if the location changes during
   * commit, meaning the response from the backend contains
   * a different location, missingRouteIds may not exist
   * right now, but could due to the response from the backend
   * because the new location would trigger another call
   * to matchLocation().
   *
   * That results in each route failing because the
   * `missingChildRoutes` Array on their payloads
   * will be an Array of ids rather than references
   * to the actual route logic, since this method won't
   * handle replacing the ids with references to the records
   * (see dispatchToMissingChildLogic() in ../shared/mutation.js).
   * That's because this logic won't catch that it needs to
   * do so due to it running before the connector resp is complete.
   *
   * This issue came up in development mode when
   * reloading the app. But for safety, I updated this
   * logic to always wait until after the connector response
   * is complete, before then calling _doFetchMissingRouteLogic() which
   * implements what the fetchMissingRouteLogic logic does.
   *
   * I think this is the best solution for now. I can foresee
   * other situations where a backend location response could
   * differ from what exists in local state, which would also
   * cause the problem described above. The only
   * downside is you create async logic for every router
   * logic mutation, which is what this original implementation
   * was designed to prevent. But the potential for errors is just
   * too high in my opinion. Plus it's only 1 Observable...
   * So I updated this logic to wait instead. Remove
   * the above code and uncomment this below code,
   * as well as the fetchMissingRouteLogic logic at the
   * end of this module, to revert to the original
   * implementation.
   */
  // if (action.payload.missingRouteIds) {
  //   action.meta.addLogic(fetchMissingRouteLogic);
  // }
  // return action;
}

builder.useRelative('trxCommit', -100)
  .setName('manageMissingRouteLogic')
  .setPure(false)
  .setCancelOp(action => {
    const { payload } = action;
    if (!payload.missingRouteIds) return action;
    if (payload.allMissingRouteIds) {
      /*
       * Remove from the Array held by the RoutesMgr,
       * the `allMissingRouteIds` is a reference to
       * that Array.
       */
      _removeFromAllMissing(payload.missingRouteIds, payload.allMissingRouteIds);
    }
    if (payload.removeFromAllMissingSubscriber) {
      payload.removeFromAllMissingSubscriber.unsubscribe();
      payload.removeFromAllMissingSubscriber = undefined;
    }
    /*
     * Calling complete() on the Observable will signal to
     * the subscriber for each childRoute that had
     * missing child logic that the action was cancelled.
     * It will remove the id(s) from its
     * logic's localProps. See notes in
     * ../helpers/matchLocation => MissingSub._complete()
     */
    payload.missingRouteIds$.complete();
    payload.missingRouteIds$ = undefined;
    return action;
  });

if (process.env.NODE_ENV !== 'production') {
  builder
    ///////////////////////////////////////////////////////////
    // Original description when logic was added dynamically
    // to handle fetching the missing route logic as described
    // in the above notes in the op method.
    ///////////////////////////////////////////////////////////
    // .setDescription(`Manages fetching any missing route logic (\`payload.missingRouteIds\`, which identifies the routes that are missing logic). If there is logic that is missing, it will add async logic (\`fetchMissingRouteLogic\`) that executes a query to fetch the logic. That logic will wait until [mutation.isConnectorComplete]{@link module:model.Mutation#isConnectorComplete} is \`true\` before doing so, that way if the mutation gets cancelled, there won't be an unnecessary fetch for the missing logic.`)
    .setDescription(`Manages fetching any missing route logic identified by the \`payload.missingRouteIds\` property, which lists the ids of the routes that are missing logic. This logic will wait until the response from the connector is complete (see the mutation's [ofConnectorComplete method]{@link module:model.Mutation#ofConnectorComplete} before checking if there is indeed missing logic, since the location could change during commit if the location is being updated during this mutation and the response from the backend is not the same location that existed originally.`);
}
builder.add(manageMissingRouteLogic, true);

function _doFetchMissingRouteLogic(action) {
  const { payload } = action;
  const { missingRouteIds } = payload;
  /**
   * @type {module:model.Mutation}
   * @ignore
   */
  const mutation = payload.mutation;
  if (mutation.isCancelling || !missingRouteIds || !missingRouteIds.length) {
    return of(action);
  }
  return mutation.model.query().selectAll(true)
    .whereIn('routeId', missingRouteIds)
    .andWhere('routerLogicId', action.meta.instance.__ID)
    .includeAll('groupLogic', true)
    .fetch({ dispatchId: mutation.mainDispatchId })
    .pipe(
      map(qPayload => {
        if (mutation.isCancelling) return action;
        const { records } = qPayload;
        payload.missingRouteLogic = records;
        /*
         * This is the Observable that each child
         * logic is subscribed to that the fetched
         * logic belongs to. It'll handle replacing
         * their `payload.missingChildRoutes` Array
         * with the actual record reference. Then,
         * there's mutation logic attached to all
         * route logic that runs after trxNotify
         * that will dispatch INIT to their new logic.
         */
        payload.missingRouteIds$.next(records);
        payload.missingRouteIds$.complete();
        payload.missingRouteIds$ = undefined;
        /*
         * This is from the RoutesMgr instance,
         * which keeps this Array to prevent
         * duplicate loading of the same routes.
         */
        const { allMissingRouteIds } = payload;
        if (allMissingRouteIds) {
          /*
           * Add a reference to the subscriber in case
           * the action gets cancelled. It'll be unsubscribed
           * by the logic that added us, since it'll do it
           * manually. That way we don't try to remove the
           * ids from another action in case another action
           * is dispatched to fetch the logic.
           */
          payload.removeFromAllMissingSubscriber = action.meta.store.subscribe(
            new RemoveFromAllMissing(missingRouteIds, allMissingRouteIds)
          )
        }
        return action;
      })
    )
}

/////////////////////////////////////////////////////////
// 07/04/2022: See notes in manageMissingRouteLogic()
// about this logic and what it is used for, as well
// as why it's no longer done this way.
/////////////////////////////////////////////////////////
// /**
//  *
//  * @param {module:core-actions.mutation} action
//  * @ignore
//  */
// function fetchMissingRouteLogicOpMethod(action) {
//   const { meta, payload } = action;
//   const { missingRouteIds } = payload;
//   /**
//    * @type {module:model.Mutation}
//    * @ignore
//    */
//   const mutation = payload.mutation;
//   return action.payload.mutation.ofConnectorComplete().pipe(
//     mergeMap((ctrl) => {
//       if (ctrl.isCancelling) {
//         return of(action);
//       }
//       return mutation.model.query().selectAll(true)
//         .whereIn('routeId', missingRouteIds)
//         .andWhere('routerLogicId', meta.instance.__ID)
//         .includeAll('groupLogic', true)
//         .fetch({ dispatchId: mutation.mainDispatchId })
//     }),
//     map(qPayload => {
//       if (mutation.isCancelling) return action;
//       const { records } = qPayload;
//       payload.missingRouteLogic = records;
//       /*
//        * This is the Observable that each child
//        * logic is subscribed to that the fetched
//        * logic belongs to. It'll handle replacing
//        * their `payload.missingChildRoutes` Array
//        * with the actual record reference. Then,
//        * there's mutation logic attached to all
//        * route logic that runs after trxNotify
//        * that will dispatch INIT to their new logic.
//        */
//       payload.missingRouteIds$.next(records);
//       payload.missingRouteIds$.complete();
//       payload.missingRouteIds$ = undefined;
//       /*
//        * This is from the RoutesMgr instance,
//        * which keeps this Array to prevent
//        * duplicate loading of the same routes.
//        */
//       const { allMissingRouteIds } = payload;
//       if (allMissingRouteIds) {
//         /*
//          * Add a reference to the subscriber in case
//          * the action gets cancelled. It'll be unsubscribed
//          * by the logic that added us, since it'll do it
//          * manually. That way we don't try to remove the
//          * ids from another action in case another action
//          * is dispatched to fetch the logic.
//          */
//         payload.removeFromAllMissingSubscriber = meta.store.subscribe(
//           new RemoveFromAllMissing(missingRouteIds, allMissingRouteIds)
//         )
//       }
//       return action;
//     })
//   );
// }
//
// const fetchMissingRouteLogic = builder
//   .useRelative('manageMissingRouteLogic', 1)
//   .setName('fetchMissingRouteLogic')
//   .setPure(false)
//   .setId()
//   .setOpMethod(fetchMissingRouteLogicOpMethod)
//   .setDescription(`Dynamic logic that is added if there is missing routeLogic (\`payload.missingRouteIds\`) to execute a query to fetch the logic. This will wait until [mutation.isConnectorComplete]{@link module:model.Mutation#isConnectorComplete} is \`true\` before doing so.`)
//   .done();
