import React, { createElement, useContext } from 'react';


const defaultGetBest = (rec) => rec;

function _renderFrom(logic, extraProps, getBest) {
  if (!logic) return null;
  const children = [];
  let component, props;
  while(logic) {
    logic = getBest(logic);
    if ((component = logic.component)) {
      props = extraProps
        ? { ...extraProps }
        : {};
      props.key = logic.__storeId;
      props.logic = logic;
      children.push(createElement(component, props));
    }
    logic = logic.next;
  }
  return children.length ? children : null;
}

function _renderOne(logic, extraProps, getBest) {
  const component = (logic = getBest(logic)) && logic.component;
  if (!component) return null;
  return createElement(component, extraProps
    ? { ...extraProps, key: logic.__storeId, logic }
    : { key: logic.__storeId, logic }
  )
}

function _renderMany(objOrArray, extraProps, getBest) {
  if (!objOrArray) return null;
  const children = Array.isArray(objOrArray)
    ? objOrArray
    : Object.values(objOrArray);
  let length = children.length;
  let i = 0;
  let component;
  while(i < length) {
    if ((component = _renderOne(children[i], extraProps, getBest))) {
      children[i] = component;
      i++;
    } else {
      children.splice(i, 1);
      length--;
    }
  }
  return children;
}

const _createValue = (dispatchId) => {
  let getBest;
  if (dispatchId) {
    getBest = (rec) => rec ? rec.getBestInstance(dispatchId) : null;
  } else {
    getBest = defaultGetBest;
  }
  return {
    dispatchId: dispatchId || null,
    from(logic, extraProps) {
      return _renderFrom(logic, extraProps, getBest);
    },
    one(logic, extraProps) {
      return _renderOne(logic, extraProps, getBest);
    },
    many(objOrArray, extraProps) {
      return _renderMany(objOrArray, extraProps, getBest);
    }
  };
}

const _defaultValue = _createValue(null);

export function createRenderContextValue(props) {
  if (props.dispatchId) {
    return _createValue(props.dispatchId);
  } else {
    return { ..._defaultValue };
  }
}

export const RenderContext = React.createContext(_defaultValue);

export function useRenderer() {
  return useContext(RenderContext);
}
