import React, { createElement } from 'react';
import {
  LayoutContainer as RmdLayoutContainer
} from '@isomorix/react-md-layout';

/*
 * This is using loadable components, but I went
 * a different direction so that the coreStore
 * can be provided via a closure to the graphs.
 */
// const Layout3D = loadable(() => import('./Layout3D'), {
//   resolveComponent: mod => mod.Layout3D,
//   ssr: false,
// })

let css;
let LayoutContainer;
if (process.env.BROWSER) {
  LayoutContainer = function LayoutContainer(props) {
    const {
      logic: {
        localProps: { appBuilderModule }
      }
    } = props;
    if (appBuilderModule) {
      const { DndProvider } = appBuilderModule;
      return (
        <DndProvider backend={appBuilderModule.HTML5Backend}>
          { createElement(RmdLayoutContainer, props) }
        </DndProvider>
      )
    } else {
      return createElement(RmdLayoutContainer, props);
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    let clientCss, useCss;
    try {
      clientCss = require('./_layout.scss');
      ({ useCss } = require('@isomorix/react-router'));
    } catch(e) {}
    const LayoutContainerProduction = LayoutContainer;
    LayoutContainer = function LayoutContainer(props) {
      useCss(clientCss);
      return createElement(LayoutContainerProduction, { ...props });
    }
  }
} else {
  try {
    css = require('./_layout.scss');
  } catch(e) {}
  LayoutContainer = RmdLayoutContainer;
}

export { LayoutContainer, css };
