import React, { useEffect, useState } from 'react';
import { TextContainer, Typography } from '@react-md/typography';
import { useRouterContext } from '@isomorix/react-router';
import { Button } from './components';
import { Button as RmdButton } from '@react-md/button';
import { MODEL_META_FIELDS } from '@isomorix/core-config';
import { forkJoin } from 'rxjs';
import { LinearProgress } from '@react-md/progress';
import { FontIcon, TextIconSpacing } from '@react-md/icon';

const { PERM_REC_READ } = MODEL_META_FIELDS;

function SwitchToRole(
  {
    session,
    userRole
  }
) {
  const { name } = userRole.role;
  return (
    <RmdButton
      key={`switch_to_${name}`}
      theme={'primary'}
      themeType={'contained'}
      onClick={() => session.switchToRole(userRole)}
      style={{ marginRight: '1rem', marginTop: '0.5rem' }}
      aria-label={`Switch to ${userRole.role.name} Role`}
    >
     { name }
    </RmdButton>
  )
}

const getRoleStyle = (session) => ({
  fontWeight: session.meta.themeType === 'light'
    ? 'bold'
    : undefined,
  color: 'var(--rmd-theme-secondary)'
})

// const getDeniedMsg = (docsStore, session) => docsStore
//   ? (
//       <Typography key={'docs_subhead'} type={'body-1'}>
//         Access to the documentation is restricted to those with the<br/> <span style={getRoleStyle(session)}>Partner</span> or <span style={getRoleStyle(session)}>Engineer role</span> while we iron out the last few details. <br/><br/>It will be available to everyone soon!
//       </Typography>
//     )
//   : session.userRoleId
//       ? (
//         <Typography key={'subhead-logged-in'} type={'body-1'}>
//           It looks like you don't have permission to access this page.
//         </Typography>
//       )
//      : (
//         <div key={'subhead'}>
//           <Typography key={'subhead-logged-in'} type={'body-1'}>
//             It looks like you don't have permission to access this page.
//           </Typography>
//           <Typography type={'headline-5'}>
//             Get notified of upcoming launch events & get instant access to prelaunch content!
//           </Typography>
//         </div>
//       );

const getDeniedMsg = (docsStore, session) => {
  if (docsStore) {
    return (
      <Typography key={'docs_subhead_login_register'} type={'headline-5'}>
        Get early access to the docs & get notified of upcoming launch events!
      </Typography>
    )
  } else {
    const child = (
      <Typography key={'subhead-message'} type={'body-1'}>
        It looks like you don't have permission to access this page.
      </Typography>
    );
    if (!session.userRoleId) {
      return (
        <div key={'subhead-container'}>
          { child }
          <Typography type={'headline-5'}>
            Get notified of upcoming launch events & get instant access to prelaunch content!
          </Typography>
        </div>
      )
    }
  }
}
export function PermissionDenied(
  {
    logic,
    docsStore
  }
) {
  const { session } = useRouterContext();
  const { userRole } = session;
  const [ loaded, setLoaded ] = useState(
    userRole
    && !userRole.user.isMissingRoles
  );
  useEffect(() => {
    if (loaded || !userRole) return;
    const sub = forkJoin([
      session.plugin.loadRoles(),
      userRole.user.loadRoles()
    ]).subscribe(() => setLoaded(true));
    return () => sub.unsubscribe();
  }, [ loaded, setLoaded, loaded, setLoaded, session, userRole ]);
  let children, route;
  if (!userRole) {
    children = [
      getDeniedMsg(docsStore, session),
      (
        <Button
          key={'login'}
          theme={'primary'}
          themeType={'contained'}
          style={{ marginTop: '0.5rem' }}
          searchOnly={true}
          to={{ user_login: true }}
        >
          <TextIconSpacing
            icon={<FontIcon>verified_user</FontIcon>}
          >
            Login/Register
          </TextIconSpacing>
        </Button>
      )
    ]
  } else if (docsStore || (logic && (route = logic.route))) {
    const { rolesData } = userRole.user;
    const permRead = docsStore && docsStore !== true && docsStore.get('permRead');
    let usrRole;
    for(let roleId in rolesData) {
      usrRole = rolesData[roleId];
      if (
        (permRead && permRead(usrRole))
        || docsStore
        || (
          !permRead
          && logic[PERM_REC_READ](usrRole, logic)
          && route.permUse(usrRole, logic)
        )
      ) {
        if (!children) {
          if (docsStore) {
            children = [ getDeniedMsg(docsStore, session) ];
          } else {
            children = [(
              <Typography key='subhead_wrong_role' type={'body-1'}>
                It looks like the <span style={getRoleStyle(session)}>{userRole.role.name} role</span> is not allowed to access this page.
              </Typography>
            )];
          }
          children.push(
            <Typography
              key={'switch_to_role_msg'}
              type={'body-1'}
            >
              Switch roles?
            </Typography>
          );
        }
        children.push(
          <SwitchToRole
            key={roleId}
            userRole={usrRole}
            session={session}
          />
        );
      }
    }
  }
  if (!children) {
    children = [ getDeniedMsg(docsStore, session) ];
    if (!loaded && userRole) {
      children.push(
        <Typography key={'loading_roles'} type={'body-1'}>
          Checking if you have roles that would allow access...
        </Typography>
      );
      children.push(<LinearProgress key={'linear_progress'} />);
    }
  }
  let headline;
  if (docsStore) {
    headline = `Login/Register`
  } else {
    headline = `${userRole ? userRole.user.firstName : 'Oops'}...`
  }
  return (
    <TextContainer>
      <Typography type={`headline-${docsStore ? '3' : '1'}`}>
        <TextIconSpacing
          icon={<FontIcon className={'h1-icon perm-denied'}>announcement</FontIcon>}
        >
          { headline }
        </TextIconSpacing>
      </Typography>
      { children }
    </TextContainer>
  );
}
