import React from 'react';
import { useRecordSubscription } from '@isomorix/react';
import {
  CORE_LOGIC,
  BKG_COLOR_DARK,
  BKG_COLOR_LIGHT
} from '../constants';
import {
  loadable,
  useRouterContext,
  getThemeType,
} from '@isomorix/react-router';
import { LinearProgress } from '@react-md/progress';
import { useLayoutContext } from '@isomorix/react-md-layout';
import { PermissionDenied } from '../PermissionDenied';

const ApiGraph3D = loadable(() => import('@isomorix/react-force-graph'), {
  resolveComponent: (mod) => {
    return mod.ApiGraph3D
  },
  ssr: false,
  fallback: <LinearProgress />
});

export const GraphqlForceGraph = React.memo(function GraphqlForceGraph(
  {
    logic
  }
) {
  useRecordSubscription(logic);
  const ref = useLayoutContext();
  const { session } = useRouterContext();
  const { match } = logic;
  if (!match) return null;
  if (logic.permDenied) {
    return <PermissionDenied logic={logic} />
  }
  const color = getThemeType(session) === 'light'
    ? BKG_COLOR_LIGHT
    : BKG_COLOR_DARK;
  return (
    <div style={{
      position: 'relative',
      overflow: 'hidden',
      width: ref.scrollWidth,
      height: ref.scrollHeight
    }}>
      <ApiGraph3D
        plugin={logic.routerLogic.plugin.pluginInstance}
        width={ref.scrollWidth}
        height={ref.scrollHeight}
        backgroundColor={color}
      />
    </div>
  )
});

GraphqlForceGraph.displayName = CORE_LOGIC.GRAPHQL_FORCE_GRAPH_ROUTE;
