let docsData;
try {
  docsData = require('../../jsdocData/features/query.json');
} catch (e) {
  docsData = { links: {} };
}
export const query = (function(){
  const query = {
    name: 'query',
    headline: [
      'Queries That Are So Powerful & Intuitive',
      {
        children: `You'll Feel Like It's Cheating`,
        color: 'theme-secondary'
      }
    ],
    subHeadline: [
      'PLUS: Subscribe to',
      { children: 'any', fontStyle: 'italic' },
      'query to get real-time updates - nothing additional to configure!'
    ],
    video: {
      url: 'https://dev-39.wistia.com/medias/jlz47l2obs',
      preview: '/static/img/vid/breakout_query_preview.webp',
      totalSeconds: 35,
    },
  };
  const { list } = docsData;
  const panels = [];
  for(let item of list) {
    panels.push({
      header: {
        icon: item.icon,
        avatarColor: item.color,
        text: item.label,
      },
      list: item.children,
    });
  }
  query.panels = panels;
  return query;
})();


