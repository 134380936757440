import { upperCaseFirstLetter } from '@isomorix/utils';

/**
 * Creates the best title to use for the
 * [current location]{@link module:core/location.Record}.
 *
 * It first attempts to use the [location's title property]{@link module:core/location.Record#title}.
 *
 * If that is not set, it will create the title using
 * the [location's pathname property]{@link module:core/location.Record#pathname}
 * by splitting on `/` and combining using ` - `.
 * @function getBestTitle
 * @memberof module:ui-router
 * @param {module:core/location.Record} location - The
 * [Core location record]{@link module:core/location.Record}
 * that is representing the location for the app.
 * @param {string} [base] - An optional "base" value
 * for the title. For example, the name of the app.
 *
 * When provided, it will be *prepended* to the title
 * if the title is derived using the
 * [location's pathname property]{@link module:core/location.Record#pathname},
 * otherwise it will only be prepended to the title
 * if `forceBase === true`.
 * @param {boolean} [forceBase = false] - If `true`,
 * the `base` will also be *prepended* to the
 * [location's title property]{@link module:core/location.Record#title},
 * if that is set.
 *
 * The default is to use the location's title as-is.
 * @returns {string}
 * The title
 */
export function getBestTitle(location, base, forceBase) {
  let title = location.title;
  if (title) {
    return forceBase ? `${base} - ${title}` : title;
  }
  const titleParts = location.pathname.split('/');
  title = base || '';
  let subParts;
  for(let part of titleParts) {
    subParts = part.split('-');
    if (subParts.length === 1 && !subParts[0]) continue;
    if (title) {
      title += ` -`;
    }
    for(let i = 0; i < subParts.length; i++) {
      title += ` ${upperCaseFirstLetter(subParts[i])}`;
    }
  }
  return title;
}
