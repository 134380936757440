/**
 * @module core-ui/constants/logicIds
 */
import { PKG } from './pkg';

/**
 * @constant module:core-ui/constants/logicIds.GRAPHQL_FORCE_GRAPH_ROUTE
 * @type {string}
 * @default graphql_force_graph_route
 */
const GRAPHQL_FORCE_GRAPH_ROUTE = 'graphql_force_graph_route';


/**
 * @constant module:core-ui/constants/logicIds.GRAPHQL_PLAYGROUND_ROUTE
 * @type {string}
 * @default graphql_playground_route
 */
const GRAPHQL_PLAYGROUND_ROUTE = 'graphql_playground_route';

/**
 * @constant module:core-ui/constants/logicIds.GRAPHQL_VOYAGER_ROUTE
 * @type {string}
 * @default graphql_voyager_route
 */
const GRAPHQL_VOYAGER_ROUTE = 'graphql_voyager_route';

/**
 * @constant module:core-ui/constants/logicIds.APP_BUILDER_ROUTE
 * @type {string}
 * @default core_ui_app_builder_route
 */
const APP_BUILDER_ROUTE = 'app_builder_route';

/**
 * @constant module:core-ui/constants/logicIds.DOCS_ROUTE
 * @type {string}
 * @default core_ui_docs_route
 */
const DOCS_ROUTE = 'docs_route';

/**
 * @constant module:core-ui/constants/logicIds.LAYOUT
 * @type {string}
 * @default core_ui_layout
 */
const LAYOUT = 'layout';

/**
 * @constant module:core-ui/constants/logicIds.ROUTER
 * @type {string}
 * @default core_ui_router
 */
const ROUTER = 'router';

/**
 * @constant module:core-ui/constants/logicIds.USER_ROUTE
 * @type {string}
 * @default core_user_route
 */
const USER_ROUTE = 'user_route';


const CORE_LOGIC = {
  // API_ROUTE,
  // API_GRAPH_ROUTE,
  // API_PLAYGROUND_ROUTE,

  GRAPHQL_FORCE_GRAPH_ROUTE,
  GRAPHQL_PLAYGROUND_ROUTE,
  GRAPHQL_VOYAGER_ROUTE,
  APP_BUILDER_ROUTE,
  DOCS_ROUTE,
  LAYOUT,
  ROUTER,
  USER_ROUTE
}

for(let key in CORE_LOGIC) {
  CORE_LOGIC[key] = `${PKG.SLUG}_${CORE_LOGIC[key]}`;
}

export { CORE_LOGIC };

// /**
//  * @constant module:core-ui/constants/logicIds.API_ROUTE
//  * @type {string}
//  * @default core_ui_api_route
//  */
// const API_ROUTE = 'api_route';
//
// /**
//  * @constant module:core-ui/constants/logicIds.API_GRAPH_ROUTE
//  * @type {string}
//  * @default core_ui_api_route
//  */
// const API_GRAPH_ROUTE = 'api_graph_route';
//
// /**
//  * @constant module:core-ui/constants/logicIds.API_PLAYGROUND_ROUTE
//  * @type {string}
//  * @default core_ui_api_route
//  */
// const API_PLAYGROUND_ROUTE = 'api_playground_route';


