import React, { createElement } from 'react';
import { ExpansionPanel as RmdPanel } from '@react-md/expansion-panel';
import { ViewModeButton } from '@isomorix/react-md-layout';
import {
  Button,
  Text,
} from '../../components';
import { List } from './List';
import { bem } from '../helpers';
import { ExpansionHeader } from './ExpansionHeader';
import cn from 'classnames';

const components = {
  Button,
  ViewModeButton,
  Text,
  List,
}

const block = bem('expansion-panel');

export const ExpansionPanel = React.memo(function ExpansionPanel(
  {
    panel,
    location,
    ...props
  }
) {
  let children;
  const { header, content } = panel;
  if (content) {
    children = [];
    let Component, i = 0;
    for(let item of content) {
      Component = item.component;
      children.push(createElement(Component, { ...item.props, key: i }));
      i++;
    }
  } else if (panel.text) {
    const { text } = panel;
    let i = 0;
    children = [];
    for(let t of text) {
      if (typeof t === 'string' || Array.isArray(t)) {
        children.push(
          <Text
            key={i}
            type={'body-1'}
            children={t}
          />
        );
      } else if (t.props) {
        // Custom component, like a Button.
        children.push(createElement(
          typeof t.component === 'string'
            ? components[t.component] || t.component
            : t.component,
          { ...t.props, key: `${i}_component`})
        );
      } else {
        // Custom Text props
        children.push(createElement(
          Text,
          { ...t, key: `${i}_text` }
        ));
      }
      i++;
    }
  } else if (panel.list) {
    children = Array.isArray(panel.list)
      ? <List key={'list'} data={panel.list} />
      : createElement(List, { ...panel.list, key: 'list' });
  }
  return (
    <RmdPanel
      header={<ExpansionHeader header={header} />}
      { ...props }
      className={cn(block(), props.className)}
      contentClassName={block('content')}
      // disablePadding={true}
    >
      <div
        id={`${props.id}-scrollable-container`}
        className={`styled-scrollbars ${block('content__scrollable')}`}
      >
        <div className={block('content__scrollable--children')}>
          { children }
        </div>
      </div>
    </RmdPanel>
  )
})
