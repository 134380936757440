import React from 'react';
import { useFormContext } from './FormContext';
import { Button } from '@react-md/button';
import { TextIconSpacing, FontIcon } from '@react-md/icon';
import { CircularProgress } from '@react-md/progress';

export function SubmitButton({
  Container,
  containerProps,
  leftIcon,
  text,
  submittingText,
  progressOnSubmit,
  ...props
}) {
  const context = useFormContext();
  const theme = context.errorCount || context.submitting
    ? 'disabled'
    : props.theme;
  let children;
  if (context.submitting && progressOnSubmit) {
    children = (
      <TextIconSpacing
        key={1}
        icon={<CircularProgress style={{ marginRight: '1rem' }}/>}
      >
        { submittingText || text }
      </TextIconSpacing>
    );
  } else if (leftIcon) {
    children = (
      <TextIconSpacing
        key={1}
        icon={<FontIcon>{leftIcon}</FontIcon>}
      >
        { text }
      </TextIconSpacing>
    )
  } else {
    children = text;
  }
  const button = (
    <Button
      type={'submit'}
      onClick={context.onSubmit}
      { ...props }
      theme={theme}
    >
      { children }
    </Button>
  )
  if (Container === null) {
    return button;
  }

  if (!Container) {
    Container = context.FieldContainer;
    if (!containerProps) {
      containerProps = { ...context.fieldContainerProps }
    }
  } else if (!containerProps) {
    containerProps = {};
  }
  return (
    <Container { ...containerProps }>
      { button }
    </Container>
  )
}
