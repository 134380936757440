import { routerLogicMgr as logicMgr } from './logicMgr';
import { asyncLogicResp } from '@isomorix/store';
import { MUTATION } from '@isomorix/core-actions';
import { DOCS_URL_SEARCH as SEARCH } from '@isomorix/core-config';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


if (process.env.BROWSER) {
  const builder = logicMgr.getBuilder(MUTATION, './mutation');
  function loadLayout3D(action) {
    const { localProps } = action.meta.instance;
    // no local props would mean we're being deleted.
    if (!localProps || localProps.viewMode !== '3D') {
      return action;
    }
    const { meta } = action;
    meta.removeLogic(meta.getCurrentLogic(), 'all', 'all');
    if (!localProps.createLayout3DStore) {
      const main = action.meta.store.mainStore.instance;
      forkJoin({
        mutation: action.payload.mutation.ofComplete(true),
        module: localProps.loadLayout3DModule()
      }).subscribe(({ module }) => {
        main.setLocalProps({
          createLayout3DStore: module.createLayout3DStore,
          Layout3D: module.Layout3D
        });
      });
    }
    return action;
  }
  builder.useRelative('trxPrepare', 10)
    .setName('loadLayout3D')
    .setPure(true)
    .setDescription(`Client-only logic that handles loading the Layout3D module when the viewMode is changed to 3D. Once this occurs the first time, this logic will be removed.`)
    .add(loadLayout3D, true);


  function updateDocsMatch(action, observer) {
    const logic = action.meta.instance;
    let { localProps, match } = logic;
    // no local props would mean we're being deleted.
    if (!localProps) {
      if ((localProps = action.meta.store.value.getPrev('localProps'))) {
        if (localProps.layout3DStore) {
          localProps.layout3DStore.complete();
        }
        if (localProps.routesMgr) {
          localProps.routesMgr.destroy();
        }
        if (localProps.docsMutationLogic) {
          return localProps.docsMutationLogic(action, observer);
        }
      }
      return asyncLogicResp(null, action, observer);
    }
    const searchParams = match && match.searchParams;
    if (searchParams && searchParams[SEARCH.PATHNAME]) {
      const { docsMutationLogic } = localProps;
      if (!docsMutationLogic) {
        return localProps.loadDocsModule(action).pipe(
          mergeMap(module => {
            const localProps = logic.getMutableLocalProps();
            localProps.createDocsStore = module.createPortalStore;
            localProps.docsMutationLogic = module.mutationLogic;
            localProps.docsCss = module.css;
            localProps.docsTemplateCss = module.templateCss;
            localProps.DocsSearchButton = module.SearchButton;
            const docsStore = module.createPortalStore(action);
            localProps.docsStore = docsStore;
            docsStore.value.commit();
            logic.localProps = localProps;
            return localProps.docsMutationLogic(action);
          })
        )
      } else {
        return docsMutationLogic(action, observer);
      }
    } else if (
      localProps.docsStore
      && localProps.docsStore.get('portalVisible')
    ) {
      return localProps.docsMutationLogic(action, observer);
    } else {
      return asyncLogicResp(null, action, observer);
    }
  }
  builder.useRelative('trxOptimisticExecute', 10)
    .setName('updateDocsMatch')
    .setPure(false)
    .add(updateDocsMatch, true);
}
