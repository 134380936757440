const descend = (logic, allLogic) => {
  const { children } = logic.route;
  let childLogic;
  for(let id in children) {
    if ((childLogic = allLogic[id]) && childLogic.match) {
      return descend(childLogic, allLogic);
    }
  }
  return logic;
}

/**
 * Determines the [CoreLogic record]{@link module:core/coreLogic.Record}
 * that is managing the
 * [Route]{@link module:core/route.Record} that
 * is the best match for the current
 * [location]{@link module:core/location.Record}.
 *
 * This is most useful for things like determining which
 * link to highlight in an app's main navigation.
 * @function getBestMatch
 * @memberof module:ui-router
 * @param {module:core/coreLogic.Record} routerLogic - The
 * [CoreLogic record]{@link module:core/coreLogic.Record}
 * that is managing the routes associated with the plugin.
 * @returns {module:core/coreLogic.Record}
 * The CoreLogic record that is managing the
 * [Route]{@link module:core/route.Record} that
 * is the best match for the current
 * [location]{@link module:core/location.Record}.
 */
export function getBestMatch(routerLogic) {
  const allLogic = routerLogic.routeLogic;
  if (!allLogic) return routerLogic;
  let logic = descend(routerLogic, allLogic);
  if (!logic || logic === routerLogic) {
    return routerLogic;
  }
  /*
   * Make sure it defines a path. There could be routes that
   * only listen for searchParams. For example,
   * a "user" route to show a login form. It
   * would always be returned as the match
   * for the home page when in reality it's
   * not really its own route.
   */
  const path = logic.route.path;
  return path && path !== '/'
    ? logic
    : logic.parent || routerLogic;
}
