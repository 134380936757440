import React, {
  createElement,
  useCallback,
  useState
} from 'react';
import { useRouterContext } from '@isomorix/react-router';
import {
  Dialog,
  DialogHeader,
  DialogContent,
} from '@react-md/dialog';
import { Button } from '@react-md/button';
import {
  TabsManager,
  TabPanel,
  TabPanels,
  Tabs
} from '@react-md/tabs';
import { FontIcon } from '@react-md/icon';
import { useAppSize } from '@react-md/utils';
import { dialogCloseIcon, dialogStyle } from './inlineStyles';
import { LoginForm } from './LoginForm';
import { RegisterForm } from './RegisterForm';

const emptyObj = {};

export function LoginDialog(
  {
    onComplete,
    dialogProps = emptyObj,
    loginFormProps = emptyObj,
    registerFormProps = emptyObj,
  }
) {
  const { session } = useRouterContext();
  const onClose = useCallback(() => {
    if (onComplete) {
      onComplete(session);
    } else {
      session.location.pushSearch({ user_login: undefined });
    }
  }, [ session, onComplete ]);
  const appSize = useAppSize();
  const onActiveIndexChange = useCallback((activeIndex) => {
    session.location.pushSearch({
      user_login: activeIndex === 0 ? true : 'register'
    });
  }, [ session ]);
  const searchParams = session.location.searchParams;
  const activeIndex = searchParams && searchParams.user_login === 'register'
    ? 1
    : 0;
  const children = (
    <TabsManager
      key={'tabsManager'}
      tabs={[{
        icon: <FontIcon>login</FontIcon>,
        children: "Login"
      }, {
        icon: <FontIcon>how_to_reg</FontIcon>,
        children: "Register"
      }]}
      activeIndex={activeIndex}
      onActiveIndexChange={onActiveIndexChange}
      tabsId={'rmd-dialog-login-register-tabs'}
    >
      <DialogHeader>
        <Tabs />
        <Button
          buttonType={'icon'}
          style={dialogCloseIcon}
          onClick={onClose}
        >
          <FontIcon>close</FontIcon>
        </Button>
      </DialogHeader>
      <DialogContent>
        <TabPanels persistent={true}>
          <TabPanel>
            <LoginForm
              session={session}
              onComplete={onComplete}
              isVisible={activeIndex === 0}
              { ...loginFormProps }
            />
          </TabPanel>
          <TabPanel>
            <RegisterForm
              session={session}
              onComplete={onComplete}
              isVisible={activeIndex === 1}
              { ...registerFormProps }
            />
          </TabPanel>
        </TabPanels>
      </DialogContent>
    </TabsManager>
  );
  const dProps = {
    id: 'login-dialog',
    appear: true,
    visible: true,
    onRequestClose: onClose,
    'aria-labelledby': "login-dialog-title",
    portal: false,
    type: appSize.isPhone ? 'full-page' : 'centered',
    ...dialogProps,
  };
  if (
    !appSize.isPhone
    && !dProps.className
    && (!dProps.style || !dProps.style.minWidth)
  ) {
    if (dProps.style) {
      dProps.style.minWidth = '40rem';
    } else {
      dProps.style = dialogStyle;
    }
  }
  return createElement(Dialog, dProps, children);
}
