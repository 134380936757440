import React, {
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { useResizeObserver } from '@react-md/utils';

const _defaultValue = (function() {
  const value = {
    render2D: true,
    render3D: false,
    viewMode: '2D',
  }
  if (typeof window === 'undefined') {
    value.width = 1920;
    value.height = 969;
  } else {
    value.width = window.innerWidth;
    value.height = window.innerHeight;
  }
  value.scrollWidth = value.width;
  value.scrollHeight = value.height;
  return value;
})();

export const LayoutContext = React.createContext(_defaultValue);

export function useLayoutContext() {
  return useContext(LayoutContext);
}

const defaultBase = {};

const [
  _rootSize,
  _size,
] = (function() {
  const root = {};
  if (typeof window === 'undefined') {
    root.width = 1920;
    root.height = 969;
  } else {
    root.width = window.innerWidth;
    root.height = window.innerHeight;
  }
  root.scrollWidth = root.width;
  root.scrollHeight = root.height;
  const base = {
    width: root.width - 24,
    height: root.height - 48,
  };
  base.scrollWidth = base.width;
  base.scrollHeight = base.height;
  return [ root, base ];
})();

export function useLayoutContextProvider(baseValue = defaultBase, resizeOptions) {
  const { isRoot = false } = baseValue;
  const [ size, setSize ] = useState(isRoot ? _rootSize : _size);
  const onResize = useCallback(size => {
    if (isRoot) {
      const elem = document.documentElement;
      setSize(prev => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        if (prev.width === width && prev.height === height) {
          return prev;
        }
        return {
          ...prev,
          elem,
          width,
          height
        };
      })
    } else {
      setSize(size);
    }
  }, [ setSize, isRoot ]);
  const [ ref, refHandler ] = useResizeObserver(onResize, resizeOptions);
  const value = useMemo(() => ({
    ...baseValue,
    ...size,
    isRoot: !!baseValue.isRoot,
  }), [ size, baseValue ]);
  return {
    ref,
    refHandler,
    value,
  }
}

export function useLayoutContextDefaultRootProvider(logic, themeType) {
  const {
    localProps: {
      viewMode,
      render2D,
      render3D,
    }
  } = logic;
  const baseValue = useMemo(() => ({
    viewMode,
    render2D,
    render3D,
    themeType,
    isRoot: true,
    toggleViewMode: () => {
      if (!render2D || !render3D) {
        logic.setLocalProps({
          viewMode: viewMode === '2D' ? '3D' : '2D',
          render2D: true,
          render3D: true
        })
      }
    }
  }), [ viewMode, render2D, render3D, themeType, logic ]);
  return useLayoutContextProvider(baseValue);
}

export const LayoutContextProvider = React.memo(function LayoutContextProvider(
  {
    baseValue = _size,
    ...componentProps
  }
) {
  const { refHandler, value } = useLayoutContextProvider(baseValue);
  return (
    <LayoutContext.Provider value={value}>
      <div { ...componentProps } ref={refHandler}/>
    </LayoutContext.Provider>
  );
});
