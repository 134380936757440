import React, { useRef } from 'react';
import { FontIcon, TextIconSpacing } from '@react-md/icon';
import { useFlames } from '../../hooks';
import { bem } from '../helpers';
import { Avatar } from '@react-md/avatar';

const block = bem('expansion-header');

const render = (header, flames, ref) => {
  let icon;
  if (header.icon) {
    if (header.avatarColor) {
      icon = (
        <Avatar
          ref={ref}
          className={block('avatar')}
          color={header.avatarColor}
        >
          { flames }
          <FontIcon className={block('icon')}>
            { header.icon }
          </FontIcon>
        </Avatar>
      )
    } else {
      icon = (
        <FontIcon ref={ref} className={block('icon')}>
          { flames }
          { header.icon }
        </FontIcon>
      )
    }
  } else if (header.avatar) {
    icon = (
      <Avatar
        ref={ref}
        className={block('avatar')}
        color={header.avatarColor}
      >
        { flames }
        { header.avatar }
      </Avatar>
    )
  }
  const text = (
    <span className={block('text', {
      fire: !!flames
    })}>
      { header.text }
    </span>
  );
  if (icon) {
    return (
      <TextIconSpacing
        className={block({
          fire: !!flames
        })}
        icon={icon}
      >
        { text }
      </TextIconSpacing>
    )
  } else {
    return text;
  }
}

function HeaderFlames(
  {
    header
  }
) {
  const ref = useRef();
  const flames = useFlames(ref);
  return render(header, flames, ref);
}

export const ExpansionHeader = React.memo(function ExpansionHeader(
  {
    header
  }
) {
  return header.flames
    ? <HeaderFlames header={header} />
    : render(header);
});
