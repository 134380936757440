import React, {
  useRef,
  useLayoutEffect,
  createElement,
} from 'react';
import { useCss, getThemeType } from '@isomorix/react-router';
import {
  LayoutContext,
  useLayoutContextDefaultRootProvider
} from './LayoutContext';
import { Scanner } from './Scanner';

/*
 * Closes out the 3D Dashboard store once the
 * transition is complete. This would mean the
 * store gets recreated each time.
 *
 * If this is used, also modify the docs-chunk's
 * `mutationLogic` to set the store to `null`.
 * The process for that are still there, just
 * uncomment them.
 */
function closeLayout3DStore(logic) {
  const { localProps } = logic;
  if (
    localProps.clearLayout3DStoreOnClose
    && !localProps.render3D
    && localProps.layout3DStore
  ) {
    const { layout3DStore } = localProps;
    setTimeout(() => {
      logic.setLocalProps({ layout3DStore: null });
    }, 5);
    setTimeout(() => {
      layout3DStore.complete();
    }, 500);
  }
}

let useBrowser;
if (process.env.BROWSER) {
  useBrowser = function(props, children) {
    const { logic } = props;
    const { localProps } = logic;
    const {
      render2D,
      render3D,
      Layout3D,
    } = localProps;
    const scrollPos = useRef(false);
    /*
     * Capture current scroll if this is the
     * beginning of the transition. This is
     * done here, rather than in something like
     * the `ViewModeFAB` so that transitions can
     * be triggered programmatically, while
     * scroll restoration remains consistent.
     */
    if (render2D && render3D && !scrollPos.current) {
      scrollPos.current = true;
      logic.getLocation()?.saveScrollPositions();
    }
    useLayoutEffect(() => {
      if (!render2D || !render3D) {
        scrollPos.current = false;
        /*
         * It is only necessary to restore positions
         * when coming out of 3D, since the AppNode
         * will handle it going into 3D.
         * See @isomx/core-fg/shared/nodes/AppNode,
         * its threeObject getter.
         */
        if (render2D) {
          logic.getLocation().restoreScrollPositions();
        }
      }
    }, [ render2D, render3D, scrollPos, logic ]);
    if (render2D) {
      closeLayout3DStore(logic);
    }
    if (render3D && Layout3D) {
      children.push(createElement(Layout3D, {
        ...props,
        key: 'layout3D',
        is2D: false,
      }));
    }
    return children;
  }
} else {
  useBrowser = function(props, children) {
    return children;
  }
}

export function useLayouts(props) {
  const children = [];
  const { logic: { localProps } } = props;
  if (localProps.render2D) {
    const { Layout } = localProps;
    let className = 'rmd-layout-2D';
    if (localProps.render3D) {
      className += ' exit';
    }
    children.push(
      <div key={'layout2D'} className={className}>
        <Layout { ...props } key={'layout2D'} is2D={true} />
      </div>
    );
  }
  useBrowser(props, children);
  return children;
}

export const LayoutContainerWithCss = (css) => function LayoutWrapper(props) {
  useCss(css);
  return <LayoutContainer { ...props } />;
}

export function LayoutContainer(props) {
  const children = useLayouts(props);
  const { value, refHandler } = useLayoutContextDefaultRootProvider(
    props.logic,
    getThemeType(props.session)
  );
  return (
    <LayoutContext.Provider value={value}>
      <Scanner
        render2D={value.render2D}
        render3D={value.render3D}
        viewMode={value.viewMode}
      />
      <div ref={refHandler} className={'rmd-layout-container'}>
        { children }
      </div>
    </LayoutContext.Provider>

  );
};
