import React, { useCallback, useEffect, useState } from 'react';
import { useAddMessage } from '@react-md/alert';
import { Grid, GridCell } from '@react-md/utils';
import { Form } from '@react-md/form';
import { CORE_DIRECTIVES } from '@isomorix/core-config';
import {
  FormController,
  TextField,
  Password,
  SubmitButton
} from '@isomorix/react-md-form';
import { Typography } from '@react-md/typography';
import { defaultSubmitStyle } from './inlineStyles';
import { FontIcon } from '@react-md/icon';

export const LoginForm = React.memo(function LoginForm(
  {
    session, // required, all remaining are optional
    onComplete,
    beforeChildren,
    beforeSubmitChildren,
    afterSubmitChildren,
    submitButton,
    isVisible
  }
) {
  const { plugin } = session;
  const {
    directives: {
      [CORE_DIRECTIVES.LOGIN_LOCAL_STRATEGY]: directive
    }
  } = plugin;
  const addMessage = useAddMessage();
  const [ error, setError ] = useState(null);
  useEffect(() => {
    if (!isVisible) return;
    const elem = document.getElementById('login-email');
    if (elem) {
      // delay in case this is a location pop
      setTimeout(() => elem.focus(), 5);
    }
  }, [ isVisible ]);
  const onSubmit = useCallback((state, setState) => {
    state.submitting = true;
    setState(state);
    session.login(state.values).subscribe(session => {
      if (!session || session instanceof Error) {
        setState({ ...state, submitting: false });
        setError('Oops! An unknown error occurred.');
      } else if (!session.userRoleId) {
        setState({ ...state, submitting: false });
        setError(`The email or password is not valid.`);
      } else {
        const { userRole } = session;
        addMessage({
          children: `Welcome, ${userRole.user.firstName}! You have been logged in with the ${userRole.role.name} role.`
        });
        if (onComplete) {
          onComplete(session);
        } else {
          session.location.replaceSearch({ user_login: undefined });
        }
      }
    })
  }, [ session, addMessage, onComplete ]);
  const { args } = directive;
  let errorComponent;
  if (error) {
    errorComponent = (
      <GridCell colSpan={12}>
        <Typography
          type={'body-1'}
          className={'rmd-label--error'}
        >{ error }</Typography>
      </GridCell>
    )
  }
  if (!submitButton) {
    submitButton = (
      <SubmitButton
        id={'dialog-close'}
        style={defaultSubmitStyle}
        text={'Login'}
        submittingText={'Logging in...'}
        progressOnSubmit={true}
        leftIcon={'gpp_good'}
        theme={'primary'}
        themeType={'contained'}
      />
    )
  }
  return (
    <FormController fields={args} onSubmit={onSubmit}>
      <Form>
        <Grid>
          { beforeChildren }
          {errorComponent}
          <TextField
            id={'login-email'}
            autoComplete={'email'}
            name={'email'}
            label={'Email'}
            type={'email'}
            rightChildren={<FontIcon>email</FontIcon>}
          />
          <Password
            id={'login-password'}
            autoComplete={'current-password'}
            name={'password'}
            label={'Password'}
            type={'password'}
          />
          { beforeSubmitChildren }
          { submitButton }
          { afterSubmitChildren }
        </Grid>
      </Form>
    </FormController>

  )
})
