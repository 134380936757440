import React, { useMemo } from 'react';
import Countdown from 'react-component-countdown-timer';

export const LaunchTimerRender = React.memo(function LaunchTimerRender(

) {
  const count = useMemo(() => {
    const now = new Date();
    const future = new Date('February 01, 2023 08:00:00');
    return Math.abs(now.getTime() - future.getTime()) / 1000;
  }, []);
  return (
    <div className={'launch-timer-container'}>
      <div className={'root'}>
        <Countdown
          count={count}
          showTitle={true}
          border={true}
          color={'#fff'}
          backgroundColor={'rgba(0, 0, 0, 0)'}
          direction={'right'}
          responsive={true}
          size={16}
          className={'launch-timer'}
        />
      </div>
    </div>
  )
})
