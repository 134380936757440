/**
 * @module react-router/constants/logicIds
 */
import { PKG } from './pkg';

/**
 * @constant module:react-router/constants/logicIds.ROUTER
 * @type {string}
 * @default react_router_router
 */
const ROUTER = 'router';

/**
 * @constant module:react-router/constants/logicIds.ROUTE
 * @type {string}
 * @default react_router_route
 */
const ROUTE = 'route';

const CORE_LOGIC_IDS = {
  ROUTER,
  ROUTE
};

for(let key in CORE_LOGIC_IDS) {
  CORE_LOGIC_IDS[key] = `${PKG.SLUG}_${CORE_LOGIC_IDS[key]}`;
}

export { CORE_LOGIC_IDS };

