// import { pluginLogicMgr } from "@isomorix/plugin";
// import { LogicMgr } from "@isomorix/store";
import { CoreLogicMethods } from '@isomorix/core-logic';

// /**
//  * Contains the Plugin logic
//  * for the meilisearch implementation.
//  * @name logicMgr
//  * @memberof module:meilisearch
//  * @type {module:store.LogicMgr}
//  */
// export const logicMgr = LogicMgr
//   .initModule('@isomorix/meilisearch/logic')
//   .addSource(pluginLogicMgr);


/**
 * The LogicMgr instance that contains
 * logic that will be registered as methods
 * in the [CoreLogicMethod enum]{@link module:core/enums.CoreLogicMethod}.
 * @name coreLogicMethods
 * @memberof module:meilisearch
 * @type {module:store.LogicMgr}
 */
export const coreLogicMethods = CoreLogicMethods
  .initModule('@isomorix/meilisearch/routerLogic');
