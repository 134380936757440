import React from 'react';
import { useRecordSubscription } from '@isomorix/react';
import { CORE_LOGIC } from '../constants';
import { PermissionDenied } from '../PermissionDenied';

export const DocsRoute = React.memo(function DocsRoute(props) {
  const { logic } = props;
  useRecordSubscription(logic);
  const { match } = logic;
  if (!match) return null;
  if (logic.permDenied) {
    return <PermissionDenied docsStore={true} logic={logic} />
  }
  const { localProps: { docsStore } } = logic;
  const Container = docsStore.get('Container');
  return <Container key='docsContainer' store={docsStore} />;
});

DocsRoute.displayName = CORE_LOGIC.DOCS_ROUTE;
