import React, { createElement } from 'react';
import { Typography } from '@react-md/typography';
import { Link } from './Link';
import { DocsDialogLink } from './DocsDialogLink';


export const Text = React.memo(function Text(
  {
    children: propsChildren,
    to,
    isDocsLink = false,
    linkProps,
    isPanel,
    ...props
  }
) {
  const { type } = props;
  const isTextChild = typeof propsChildren === 'string';
  let children = [];
  if (
    props.decoration
    && (
      isTextChild
      || (
        propsChildren.length === 1
        && typeof propsChildren[0] === 'string'
      )
    )
  ) {
    const parts = isTextChild
      ? propsChildren.split(' ')
      : propsChildren[0].split(' ');
    const { decoration } = props;
    props.decoration = undefined;
    for(let i = 0; i < parts.length; i++) {
      if (i > 0) {
        children.push(" ");
      }
      children.push(
        createElement(Typography, {
          key: i,
          type,
          component: 'span',
          decoration,
        }, parts[i])
      )
    }
  } else if (isTextChild) {
    children.push(propsChildren);
  } else {
    if (!Array.isArray(propsChildren)) {
      propsChildren = [ propsChildren ];
    }
    let child;
    for(let i = 0; i < propsChildren.length; i++) {
      if (i > 0) {
        children.push(' ');
      }
      child = propsChildren[i];
      if (typeof child === 'string') {
        children.push(child);
      } else {
        children.push(
          createElement(Text, {
            type,
            component: 'span',
            key: i,
            ...child,
            suffix: undefined,
          })
        );
        if (child.after || child.suffix) {
          children.push(child.after || child.suffix);
        }
      }
    }
  }
  if (to || linkProps) {
    const Component = isDocsLink ? DocsDialogLink : Link;
    if (!linkProps) {
      linkProps = { to };
    } else if (to) {
      linkProps = { ...linkProps, to };
    }
    children = createElement(Component, linkProps, children);
  }
  return createElement(Typography, props, children);
})
