import { of } from 'rxjs';

const isBrowser = process.env.BROWSER;

export function getThemeType(session) {
  return session.meta.themeType;
}

export function findThemeType(session, orPreferred) {
  if (isBrowser) {
    const plugin = session.plugin.pluginInstance;
    let themeType = plugin.localStorage.getItem('theme');
    if (
      !themeType
      && !(themeType = plugin.Cookie.get('theme'))
      && orPreferred
    ) {
      themeType = (
        typeof window !== 'undefined'
        && window.matchMedia
        && window.matchMedia('(prefers-color-scheme: dark)').matches
      ) ? 'dark' : 'light';
    }
    return themeType || undefined;
  } else {
    const req = session.request;
    return req && req.cookies
      ? req.cookies[`${session.plugin.slug}__theme`] || undefined
      : undefined;
  }
}

export function setThemeType(session, themeType) {
  if (isBrowser) {
    const plugin = session.plugin.pluginInstance;
    plugin.localStorage.setItem('theme', themeType);
    plugin.Cookie.set('theme', themeType);
  }
  const meta = session.meta;
  if (meta.themeType !== themeType) {
    return session.setState({ meta: { ...meta, themeType } });
  } else {
    return of(session);
  }
}

export function toggleThemeType(session) {
  return getThemeType(session) === 'light'
    ? setThemeType(session, 'dark')
    : setThemeType(session, 'light');
}

