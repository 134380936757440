import React, { createElement } from 'react';
import { GridCell, useAppSize } from '@react-md/utils';
import cn from 'classnames';

const phone = { colSpan: 4 };
const tablet = { colSpan: 8 };

const leftSmall = {
  desktop: {
    colStart: 1,
    colEnd: 6
  },
  largeDesktop: {
    colStart: 2,
    colEnd: 6,
  },
  phone,
  tablet,
};
leftSmall.largeDesktop = leftSmall.desktop;
const leftLarge = {
  desktop: {
    colStart: 1,
    colEnd: 8
  },
  largeDesktop: {
    colStart: 2,
    colEnd: 8,
  },
  phone,
  tablet
};
leftLarge.largeDesktop = leftLarge.desktop;

const rightLarge = {
  desktop: {
    colStart: 6,
    colEnd: 13,
  },
  largeDesktop: {
    colStart: 6,
    colEnd: 12,
  },
  phone,
  tablet,
};
rightLarge.largeDesktop = rightLarge.desktop;

const rightSmall = {
  desktop: {
    colStart: 8,
    colEnd: 13,
  },
  largeDesktop: {
    colStart: 8,
    colEnd: 12,
  },
  phone,
  tablet,
};
rightSmall.largeDesktop = rightSmall.desktop;

const gridProps = {
  leftSmall,
  leftLarge,
  rightSmall,
  rightLarge
}

const style = {
  position: 'relative'
}

export const GridCellSplit = React.memo(function GridCellSplit(
  {
    pos,
    rightLarge = false,
    large,
    small,
    smallClassName,
    largeClassName,
    leftClassName,
    rightClassName,
    children
  }
) {
  const appSize = useAppSize();
  if (appSize.isPhone || appSize.isTablet) {
    return (
      <>
        <GridCell
          { ...gridProps.leftSmall }
          className={leftClassName}
          style={style}
        >
          { small }
        </GridCell>
        <GridCell
          { ...gridProps.rightLarge }
          className={rightClassName}
          style={style}
        >
          { large }
        </GridCell>
      </>

    )
  } else if (rightLarge) {
    return (
      <>
        <GridCell
          { ...gridProps.leftSmall }
          className={cn(leftClassName, smallClassName)}
          style={style}
        >
          { small }
        </GridCell>
        <GridCell
          { ...gridProps.rightLarge}
          style={style}
          className={cn(rightClassName, largeClassName)}
        >
          { large }
        </GridCell>
      </>

    )
  } else {
    return (
      <>
        <GridCell
          { ...gridProps.leftLarge }
          className={cn(leftClassName, largeClassName)}
          style={style}
        >
          { large }
        </GridCell>
        <GridCell
          { ...gridProps.rightSmall}
          className={cn(rightClassName, smallClassName)}
          style={style}
        >
          { small }
        </GridCell>
      </>

    )
  }
  return createElement(GridCell, gridProps[pos], children);
})
