import { coreLogicMgr } from '@isomorix/core';
import { LogicMgr } from '@isomorix/store';

/**
 * @name pluginLogicMgr
 * @memberof module:core-ui
 * @type {module:store.LogicMgr}
 */
export const pluginLogicMgr = LogicMgr
  .initModule('@isomorix/core-ui/logic')
  .addSource(coreLogicMgr);
