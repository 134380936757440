import React from 'react';
import { useRecordSubscription } from '@isomorix/react';
import { TextContainer, Typography } from '@react-md/typography';
import { LoginDialog } from '@isomorix/react-md-user';
import { CORE_LOGIC } from '../constants';
import { DOCS_URL_SEARCH } from '@isomorix/core-config';

const containerStyle = { zIndex: 36 };

export const UserRoute = React.memo(function UserRoute(props) {
  const { logic } = props;
  useRecordSubscription(logic);
  const { match } = logic;
  if (!match) {
    return null;
  }
  if (match.pathname === '/user') {
    return (
      <TextContainer>
        <Typography type="headline-2">User</Typography>
      </TextContainer>
    )
  }
  const { searchParams } = match;
  if (!searchParams) return null;
  if (searchParams.user_login) {
    const isDocsPortal = !!logic.getSession()
      .location
      .searchParams[DOCS_URL_SEARCH.PATHNAME];
    return !isDocsPortal
      ? (<LoginDialog />)
      : (<LoginDialog
          dialogProps={{
            portal: true,
            style: containerStyle,
            containerStyle,
            overlayStyle: containerStyle
          }}/>)
  }
  return null;
});

UserRoute.displayName = CORE_LOGIC.USER_ROUTE;
