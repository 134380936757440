/*
 * 01/21/22 no longer accepting an Array,
 * See notes in useCss(). It messes up the ids,
 * breaking duplicate checks and removal.
 */
// const insertCssMapper = style => style._insertCss();
const noRemove = () => {};
/*
 * This function (by default) is added to the
 * Router's localProps on the client and it
 * is then used when rendering/hydrating as the
 * value for CssContextProvider. The CssContext
 * is provided by @isomx/react-css. This
 * function is then used by the
 * useCss() hook (also provided by @isomx/react-css).
 *
 * On the server this function can't be
 * static since it needs a reference to the Html props
 * so it can populate with `props.css` that
 * will be a Set with the values from the components.
 */
function insertCss(css) {
  if (css) {
    /*
     * The css is the Object generated by
     * isomorphic-style-loader.
     */
    return css._insertCss();
    // const removeCss = css.map(insertCssMapper);
    // return () => removeCss.forEach(dispose => dispose());
  } else {
    return noRemove;
  }
}

export function getInsertCssFn() {
  return insertCss;
}

export const createStyleTags = null;
