import { AppBuilderRoute } from './AppBuilder';
import { coreLogicRoutes } from '../coreLogicValues';
import { appBuilderLogicMgr } from './logic';

export function addCoreLogicValues(pluginBuilder) {
  coreLogicRoutes.addRoute(
    AppBuilderRoute,
    './appBuilder',
    appBuilderLogicMgr
  );
}

export { appBuilderLogicMgr }
