import { Subscriber } from "rxjs";
import { useEffect, useRef, useState } from 'react';
import { isRecord } from '@isomorix/object-types';
import { CHANGES } from "@isomorix/store";
import { getStateChangeKey } from "./utils";

const { CREATED, DELETED } = CHANGES;

const getChangeKey = (record, VFName, keys) => {
  if (!record || !record.__ID) {
    return null;
  } else if (record.__isVFMany(VFName)) {
    let val = record.getVFValue(VFName);
    if (!val) {
      return null;
    }
    val = val.value;
    return `${val.dispatchId}_${val.event}`;
  } else {
    return getStateChangeKey(record[VFName], keys);
  }
}

export function useVFSubscription(record, VFName, keys) {
  const isFirst = useRef(true);
  const [ changeKey, setChange ] = useState(isFirst.current
    ? getChangeKey(record, VFName, keys)
    : undefined
  );
  useEffect(() => {
    const first = isFirst.current;
    isFirst.current = false;
    if (record && record.__ID) {
      const sub = record[`${VFName}Subscribe`](new VFSubscriber(setChange, keys, record));
      if (!first) {
        setChange(getChangeKey(record, VFName, keys));
      }
      return () => sub.unsubscribe();
    } else if (!first) {
      setChange(null);
    }
  }, [ record, VFName, keys, setChange ]);
  return changeKey;
}

class VFSubscriber extends Subscriber {

  constructor(setChange, keys, record) {
    super();
    this.setChange = setChange;
    this.changeKeys = keys;
    this.record = record;
  }

  _next(recordOrValue) {
    if (!recordOrValue) {
      if (this.record.__ID) {
        this.setChange(null);
      }
    } else if (isRecord(recordOrValue)) {
      this.setChange(getStateChangeKey(recordOrValue, this.changeKeys));
    } else {
      this.setChange(`${recordOrValue.dispatchId}_${recordOrValue.event}`);
    }
  }

  _complete() {
    this.setChange(null);
    this.unsubscribe();
  }

  unsubscribe() {
    super.unsubscribe();
    this.setChange = undefined;
    this.changesKeys = undefined;
    this.record = undefined;
  }
}

/**
 * Returns the value from the subscription.
 *
 * **This can only be used for many-relationships**,
 * since otherwise the Record remains the same
 * and it will not trigger a re-render. Use
 * the `useVFSubscription` hook instead, then
 * just access the value by getting it from the record.
 * @param {module:record.Record} [record]
 * @param {string} VFName
 * @param {boolean} [onCreateOrDelete = false]
 * When `true`, it will only change state when
 * a ref is added or removed from the Value.
 * @returns {(
 *   null|
 *   module:model/indexChildStore.Store
 * )}
 * @ignore
 */
export function useVFSubscriptionValue(
  record, VFName, onCreateOrDelete = false
) {
  const isFirst = useRef(true);
  const [ value , setValue ] = useState(isFirst.current && record
    ? record.getVFValue(VFName) || null
    : undefined
  );
  useEffect(() => {
    const first = isFirst.current;
    isFirst.current = false;
    if (!record || !record.__ID) {
      if (!first) {
        setValue(null);
      }
      return;
    }
    let sub;
    if (onCreateOrDelete) {
      sub = record[`${VFName}Subscribe`](
        new VFValueSubscriberOnCreateOrDelete(setValue, record)
      );
    } else {
      sub = record[`${VFName}Subscribe`](
        new VFValueSubscriber(setValue, record)
      );
    }
    if (!first) {
      setValue(record.getVFValue(VFName) || null);
    }
    return () => sub.unsubscribe();
  }, [ record, VFName, onCreateOrDelete, setValue ]);
  /*
   * If onCreateOrDelete === true, you have to
   * get the value every time because the
   * current value will get de-referenced
   * when changes occur. But we'll only
   * reference the new one in useState()
   * when there is a CREATE or DELETE.
   */
  return onCreateOrDelete
    ? record && record.getVFValue(VFName) || null
    : value;
}


class VFValueSubscriber extends Subscriber {
  constructor(setValue, record) {
    super();
    this.setValue = setValue;
    this.record = record;
  }

  _next(recordOrValue) {
    if (!recordOrValue) {
      if (this.record.__ID) {
        this.setValue(null);
      }
    } else {
      this.setValue(recordOrValue);
    }
  }

  _complete() {
    this.setChange(null);
    this.unsubscribe();
  }

  unsubscribe() {
    super.unsubscribe();
    this.setValue = undefined;
    this.record = undefined;
  }
}

class VFValueSubscriberOnCreateOrDelete extends VFValueSubscriber {

  _next(value) {
    if (!value) {
      if (this.record.__ID) {
        this.setValue(null);
      }
    } else {
      const { changes } = value;
      let change;
      for(let key in changes) {
        change = changes[key];
        if (
          change === CREATED
          || change === DELETED
        ) {
          this.setValue(value);
          break;
        }
      }
    }
  }
}
