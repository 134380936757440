import { useContext } from 'react';
import { RouterContext } from '../RouterContext';

/**
 * A hook for using the
 * [Location record]{@link module:core/location.Record}
 * from the router context.
 *
 * This could also be accomplished with the
 * [useRouterLocation hook]{@link module:react-router.useRouterContext}:
 *
 * ```js
 * import { useRouterContext } from '@isomorix/react-router';
 *
 * function MyComponent(props) {
 *   const { location } = useRouterContext();
 *
 * }
 * ```
 *
 * Furthermore, the Location record can
 * also be accessed via the
 * [Session record's location virtual field]{@link module:core/session.Record#location}.
 * @function useLocation
 * @memberof module:react-router
 * @returns {module:core/location.Record}
 */
export function useLocation() {
  return useContext(RouterContext).location;
}

