let docsData;
try {
  docsData = require('../../jsdocData/features/graphql.json');
} catch (e) {
  docsData = { links: {} };
}
export const graphql = {
  name: 'graphql',
  headline: [
    'Get A Full-Spec',
    { children: 'Graphql', className: 'color--graphql' },
    'API',
    { children: 'Without', fontStyle: 'italic' },
    'Writing A Single Line Of',
    { children: 'Graphql', className: 'color--graphql', after: ',' },
    { children: 'Ever', decoration: 'underline' }
  ],
  video: {
    url: 'https://dev-39.wistia.com/medias/v63h8l2gbl',
    preview: '/static/img/vid/breakout_graphql_preview.webp',
    totalSeconds: 35,
  },
  panels: [{
    header: {
      flames: true,
      icon: '3d_rotation',
      avatarColor: 'pink',
      text: '3D + VR'
    },
    text: [
      'Get inside your graph like never before and explore its connections, fields, and relationships with the first-ever fully interactive Virtual Reality experience.',
      [
        'Use your',
        { children: 'Flight Dashboard', weight: 'bold' },
        'to easily isolate connections, view interactive documentation, filter nodes in real-time and so much more...'
      ],
      {
        component: 'ViewModeButton',
        props: {
          name: 'features_graphql_toggle_view_mode',
          universalProps: {
            theme: 'secondary',
            themeType: 'outline',
          },
          props2D: {
            icon: '3d_rotation',
            children: 'Try it now!'
          },
          props3D: {
            icon: '360',
            children: 'Back To 2D'
          }
        }
      }
    ]
  }, {
    header: {
      icon: 'description',
      avatarColor: 'pink',
      text: 'Self Documenting'
    },
    text: [
      [
        'Every operation, field, and type will include',
        {
          children: 'detailed descriptions specific to your app',
          to: docsData.links.modelDef,
          isDocsLink: true,
        },
        'so you have a true self-documenting API.'
      ],
      // [
      //   'It\'s going to',
      //   {
      //     children: 'automatically include helpful details',
      //     to: docsData.links.modelDef,
      //     isDocsLink: true,
      //   },
      //   'about each operation, field, and type so you have a true self-documenting API.'
      // ],
      [
        'It can even',
        {
          children: 'interlink schema documentation',
          to: docsData.links.scLongnameToUrl,
          isDocsLink: true,
        },
        'with your code\'s documentation so it\'s dead-simple for other team members or developers to connect any app or service with your API. You can provide existing links if you want, or',
        {
          children: 'generate detailed documentation for your entire app automatically',
          to: docsData.links.jsdocPackage,
          isDocsLink: true,
        },
        'to take full advantage of this feature with zero additional effort!'

        // 'That means you not only have an amazing standalone documentation site as well as a tightly integrated develtake full advantage of this feature to interlink your API with the output with zero additional effort!'

      ],
      // [
      //   {
      //     children: 'Tip:',
      //     weight: 'bold',
      //   },
      //   'You can provide existing links if you want, or use the built-in tools to',
      //   {
      //     children: 'generate extremely detailed documentation automatically',
      //     to: docsData.links.jsdocPackage,
      //     isDocsLink: true,
      //   },
      //   'so you can take full advantage of this feature with zero additional effort!'
      // ]
      // [
      //   'You can provide existing links if you want, but the best part is you can take full advantage of this feature with zero additional effort using the built-in tools that will',
      //   {
      //     children: 'generate your documentation for you',
      //     to: docsData.links.jsdocPackage,
      //     isDocsLink: true,
      //
      //   },
      //   'which this will link to automatically!'
      // ]
      // [
      //   {
      //     children: 'Tip:',
      //     weight: 'bold',
      //   },
      //   'Use the built-in tools to',
      //   {
      //     children: 'automatically generate extremely detailed documentation',
      //     to: docsData.links.jsdocPackage,
      //     isDocsLink: true,
      //   },
      //
      // ],
      // [
      //   'You The best part is you can take full advantage of this feature with zero additional effort! As you know, the included tools already',
      //   {
      //     children: 'generate extremely detailed documentation'
      //   },
      //   'about your code, your app\'s data structures, and much more. And this feature integrates with those tools automatically so yoAnd the exported links will seamlessly integrate with this feature. This is fully integrated with that, t will fully integrate with your API in addition to being an amazing standalone resource.'
      // ],
      // [
      //   'The best part is there are built-in tools that will',
      //   {
      //     children: 'generate extremely detailed documentation',
      //     to: docsData.links.jsdocPackage,
      //     isDocsLink: true,
      //   },
      //   'about your code, your app\'s data structures, and much more so that  - including all the links - meaning you can take full advantage of this feature with zero additional effort!'
      // ]
    ]
  }, {
    header: {
      icon: 'lock',
      avatarColor: 'pink',
      text: 'Comprehensive Multi-Layer Security'
    },
    text: [
      // 'Schema and all documentation is automatically generated for each user role. Never again broadcast sensitive details to users that don\'t have the proper permissions!'
      [
        'Schema and all documentation will be',
        {
          children: 'generated on-demand for each user role',
          to: docsData.links.graphqlRoleSchemas,
          isDocsLink: true,
        },
        'so you never again broadcast sensitive details about fields and operations to users that don\'t have the proper permissions.',
      ],
      'But that\'s just the first layer of security.',
      [
        'All queries and subscriptions must also pass',
        {
          children: 'robust permission checks',
          // after: ',',
          to: docsData.links.queryPermApi,
          isDocsLink: true,
        },
        'that are',
        {
          children: 'easily configurable',
          to: docsData.links.permissionRecordModule,
          isDocsLink: true,
        },
        'on any plugin, model, field, or even on a per-record level so you always have complete control over access to your app\'s data.',
      ],
      // [
      //   'All operations must also pass robust permission checks that are',
      //   {
      //     children: 'robust permission checks',
      //     to: docsData.links.queryPermApi,
      //     isDocsLink: true,
      //   },
      //   {
      //     children: 'easily configurable',
      //     to: docsData.links.permissionRecordModule,
      //     isDocsLink: true,
      //   },
      //   'on any plugin, model, field, or even on a per-record level so you always have complete control over access to your app\'s data.',
      // ],
      // [
      //   // 'This will include',
      //   'This is done by',
      //   {
      //     children: 'analyzing every query and subscription',
      //     to: docsData.links.queryPermApi,
      //     isDocsLink: true,
      //   },
      //   'to ensure a user has permission to access the fields used in its arguments, as well as permission to read the selected fields and virtual fields.',
      // ],
      [
        'Directives can be',
        {
          children: 'easily protected too',
          after: ',',
          to: docsData.links.directivePermUse,
          isDocsLink: true,
        },
        'even on a',
        {
          children: 'per-argument level',
          after: ',',
          to: docsData.links.directiveArgPermUse,
          isDocsLink: true,
        },
        'so those are also checked. This is an amazing feature you\'re going to absolutely love because it makes it possible to use directives as a means of securely triggering virtually any side effect you can imagine. In fact, it\'s exactly how this app handles login/logout operations!'
      ],
      // 'But what about mutations?',
      [
        // 'This is where the security features really shine, because not only do all the query & directive checks apply, but',
        'It also fully integrates with the Mutation interface, so for mutations it\'ll trigger',
        {
          // children: 'detailed checks are also performed',
          children: 'additional permission checks',
          // after: ',',
          to: docsData.links.mutationSetPermCheck,
          isDocsLink: true,
        },
        // 'to ensure a user is allowed to modify the resources identified by the mutation - regardless of how complex the mutation might be or how many data models it affects.',
        // 'that ensures a user is allowed to modify the resources identified by the request, which it is fully capable of doing regardless of how complex the mutation might be or how many data models it affects.',

        // 'which is fully capable of accurately checking all permissions regardless of how complex the mutation might be or how many data models it affects.',
        'that ensure the user is allowed to make the requested changes.'
      ],
      // [
      //   // 'This is where the security features really shine, because not only do all the query & directive checks apply, but',
      //   'It also fully integrates with the Mutation interface, so in addition to all the query and directive checks, it\'ll also trigger the',
      //   {
      //     // children: 'detailed checks are also performed',
      //     children: 'Mutation\'s detailed permission checks',
      //     // after: ',',
      //     to: docsData.links.mutationSetPermCheck,
      //     isDocsLink: true,
      //   },
      //   // 'to ensure a user is allowed to modify the resources identified by the mutation - regardless of how complex the mutation might be or how many data models it affects.',
      //   // 'that ensures a user is allowed to modify the resources identified by the request, which it is fully capable of doing regardless of how complex the mutation might be or how many data models it affects.',
      //
      //   // 'which is fully capable of accurately checking all permissions regardless of how complex the mutation might be or how many data models it affects.',
      //   'that\'ll ensure the user is allowed to make the changes.'
      // ],


      // [
      //   'All of this would be challenging to accurately do by hand, not to mention',
      //   {
      //     children: 'extremely',
      //     fontStyle: 'italic'
      //   },
      //   'time consuming, since it would typically require manual intervention every time a permission or relationship changes.'
      // ],
      // 'Luckily you no longer have to worry about it. And you\'ll be able to modify any part of your data structure at any time while having the peace of mind that everything will be fully protected, automatically.',

      'In short, nothing has been left to chance.',
      'You can freely modify any part of your data structure at any time with 100% peace of mind because it\'ll be protected in multiple layers automatically, allowing you to rapidly ship new features with rock-solid confidence.'

    ]
  }, {
    header: {
      icon: 'touch_app',
      // icon: 'terminal',
      avatarColor: 'pink',
      text: 'Interactive UI',
    },
    text: [
      [
        'An interactive Playground based on the component from Prisma is included for rapid testing, or to simply read through the generated schema.',
        // 'Currently, it\'s a modified version of the Playground provided by Prisma, but a brand new interface is coming out soon that will include tons of improvements and several powerful new features you\'re going to love.',
      ],
      [
        'The coolest part is you can use the Query\'s',
        {
          children: 'toGraphql method',
          to: docsData.links.queryToGraphql,
          isDocsLink: true,
        },
        'or the',
        {
          children: 'toGraphqlMutation method',
          to: docsData.links.mutationToGraphql,
          isDocsLink: true,
        },
        'to instantly convert any query, subscription, or mutation to GraphQL. Simply copy & paste the output into the Playground to run it visually, modify it, whatever you want!',
      ],

      // [
      //   'The coolest part is you can use the Query\'s',
      //   {
      //     children: 'toGraphql',
      //     to: docsData.links.queryToGraphql,
      //     isDocsLink: true,
      //   },
      //   'or',
      //   {
      //     children: 'toGraphqlSubscription',
      //     to: docsData.links.queryToGraphqlSubscription,
      //     isDocsLink: true,
      //   },
      //   'method to instantly convert any query or subscription to GraphQL. Simply copy & paste the output into the Playground to run it visually, modify it, whatever you want!',
      //   'The same can be done for mutations too using its',
      //   {
      //     children: 'toGraphqlMutation method',
      //     after: '.',
      //     to: docsData.links.mutationToGraphql,
      //     isDocsLink: true,
      //   },
      // ],
      [
        'It works in the opposite direction too - just use the',
        {
          children: 'fromGraphql method',
          to: docsData.links.queryFromGraphql,
          isDocsLink: true,
        },
        'or the',
        {
          children: 'fromGraphqlRootMutation method',
          to: docsData.links.mutationFromGraphql,
          isDocsLink: true,
        },
        'instead and it\'ll be automatically converted into a Query or MutationQuery instance for easy programmatic usage.'
      ],
      {
        component: 'Button',
        props: {
          theme: 'secondary',
          themeType: 'outline',
          to: '/graphql-playground',
          children: 'Try it out',
          icon: 'terminal'
        }
      },
      {
        children: [
          'What\'s more, if you want to explore your graph\'s connections and edges from a different perspective than the awesome 3D and VR experience you\'re covered!'
        ],
        style: { marginTop: '1rem' }
      },
      {
        component: 'Button',
        props: {
          theme: 'secondary',
          themeType: 'outline',
          to: '/graphql-voyager',
          children: 'Voyager Is Included',
          icon: 'satellite_alt'
        }
      }

      // [
      //   'Execute queries, mutations, and subscriptions via a beautiful Playground, or explore your graph\'s connections and edges with Voyager - both available as an endpoint',
      //   { children: 'and', fontStyle: 'italic' },
      //   'component'
      // ]
    ]
  }, {
    header: {
      icon: 'language',
      avatarColor: 'pink',
      text: 'Easily Customizable And Fully Portable',
    },
    text: [
      [
        'This is the',
        { children: 'exact same', decoration: 'underline' },
        'GraphQL you\'d typically write by hand, it\'s just automated and includes tons of added benefits!'
      ]
    ]
  }]
}
