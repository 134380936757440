/**
 * @module core-ui/constants
 */
export {
  BKG_COLOR_DARK,
  BKG_COLOR_LIGHT
} from './colors';
export { CORE_LOGIC } from './logic';
export { PKG } from './pkg';
export { ROLES } from './roles';
export { PATHS } from './paths';
export {
  PERMISSIONS,
  CORE_UI_PERMISSIONS
} from './permissions';
export {
  SCALE_TRANSITION_TIME,
  VIEW_MODE_TRANSITION_TIME,
} from './transitions';
export { ROUTE_SLUGS } from './routeSlugs';

export const MAIN_SCROLL_ELEM_ID = 'core-ui-layout-main-content';
export const APP_BAR_HEIGHT = 48;
