import { useContext } from 'react';
import { RouterContext } from '../RouterContext';


/**
 * A hook for retrieving the
 * [CoreLogic record]{@link module:core/coreLogic.Record}
 * that is managing the app's top-level route.
 *
 * This would be the logic record whose
 * [type property]{@link module:core/coreLogic.Record#type}
 * is set to the
 * [ROUTER constant]{@link module:core-config/coreLogicTypes.ROUTER}.
 *
 * The same could also be accomplished with the
 * [useRouterContext hook]{@link module:react-router.useRouterContext}
 * as follows:
 *
 * ```js
 * import { useRouterContext } from '@isomorix/react-router';
 *
 * function MyComponent(props) {
 *   const { logic } = useRouterContext();
 * }
 * ```
 * @function useRouterLogic
 * @memberof module:react-router
 * @returns {module:core/coreLogic.Record}
 * The [CoreLogic record]{@link module:core/coreLogic.Record}
 * that is managing the app's top-level route.
 *
 */
export function useRouterLogic() {
  return useContext(RouterContext).logic;
}

/**
 * A hook for retrieving the `RouterContext`
 *
 * Reference the
 * [routerContextValue typedef]{@link module:react-router.routerContextValue}
 * for details on the properties available in the
 * `RouterContext`.
 * @function useRouterContext
 * @memberof module:react-router
 * @returns {module:react-router.routerContextValue}
 */
export function useRouterContext() {
  return useContext(RouterContext);
}
