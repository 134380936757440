
const STATIC_DIR = 'static';

const DOCS_DIR = 'docs';

const CSS_DIR = `css`;

const CSS_PATH = `/${STATIC_DIR}/${CSS_DIR}`;

const VOYAGER_WORKER = `/${STATIC_DIR}/workers/voyager.worker.js`;
const DOCS_TEMPLATE_CSS = `${CSS_PATH}/docsTemplate.css`;
const APP_CSS = `${CSS_PATH}/appDense.css`;
const APP_BUILDER_CSS = `${CSS_PATH}/appBuilderDense.css`;

export const PATHS = {
  DOCS_DIR,

  VOYAGER_WORKER,
  DOCS_TEMPLATE_CSS,
  APP_CSS,
  APP_BUILDER_CSS,
}
