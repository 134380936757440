/**
 * # Summary
 * Provides routing that is compatible with React.
 *
 * It only provides react-specific resources,
 * all remaining functionality is provided by
 * the [@isomx/ui-router package]{@link module:ui-router}.
 *
 * Server rendering support is provided by the
 * [@isomx/react-ssr package]{@link module:react-ssr}.
 *
 * # Usage
 *
 * ### Logic Components
 *
 * These are components that can be associated with a
 * [CoreLogic record]{@link module:core/coreLogic.Record}
 * via its [component property]{@link module:core/coreLogic.Record#component}.
 *
 * - [Router]{@link module:react-router.Router} - Associated with
 * the [CoreLogic record]{@link module:core/coreLogic.Record} that is
 * managing the top-level route for the app. All other routes
 * would be children of this logic.
 * - [Route]{@link module:react-router.Route} - Associated with
 * [CoreLogic records]{@link module:core/coreLogic.Record} that are
 * managing a single route.
 *
 *
 *
 * ### Standalone Components
 *
 * These are components that can be used without being
 * associated with a [CoreLogic record]{@link module:core/coreLogic.Record}.
 *
 * - [Link]{@link module:react-router.Link} - Renders a link and
 * handles navigation based on the provided props.
 *
 * ### Hooks
 *
 * - [useCss]{@link module:react-router.useCss} - Include CSS/styles
 * - [useLocation]{@link module:react-router.useLocation} - Provides
 * access to the [Location record]{@link module:core/location.Record}
 * - [useOnClick]{@link module:react-router.useOnClick} - Create an
 * `onClick` or similar function for a component
 * - [useRouter]{@link module:react-router.useRouterContext} - Retrieve
 * the [RouterContext]{@link module:react-router.routerContextValue}
 * - [useRouterLogic]{@link module:react-router.useRouterLogic} - Retrieve
 * the [CoreLogic record]{@link module:core/coreLogic.Record}
 * that is managing the app's top-level route.
 *
 *
 * @module react-router
 */
import { coreLogicMethods, CoreLogicFactory } from './coreLogicMethods';
import { Router } from './Router';
import { Route } from './Route';
import { MODEL_NAMES as M } from '@isomorix/core-config';
export { CssContext } from './CssContext';
export { Link } from './Link';
export { RouterContext } from './RouterContext';
export { CORE_LOGIC_IDS } from './constants';
export * from './hooks';
export * from '@isomorix/react-ssr';

/**
 * Adds the react-router package to the Plugin.
 * @function reactRouter
 * @memberof module:react-router
 * @param {module:plugin.PluginBuilder} pluginBuilder
 * @returns {module:plugin.PluginBuilder} pluginBuilder
 */
export default function reactRouter(pluginBuilder) {
  return pluginBuilder
    .addLogicMethodEnumValues(coreLogicMethods)
    .addLogicComponentEnumValues(M.CORE_LOGIC, [ Router, Route ])
}

export { coreLogicMethods, Router, Route, CoreLogicFactory }
