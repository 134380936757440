import { appBuilder } from './appBuilder';
import { autoDocs } from './autoDocs';
import { cli } from './cli';
import { graphql } from './graphql';
import { main } from './main';
import { mutation } from './mutation';
import { nginx } from './nginx';
import { query } from './query';
import { ROLES } from '../../constants';

export const data = {
  appBuilder,
  autoDocs,
  cli,
  graphql,
  main,
  mutation,
  nginx,
  query
};

export const defaultOrder = [
  'main',
  'query',
  'mutation',
  'graphql',
  'nginx',
  'cli',
  'appBuilder',
];

export const orderByRole = {
  [ROLES.ADMIN]: defaultOrder,
  [ROLES.ENGINEER]: defaultOrder,
  [ROLES.PARTNER]: [
    'appBuilder',
    'main',
    'query',
    'mutation',
    'graphql',
    'nginx',
    'cli',
  ],
  [ROLES.SUBSCRIBER]: defaultOrder
}

export const home = [
  main,
  query,
  mutation,
  graphql,
  nginx,
  cli,
  appBuilder,
  // autoDocs,
];
