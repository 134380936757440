import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import ReactPlayer from 'react-player/wistia';
import { useLayoutContext } from '@isomorix/react-md-layout';
import { hasSeen3D } from '../helpers';

const _staticStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute'
};

const renderPreview = (name, preview) => (
  <div
    className={'responsive-video-player'}
    key={'videoPreview'}
    style={_staticStyle}
  >
    <img
      alt={`${name} overview`}
      width='100%'
      height='100%'
      src={preview}
    />
  </div>
);

let Video;
if (process.env.BROWSER) {
  let noDelay = false;
  Video = React.memo(function Video (
    {
      preview,
      name,
      video,
      isLighthouse,
      ...props
    }
  ) {
    const [ visibility, setVisibility ] = useState(hasSeen3D()
      ? !!(video && video.playing)
      : false
    );
    const ref = useRef(null);
    useEffect(() => {
      if (!ref.current) return;
      let obs;
      const fn = () => {
        noDelay = true;
        if (obs === false) return;
        obs = new IntersectionObserver(([ { isIntersecting }]) => {
          setVisibility(prev => prev || isIntersecting);
        }, {
          threshold: 0.25,
        });
        obs.observe(ref.current);
      }
      if (noDelay) {
        fn();
      } else {
        setTimeout(fn, isLighthouse ? 15000 : 500);
      }

      return () => {
        if (obs) {
          obs.disconnect();
        } else {
          obs = false;
        }
      }
    }, [ ref, setVisibility, isLighthouse ]);
    let child;
    if (visibility) {
      child = (
        <RenderVideo
          key={'renderVideo'}
          preview={preview}
          name={name}
          video={video}
          visibility={visibility}
          containerRef={ref}
          { ...props }
        />
      );
    }
    return (
      <div ref={ref} className={'responsive-video-container'}>
        { renderPreview(name, preview) }
        { child }
      </div>
    )
  })
} else {
  Video = function Video(
    {
      preview,
      name,
    }
  ) {
    return (
      <div className={'responsive-video-container'}>
        { renderPreview(name, preview) }
      </div>
    )
  }
}

export { Video };

const defaultPlayerConfig = {
  wistia: {
    // All options:
    // https://wistia.com/support/developers/embed-options
    options: {
      videoFoam: true,
      fitStrategy: 'contain'
    }
  }
};
const widthSetterOptions = { constrain: true };

const FORCE_LIGHT = process.env.NODE_ENV !== 'production';

const RenderVideo = React.memo(function PlayVideo(
  {
    url,
    totalSeconds,
    // preview = '/static/img/layout/coming_soon_preview.webp',
    name,
    video,
    visibility,
    setState,
    containerRef
  }
) {
  const layoutContext = useLayoutContext();
  const { viewMode } = layoutContext;
  hasSeen3D(layoutContext.render3D);
  const origMode = useRef(viewMode);
  // const prefix = `[${origMode.current}]`;
  const [ playing, setPlaying ] = useState(video && video.playing || false);
  const ref = useRef();
  const lastSec = useRef(video && video.playedSeconds || 0);
  const { scrollWidth, scrollHeight } = layoutContext;
  useEffect(() => {
    const player = ref.current && ref.current.getInternalPlayer();
    const width = containerRef.current && containerRef.current.clientWidth;
    if (player && width > 0) {
      player.width(width, widthSetterOptions);
    }
  }, [ scrollWidth, scrollHeight, containerRef, ref ]);
  const onReady = useCallback(
    () => {
      // console.log('got onReady()');
      if (video && video.playing && video.playedSeconds) {
        ref.current.seekTo(video.playedSeconds);
      }
    },
    [ video ]
  );
  const onProgress = useCallback((obj, isSeek) => {
    if (!isSeek && (obj.playedSeconds === 0 || Math.round(obj.playedSeconds) >= totalSeconds)) {
      return;
    }
    if (
      isSeek
      || !video
      || !video.playedSeconds
      || video.ended
      || (
        origMode.current === viewMode
        && Math.abs(obj.playedSeconds - lastSec.current) >= 10
      )
    ) {
      // console.log(`${prefix} PERSISTING SECONDS = `, obj.playedSeconds);
      setState(name, {
        video: {
          playedSeconds: obj.playedSeconds,
          ended: false,
        }
      });
      lastSec.current = obj.playedSeconds;
    } else {
      // console.log(`${prefix} UPDATING SECONDS = ${obj.playedSeconds}`, video, lastSec.current);
      video.playedSeconds = obj.playedSeconds;
    }
  }, [ name, setState, video, viewMode, origMode, lastSec ]);
  const onPlay = useCallback(() => {
    if (!playing) {
      if (lastSec.current) {
        ref.current.seekTo(lastSec.current);
      }
      setState(name, { video: { playing: true, ended: false } });
    }
    setPlaying(true);
    // console.log(`${prefix} onPlay()`);
  }, [ playing, name, setState, setPlaying, lastSec ]);
  const onPause = useCallback(() => {
    if (playing) {
      if (video && video.playedSeconds) {
        lastSec.current = video.playedSeconds;
      }
      setState(name, {
        video: {
          playing: false,
          playedSeconds: lastSec.current
        }
      });
      setPlaying(false);
    }
    // console.log(`${prefix} onPause()`);
  }, [ playing, name, video, setState, setPlaying, lastSec ]);
  const onEnded = useCallback(() => {
    setState(name, {
      video: {
        playing: false,
        ended: true,
        playedSeconds: 0
      }
    });
    setPlaying(false);
    lastSec.current = 0;
    // console.log(`${prefix} onEnded()`)
  }, [ name, setState, lastSec, setPlaying ])
  return (
    <ReactPlayer
      ref={ref}
      className={'responsive-video-player'}
      width={'100%'}
      height={'100%'}
      key={'ab'}
      playing={playing}
      onEnded={onEnded}
      config={defaultPlayerConfig}
      // muted={!visibility}
      // light={!(visibility || video)}
      light={FORCE_LIGHT || !(visibility || video)}
      onReady={onReady}
      onProgress={onProgress}
      onPlay={onPlay}
      onPause={onPause}
      onSeek={sec => onProgress({ playedSeconds: sec }, true)}
      progressInterval={origMode.current === viewMode ? 1000 : 50}
      playIcon={<NoPlayIcon />}
      url={url}
      controls={true}
    />
  );
})

const NoPlayIcon = () => null;


// Video = React.memo(function Video(
//   {
//     // url = '/static/vids/data_flow.mp4',
//     url = '/static/vids/coming_soon_video.mp4',
//     preview = '/static/img/layout/home-page-video-thumb.jpg',
//     name,
//     video,
//     setState,
//   }
// ) {
//   const layoutContext = useLayoutContext();
//   const { render2D, render3D, viewMode } = layoutContext;
//   const [ isReady, setReady ] = useState(false);
//   const [ playing, setPlaying ] = useState(false);
//   const origMode = useRef(viewMode);
//   const prefix = `[${origMode.current}]`;
//   const isLite = useRef(!!!video);
//   const lastSec = useRef(video && video.playedSeconds);
//   const onReady = useCallback(() => setReady(true), [ setReady ]);
//   const onProgress = useCallback((obj) => {
//     if (obj.playedSeconds === 0) return;
//     if (
//       !video
//       || !video.playedSeconds
//       || (
//         origMode.current === viewMode
//         && Math.abs(obj.playedSeconds - lastSec.current) >= 10
//       )
//     ) {
//       lastSec.current = obj.playedSeconds;
//       // console.log(`${prefix} PERSISTING SECONDS`);
//       setState(name, {
//         video: {
//           playedSeconds: obj.playedSeconds
//         }
//       });
//     } else {
//       // console.log(`${prefix} UPDATING SECONDS = ${obj.playedSeconds}`, video, lastSec.current);
//       video.playedSeconds = obj.playedSeconds;
//     }
//   }, [ name, setState, video, viewMode, render2D, render3D ]);
//   const onPlay = useCallback(() => {
//     if (!video || !video.playing) {
//       setState(name, { video: { playing: true } });
//     }
//     if (!hasPlayed.current && video && video.playedSeconds) {
//       ref.current.seekTo(lastSec.current || video.playedSeconds);
//     }
//     hasPlayed.current = true;
//     // console.log(`${prefix} onPlay()`)
//     setPlaying(true);
//   }, [ playing, video, name ]);
//   const onPause = useCallback(() => {
//     if (
//       !video
//       || video.playing
//       // || (lastSec.current && video.playedSeconds !== lastSec.current)
//     ) {
//       setState(name, {
//         video: {
//           playing: false,
//           playedSeconds: lastSec.current
//         }
//       });
//     }
//     // console.log(`${prefix} onPause()`)
//     setPlaying(false);
//   }, [ video, playing, name ]);
//   const hasPlayed = useRef(video && video.playing ? true : false);
//   const ref = useRef();
//   const containerRef = useRef();
//   if (name === 'query' && false) {
//     console.log(`${prefix} details`, {
//       isReady,
//       playing,
//       render2D,
//       render3D,
//       viewMode,
//       isLite: isLite.current,
//       hasPlayed: hasPlayed.current,
//       videoState: video ? { ...video } : undefined
//     })
//   }
//   //////////////////////////////////////////////////
//   // 10/28/2022: Disabled when fixing the wrong boolean
//   // set in hasPlayed.current during initialization.
//   // Not sure if that was on purpose, but now that
//   // it's fixed, this fails.
//   //////////////////////////////////////////////////
//   // useEffect(() => {
//   //   if (
//   //     !isReady || hasPlayed.current || (render2D && render3D)
//   //   ) {
//   //     return;
//   //   }
//   //   hasPlayed.current = true;
//   //   if (video.playedSeconds) {
//   //     ref.current.seekTo(
//   //       video.playedSeconds + (viewMode === '2D' ? 0.1 : 0.6)
//   //     );
//   //   }
//   //   console.log(`${prefix} starting video...`);
//   //   setPlaying(true);
//   //
//   // }, [ name, setState, viewMode, render2D, render3D, isReady, hasPlayed ]);
//   useEffect(() => {
//     if (name !== 'query') return;
//     return () => {
//       console.log(`${prefix} unmounting`);
//     }
//   }, []);
//   const [ visibility, setVisibility ] = useState(false);
//   useEffect(() => {
//     if (!containerRef.current) return;
//     const obs = new IntersectionObserver(([ { isIntersecting }]) => {
//       if (isIntersecting) {
//         isLite.current = false;
//       }
//       setVisibility(isIntersecting);
//     }, {
//       threshold: 1,
//     });
//     obs.observe(containerRef.current);
//     return () => {
//       obs.disconnect();
//     }
//   }, [ ref, setVisibility ]);
//   return (
//     <div ref={containerRef} className={'responsive-video-container'}>
//       <ReactPlayer
//         ref={ref}
//         className={'responsive-video-player'}
//         width={'100%'}
//         height={'100%'}
//         key={'ab'}
//         playing={playing}
//         // muted={!visibility}
//         // light={isLite.current}
//         light={!hasPlayed.current}
//         onReady={onReady}
//         onProgress={onProgress}
//         onPlay={onPlay}
//         onPause={onPause}
//         onSeek={sec => lastSec.current = sec}
//         progressInterval={origMode.current === viewMode ? 1000 : 50}
//         playIcon={(
//           <img
//             alt={`${name} overview`}
//             width='100%'
//             height='100%'
//             src={preview}
//           />
//         )}
//         url={url}
//         controls={true}
//       />
//     </div>
//   );
// })
// export { Video }




