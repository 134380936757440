import { of } from 'rxjs';
import { mapTo, mergeMap } from 'rxjs/operators';
import { loadable } from '@isomorix/store';
import { manageLayout3DLoader } from '@isomorix/react-md-layout';

const _loadDocsChunk = loadable(() => import('./docs/docsChunk'));

export const createDocsLoader = (coreStore, docsPath, localProps) => {
  coreStore = coreStore.mainStore;
  let mod;
  function loadDocsModule(action) {
    if (mod) return of(mod);
    return _loadDocsChunk(action).pipe(
      mergeMap(module => {
        if (mod) return of(mod);
        mod = module;
        const cache = new module.ContentCache(docsPath);
        module.setDefaultContentCache(cache);
        /*
         * As of 5/11/2022 the search functionality
         * is not server-rendered. So only need to
         * load the instantSearch connection
         * in the browser. But on the server it's
         * pretty much already there, since it
         * initializes the MEILISEARCH connection
         * to determine the apiKey to use on the client.
         */
        if (process.env.BROWSER) {
          return coreStore
            .instance
            .loadMeilisearchConnections()
            .pipe(mapTo(mod));
        }
        return of(mod);
      })
    )
  }
  loadDocsModule.chunkName = _loadDocsChunk.chunkName;
  if (localProps) {
    localProps.loadDocsModule = loadDocsModule;
    localProps.docsChunkName = loadDocsModule.chunkName;
  }
  return loadDocsModule;
}

let createLayout3DLoader = undefined;
if (process.env.BROWSER) {
  const _loadLayout3DChunk = loadable(() => import('./router/Layout3D'));
  createLayout3DLoader = (coreStore, localProps) => manageLayout3DLoader(
    _loadLayout3DChunk,
    coreStore,
    localProps
  );
}

export { createLayout3DLoader };
