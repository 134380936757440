import React from 'react';
import { GridCellSplit, Text } from '../../components';
import { ExpansionList } from './ExpansionList';
import { Video } from './Video';
import { Grid } from '@react-md/utils';
import { bem } from '../helpers';

const block = bem('section');
const gridBlock = bem('grid');

export const Section = React.memo(React.forwardRef(function Section(
  {
    data,
    isAlt = false,
    isMain = false,
    is2D,
    isLighthouse,
    location,
    session,
    state,
    setState,
  },
  ref
) {
  const headline = (
    <Text
      type={'headline-1'}
      className={block('headline')}
      children={data.headline}
    />
  );
  let subHeadline, customContent, panels;
  if (data.subHeadline) {
    subHeadline = (
      <Text
        type={'headline-2'}
        className={block('sub-headline')}
        children={data.subHeadline}
      />
    );
  }
  if (data.CustomContent) {
    const { CustomContent } = data;
    customContent = (<CustomContent
        key={'customContent'}
        data={data}
        isAlt={isAlt}
        isMain={isMain}
        is2D={is2D} // no longer used by main.CustomContent
        isLighthouse={isLighthouse}
        session={session}
        location={location}
        state={state}
        setState={setState}
      />
    )
  }
  if (data.panels) {
    panels = (
      <ExpansionList
        name={data.name}
        panels={data.panels}
        expandedIds={state && state.expandedIds}
        setState={setState}
        location={location}
      />
    );
  }
  const content = (
    <>
      { headline }
      { subHeadline }
      { customContent }
      { panels }
    </>
  );
  const media = (
    <Video
      url={data.video.url}
      preview={data.video.preview}
      totalSeconds={data.video.totalSeconds}
      video={state && state.video}
      name={data.name}
      isLighthouse={isLighthouse}
      setState={setState}
    />
  );
  return (
    <div
      className={block({ alt: isAlt, main: isMain })}
      ref={ref}
    >
      <Grid
        className={gridBlock({
          alt: isAlt,
          main: isMain,
        })}
      >
        <GridCellSplit
          rightLarge={isAlt || isMain}
          leftClassName={gridBlock('cell', {
            left: true,
            alt: isAlt,
            content: isAlt,
            media: !isAlt,
          })}
          rightClassName={gridBlock('cell', {
            right: true,
            alt: isAlt,
            content: !isAlt,
            media: isAlt,
          })}
          large={media}
          small={content}
        />
      </Grid>
    </div>

  );
}));
