import React, { createElement } from 'react';
import { Link } from '@react-md/link';
import { useDocsDialogLink } from '../hooks';

export const DocsDialogLink = React.memo(function DocsDialogLink(
  {
    to,
    state,
    replace,
    onClick: propsOnClick,
    ...props
  }
) {
  const { href, onClick } = useDocsDialogLink({
    to,
    state,
    replace,
    onClick: propsOnClick
  });
  props.href = href;
  props.onClick = onClick;
  return createElement(Link, props);
})
