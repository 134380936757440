import React from 'react';
import {
  useRecordSubscription,
  useRenderer
} from '@isomorix/react';
import { CORE_LOGIC_IDS } from './constants';

const keys = [ 'match' ];

/**
 * The default component used for
 * [CoreLogic]{@link module:core/coreLogic.Record}
 * that is managing a [Route]{@link module:core/route.Record}.
 * @function Route
 * @memberof module:react-router
 * @param {Object} props
 * @param {module:core/coreLogic.Record} props.logic - The
 * [CoreLogic record]{@link module:core/coreLogic.Record}
 * managing the route.
 *
 * The component will rerender when the record's
 * [match property]{@link module:core/coreLogic.Record#match}
 * changes.
 */
export const Route = React.memo(function Route(props) {
  useRecordSubscription(props.logic, keys);
  const renderer = useRenderer();
  const { match } = props.logic;
  if (!match) return null;
  const { logic, children, ...childProps } = props;
  return (
    <>
      {children}
      {renderer.many(logic.children, childProps)}
    </>
  )
});

Route.displayName = CORE_LOGIC_IDS.ROUTE;
