import {
  ROLES as _ROLES,
} from '@isomorix/core-config';

/**
 * @constant module:core-config/roles.ENGINEER
 * @type {string}
 */
const ENGINEER = 'engineer';

/**
 * @constant module:core-config/roles.PARTNER
 * @type {string}
 */
const PARTNER = 'partner';

/**
 *
 * @type {Object}
 * @ignore
 */
export const ROLES = {
  ..._ROLES,
  ENGINEER,
  PARTNER
};
