import { DOCS_URL_SEARCH as PARAMS } from '@isomorix/core-config';

export function addDocsOffsetParams(params, eventOrOffset) {
  params[PARAMS.OFFSET_X] = eventOrOffset.clientX || eventOrOffset.x;
  params[PARAMS.OFFSET_Y] = eventOrOffset.clientY || eventOrOffset.y;
  return params;
}

export function docsUrlToSearchParams(url, eventOrOffset) {
  const idx = url.indexOf('#');
  const resp = {};
  if (idx > -1) {
    resp[PARAMS.PATHNAME] = url.substring(0, idx);
    resp[PARAMS.HASH] = url.substring(idx + 1);
  } else {
    resp[PARAMS.PATHNAME] = url;
    resp[PARAMS.HASH] = undefined;
  }
  if (eventOrOffset) {
    addDocsOffsetParams(resp, eventOrOffset);
  }
  return resp;
}

export function createDocsDialogOnClick(
  location,
  {
    to,
    state,
    replace,
    onClick: optsOnClick
  }
) {
  return location.createOnClick({
    to,
    state,
    searchOnly: true,
    replace,
    onClick: (event, location) => {
      let params = to;
      if (optsOnClick) {
        const resp = optsOnClick(event, location);
        if (event.defaultPrevented) return null;
        if (typeof resp !== 'undefined') {
          params = resp;
        }
      }
      if (!params) return null;
      return typeof params === 'string'
        ? docsUrlToSearchParams(params, event)
        : addDocsOffsetParams({ ...params }, event);
    }
  })
}

export function createDocsDialogLink(location, options) {
  const onClick = createDocsDialogOnClick(location, options);
  let href;
  if (options.to) {
    href = location.createPath(
      undefined,
      typeof options.to === 'string'
        ? docsUrlToSearchParams(options.to)
        : options.to
    );
  }
  return { onClick, href };
}
