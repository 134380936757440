import React, { createElement, useMemo } from 'react';
import { AppBarAction } from '@react-md/app-bar';
import { MenuItemSwitch, Slider, useSlider } from '@react-md/form';
import { FontIcon, TextIconSpacing } from '@react-md/icon';
import { useLayoutContext } from './LayoutContext';
import { Menu, MenuItem, useContextMenu } from '@react-md/menu';
import { Button } from '@react-md/button';

const sliderStyle = {
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingBottom: '20px'
};

function TransitionTime(
  {
    logic,
  }
) {
  const { localProps } = logic;
  const [, controls ] = useSlider(localProps.viewModeTransitionTime / 1000, {
    min: 0,
    max: 6,
    step: 0.01,
    jump: 1,
    updateOn: 'blur',
    onChange
  });
  function onChange(value) {
    logic.setLocalProps({ viewModeTransitionTime: value * 1000 });
  }
  return (
    <Slider
      baseId={'viewModeTransitionTimeInputSlider'}
      label={'Transition Time (seconds)'}
      { ...controls }
      discrete={true}
      style={sliderStyle}
    />
  )
}

function ClearLayout3DStore(
  {
    logic,
  }
) {
  const { localProps } = logic;
  return (
    <MenuItemSwitch
      id={`clear-vr-store-on-close`}
      checked={!!!localProps.clearLayout3DStoreOnClose}
      onCheckedChange={(checked) => {
        logic.setLocalProps({ clearLayout3DStoreOnClose: !checked });
      }}
    >
      Persistent Worlds
    </MenuItemSwitch>
  )
}

function addButtonIcon(props) {
  if (props.floating || !props.children) {
    props.children = <FontIcon>{ props.icon }</FontIcon>
  } else {
    props.children = (
      <TextIconSpacing
        icon={<FontIcon>{ props.icon }</FontIcon>}
      >
        { props.children }
      </TextIconSpacing>
    );
  }
  delete props.icon;
}

export function useViewModeButton(
  logic,
  baseId = 'rmdViewModeContextMenu',
  anchor,
) {
  const {
    menuRef,
    menuProps,
    onContextMenu,
    visible,
  } = useContextMenu({ baseId, anchor });
  return {
    ...useLayoutContext(),
    onContextMenu,
    menu: (
      <Menu
        { ...menuProps }
        ref={menuRef}
        visible={visible}
      >
        <MenuItem>
          <TransitionTime logic={logic} />
        </MenuItem>
        <ClearLayout3DStore logic={logic} />
      </Menu>
    )
  };
}

const emptyObj = {};

function useButtonProps(
  {
    disableOnTransition = true,
    appBarAction = false,
    /*
     * first & last are universalProps, but this
     * prevents the caller from having to memoize
     * a universalProps Object when first/last
     * can be dynamic. For example when
     * other
     */
    first,
    last,
    props2D = emptyObj,
    props3D = emptyObj,
    universalProps = emptyObj,
  },
) {
  const {
    viewMode,
    render2D,
    render3D,
    toggleViewMode
  } = useLayoutContext();
  return useMemo(() => {
    const props = viewMode === '2D'
      ? { ...universalProps, ...props2D }
      : { ...universalProps, ...props3D };
    if (!props['aria-label']) {
      props['aria-label'] = viewMode === '2D'
        ? 'Transition To 3D'
        : 'Transition To 2D';
    }
    if (props.icon) {
      addButtonIcon(props);
    } else if (!props.children) {
      props.buttonType = 'icon';
      props.children = viewMode === '2D'
        ? <FontIcon>3d_rotation</FontIcon>
        : '2D';
    }
    if (appBarAction) {
      if (typeof first === 'boolean') {
        props.first = first;
      }
      if (typeof last === 'boolean') {
        props.last = last;
      }
    }
    if (disableOnTransition && render2D && render3D) {
      props.disabled = true;
    }
    props.onClick = toggleViewMode;
    return props;
  }, [
    viewMode,
    render2D,
    render3D,
    toggleViewMode,
    appBarAction,
    first,
    last,
    disableOnTransition,
    props2D,
    props3D,
    universalProps
  ]);
}

export const ViewModeButton = React.memo(function ViewModeButton(props) {
  return props.logic && props.menuBaseId
    ? createElement(ViewModeButtonWithContextMenu, props)
    : createElement(ViewModeButtonRender, props);
});

function ViewModeButtonRender(props) {
  return createElement(
    props.appBarAction
      ? AppBarAction
      : Button,
    useButtonProps(props)
  );
}

function ViewModeButtonWithContextMenu(props) {
  const {
    onContextMenu,
    menu,
  } = useViewModeButton(props.logic, props.menuBaseId);
  const buttonProps = useButtonProps(props);
  buttonProps.onContextMenu = onContextMenu;
  return (
    <>
      { createElement(props.appBarAction ? AppBarAction: Button, buttonProps) }
      { menu }
    </>
  );
}
