
const _check = (pathname, regexp) => {
  if (regexp.test(pathname)){
    // will be wrong every other time if not
    // reset after successful test
    regexp.lastIndex = 0;
    return true;
  }
  return false;
}

export const docsDirRegexp = new RegExp(`^[\/]?docs(\/|$)`);
export function isDocsPath(pathname) {
  return _check(pathname, docsDirRegexp);
}

const appBuilderRegexp = new RegExp(`^[\/]?app-builder(\/|$)`);
export function isAppBuilderPath(pathname) {
  return _check(pathname, appBuilderRegexp);
}

const voyagerRegExp = new RegExp(`^[\/]?graphql-voyager(\/|$)`);
export function isVoyagerPath(pathname) {
  return _check(pathname, voyagerRegExp);
}
