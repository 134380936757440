import { Subject, Subscriber } from 'rxjs';
import {
  CORE_LOGIC_TYPES as LOGIC_TYPES
} from '@isomorix/core-config';

/*
 * Only use `prevMatch` if not on the server. Otherwise,
 * logic won't have a chance to see the request before
 * rendering because it won't think it got updated
 * if the last request resulted in the same
 * `match` Object.
 */
const isBrowser = process.env.BROWSER;

const _mergeArrays = (src, dest) => {
  for(let val of src) {
    if (dest.indexOf(val) < 0) {
      dest.push(val);
    }
  }
}

class MissingSub extends Subscriber {
  constructor(logic, payload) {
    super();
    this.__logic = logic;
    this.__payload = payload;
  }

  _next(_) {
    const logic = this.__logic;
    const payload = this.__payload;
    const { ...localProps } = logic.localProps;
    /*
     * routeLogic Object is keyed by the ID of the
     * route that the logic is associated with.
     * So we can iterate over the Array of
     * missing routeIds to find the associated logic.
     */
    const routeLogic = logic.isTypeOf(LOGIC_TYPES.ROUTER)
      ? logic.routeLogic
      : logic.routerLogic.routeLogic;
    /**
     * @type {Array.<string>}
     * @ignore
     */
    const allMissing = localProps.missingChildRoutes;
    const missing = payload.missingChildRoutes;
    let routeId, idx;
    for(let i = 0; i < missing.length; i++) {
      routeId = missing[i];
      idx = allMissing.indexOf(routeId);
      if (idx > -1) {
        allMissing.splice(idx, 1);
      }
      missing[i] = routeLogic[routeId];
    }
    payload.mutation.update(logic, { localProps }, true);
    this.unsubscribe();
  }

  _complete() {
    /*
     * This would mean the action was cancelled, since
     * otherwise we'd get next(), which immediately unsubscribes.
     * We only need to remove the entries from `localProps`,
     * since the undo mutation that occurs when a mutation
     * is cancelled will include our logic.
     *
     * But the missingChildRoutes Array is used by
     * reference, so even though the original localProps
     * will be restored by the undo mutation, the Array would
     * still contain the id(s).
     */
    const allMissing = this.__logic.localProps.missingChildRoutes;
    const missing = this.__payload.missingChildRoutes;
    let idx;
    for(let routeId of missing) {
      if ((idx = allMissing.indexOf(routeId)) > -1) {
        allMissing.splice(idx, 1);
      }
    }
    super._complete();
  }

  unsubscribe() {
    super.unsubscribe();
    this.__logic = undefined;
    this.__payload = undefined;
  }
}

function _descendNoMatch(data, parentLogic, children, missing) {
  if (!children) return;
  const {
    routeLogic,
    mutation: m
  } = data;
  let logic;
  for(let routeId in children) {
    if (
      routeLogic
      && (logic = routeLogic[routeId])
      && logic.match !== null
    ) {
      m.update(logic, { match: null }, true);
      _descendNoMatch(data, logic, children[routeId].children);
    }
  }
  return missing;
}

function _descendMatch(data, parentLogic, children) {
  if (!children) return;
  const {
    routeLogic,
    /**
     * @type {module:model.Mutation}
     * @ignore
     */
    mutation: m,
    pathname,
    searchParams,
    hash,
    allMissing,
  } = data;
  let { missingRouteIds } = data;
  let logic, prevMatch, match, rt;
  let parentMissing;
  for(let routeId in children) {
    rt = children[routeId];
    if (!routeLogic || !(logic = routeLogic[routeId])) {
      if (!(match = rt.matchPath(pathname, searchParams, hash))) {
        continue;
      }
      if (allMissing.indexOf(routeId) < 0) {
        allMissing.push(routeId);
        if (!missingRouteIds) {
          missingRouteIds = [ routeId ];
          /*
           * These are used by the routerLogic
           * to execute the query to fetch the
           * missing logic. That'll occur
           * during the mutation, see ../routerLogic/mutation
           * for details.
           *
           * Then, it'll call data.missingRouteIds$.next(records)
           * so that they can be set on the payload for
           * each parent route that is missing children.
           * The MissingSub is who updates the payload
           * for each route.
           *
           * If the mutation is cancelled, the logic
           * in ../routerLogic/mutation will handle
           * cleaning everything up appropriately.
           */
          data.missingRouteIds = missingRouteIds;
          data.missingRouteIds$ = new Subject();
        } else {
          missingRouteIds.push(routeId);
        }
        /*
         * Only add direct children to a `parentMissing`
         * Array if there is parentLogic.
         * All nested routes will still get fetched.
         * It's just that the parentLogic should only
         * be concerned about dispatching to
         * its own direct child routes, since
         * those would in-turn call dispatch on
         * the deeper routeLogic that we may
         * also be fetching right now. And if
         * we aren't, but there's a match for them
         * by the time it comes back, it'll repeat
         * what we're doing right now.
         */
        if (parentLogic) {
          if (parentMissing) {
            parentMissing.push(routeId);
          } else {
            parentMissing = [ routeId ];
          }
        }
      }
      _descendMatch(data, null, rt.children);
    } else {
      prevMatch = logic.match;
      match = rt.matchPath(
        pathname,
        searchParams,
        hash,
        isBrowser ? prevMatch : null
      );
      if (match !== prevMatch) {
        logic = m.update(logic, { match }, true);
        if (match) {
          _descendMatch(data, logic, rt.children);
        } else {
          _descendNoMatch(data, logic, rt.children);
        }
      } else if (match) {
        _descendMatch(data, logic, rt.children);
      }
    }
  }
  if (parentLogic && parentMissing) {
    parentLogic = m.getMutableRecord(parentLogic);
    const localProps = parentLogic.getMutableLocalProps();
    let { missingChildRoutes } = localProps;
    if (missingChildRoutes) {
      _mergeArrays(parentMissing, missingChildRoutes);
    } else {
      localProps.missingChildRoutes = [ ...parentMissing ];
    }
    m.update(parentLogic, { localProps }, true);
    const payload = m.getRecordPayload(parentLogic, true);
    ({ missingChildRoutes } = payload);
    if (missingChildRoutes) {
      /*
       * It already has a subscription to data.missingRouteIds$,
       * so we only need to add to what it's going to process.
       */
      _mergeArrays(parentMissing, missingChildRoutes);
    } else {
      /*
       * Right now it's just the IDs, but it'll
       * be replaced with the actual logic record
       * by the MissingSub, once the routerLogic
       * executes the query to fetch all the
       * logic that's missing.
       */
      payload.missingChildRoutes = parentMissing;
      data.missingRouteIds$.subscribe(
        new MissingSub(parentLogic, payload)
      );
    }
  }
}

/**
 * Handles updating the
 * [match property]{@link module:core/coreLogic.Record#match}
 * for the [Router core logic]{@link module:core-config/coreLogicTypes.ROUTER}
 * associated with the app (the logic managing all routes for the app),
 * as well as all core logic whose
 * [routerLogicId property]{@link module:core/coreLogic.Record#routerLogicId}
 * matches the aforementioned `routerLogic`.
 *
 * **Note:** Typically, only logic that manages a
 * [Route]{@link module:core/route.Record} sets its
 * [routerLogicId property]{@link module:core/coreLogic.Record#routerLogicId},
 * thus triggering this method to set its
 * [match property]{@link module:core/coreLogic.Record#match}
 * when the [location changes]{@link module:core/location.Record}.
 * And while this is not required, if the logic defines its
 * [routerLogicId property]{@link module:core/coreLogic.Record#routerLogicId}
 * it **must also** define its
 * [routeId property]{@link module:core/coreLogic.Record#routeId}
 * so that this method knows which route to use when updating
 * its [match property]{@link module:core/coreLogic.Record#match}.
 *
 * In addition to updating each logic's
 * [match property]{@link module:core/coreLogic.Record#match},
 * it will also handle compiling any missing routes/routeLogic.
 * This will then be provided to the `routerLogic` via its
 * [MUTATION action]{@link module:core-actions.mutation}
 * `payload` so that it can handle fetching any missing records.
 *
 * Specifically, it sets `payload.missingRouteIds`, which the
 * [routerLogic's manageMissingRouteLogic]{@link module:ui-router/router/logic/mutation.manageMissingRouteLogic}
 * will use to execute follow-up queries as needed.
 *
 * This method can be called at any time to update the
 * `match` properties, but typically it is called
 * by the
 * [RoutesMgr's matchLocation method]{@link module:ui-router.RoutesMgr#matchLocation}.
 *
 * @function matchLocation
 * @memberof module:ui-router
 * @param {module:model.Mutation|module:model/mutation.Controller} mutation -
 * The model mutation or plugin mutation (a.k.a. `controller`)
 * to use when updating the `match` properties of each logic.
 *
 * A mutation for the [CoreLogic model]{@link module:core/coreLogic.Model}
 * will be retrieved from this param, so it doesn't need to
 * already be for the [CoreLogic model]{@link module:core/coreLogic.Model}.
 * @param {module:core/location.Record} location - The
 * [location record]{@link module:core/location.Record}
 * whose properties will be used when updating the
 * [match properties]{@link module:core/coreLogic.Record#match}.
 *
 * Typically, this is the pending `location` record instance
 * that is being mutated, since
 * this method only needs to be called due to a change
 * to that record.
 * @param {module:core/coreLogic.Record} routerLogic - The
 * CoreLogic record that is managing routing for the app.
 *
 * Its [getBestInstance method]{@link module:core/coreLogic.Record#getBestInstance}
 * will be called to get the most accurate instance
 * of the record.
 * @param {Array.<string>} allMissing - The
 * [RoutesMgr's allMissing property]{@link module:ui-router.RoutesMgr#allMissing},
 * which contains the [route IDs]{@link module:core/route.Record#id}
 * for routes that are already known not to exist.
 *
 * See that property for more details.
 * @returns {?Object}
 * The
 * [MUTATION action payload]{@link module:core-actions.mutationPayload}
 * for the routerLogic,
 * or `null` if it didn't need to be dispatched to.
 */
export function matchLocation(
  mutation,
  location,
  routerLogic,
  allMissing
) {
  routerLogic = routerLogic.getBestInstance(mutation.dispatchId);
  const { routeLogic, route: router } = routerLogic;
  const { pathname, searchParams, hash } = location;
  const m = mutation.switchTo(routerLogic.__typename);
  let prevMatch = routerLogic.match;
  const match = router.matchPath(
    pathname,
    searchParams,
    hash,
    isBrowser ? prevMatch : null
  );
  if (match !== prevMatch) {
    routerLogic = m.update(routerLogic, { match }, true);
  }
  let payload = m.getRecordPayload(routerLogic, true);
  const data = {
    pathname,
    searchParams,
    hash,
    mutation: m,
    routeLogic,
    allMissing,
  }
  if (payload) {
    data.missingRouteIds = payload.missingRouteIds;
    data.missingRouteIds$ = payload.missingRouteIds$;
  }
  if (match) {
    _descendMatch(data, routerLogic, router.children);
  } else {
    _descendNoMatch(data, routerLogic, router.children);
  }
  const { missingRouteIds } = data;
  if (missingRouteIds) {
    if (!payload) {
      routerLogic = m.getMutableRecord(routerLogic);
      payload = m.getRecordPayload(routerLogic, true);
    }
    /*
     * If it already existed, we're just setting
     * the same property again. This is because
     * we attempted to use the existing
     * properties before we processed the
     * location change - see above when creating
     * the data Object.
     */
    payload.missingRouteIds = missingRouteIds;
    payload.missingRouteIds$ = data.missingRouteIds$;
    payload.allMissingRouteIds = allMissing;
  }
  return payload || null;
}
