import React, { createElement, useMemo } from 'react';
import { FontIcon } from '@react-md/icon';
import { bem } from '../helpers';
import { createDocsDialogLink } from '../../helpers';
import { Link as RmdLink } from '@react-md/link';
import { ListItem, List as RmdList } from '@react-md/list';
import cn from 'classnames';
import { useLocation } from '@isomorix/react-router';

const listBlock = bem('list');
const itemBlock = bem('list-item');
const itemIconCn = itemBlock('icon');
const itemSecondaryText = itemBlock('text', {
  ['secondary']: true
});
const itemLabelCn = itemBlock('text', { primary: true });
const itemLabelLinkCn = itemBlock('text', {
  primary: true,
  link: true,
});

export const List = React.memo(function List(
  {
    data,
    // location,
    isDocsLink = true,
    threeLines = true,
    ...listProps
  }
) {
  const location = useLocation();
  const children = useMemo(() => {
    const children = [];
    let props, label, linkProps, i = 0, docsLink;
    for(let item of data) {
      props = { key: `${item.label}_${i}` };
      if (item.icon) {
        props.leftAddonType = 'icon';
        props.leftAddon = createElement(
          FontIcon,
          { className: itemIconCn },
          item.icon
        );
      }
      if (item.secondaryText) {
        props.secondaryText = item.secondaryText;
        props.secondaryTextClassName = cn(
          item.secondaryTextClassName,
          itemSecondaryText
        );
      }
      props.className = cn(item.className, itemBlock());
      props.threeLines = typeof item.threeLines === 'boolean'
        ? item.threeLines
        : threeLines;
      if (!item.url) {
        label = createElement(
          'span',
          { className: itemLabelCn },
          item.label
        );
      } else {
        docsLink = typeof item.isDocsLink === 'boolean'
          ? item.isDocsLink
          : isDocsLink;
        linkProps = docsLink
          ? createDocsDialogLink(location, { to: item.url })
          : {
              href: location.createPath(item.url),
              onClick: location.createOnClick({ to: item.url })
            };
        props.onClick = linkProps.onClick;
        label = createElement(RmdLink, {
          href: linkProps.href,
          className: itemLabelLinkCn,
          onClick: props.onClick,
        }, item.label);
      }
      props.textClassName = cn(item.textClassName, itemBlock('text'));
      children.push(createElement(ListItem, props, label));
      i++;
    }
    return children;
  }, [ data, location, isDocsLink ]);
  listProps.className = cn(listProps.className, listBlock());
  return createElement(RmdList, listProps, children);
})
